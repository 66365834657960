import React from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components/macro'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'

interface StakingConfirmationProgressWidgetProps {
  open: boolean
  onClose?: () => any
  title: string
  message: string
}

const progress = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
`

const Wrapper = styled.div`
  position: fixed;
  left: 20px;
  bottom: 56px;
  width: 256px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.oceanBlue['125']};
  padding: 0;
  overflow: hidden;
`

const TitleRow = styled(FlexRow)`
  padding: 12px 12px 0;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white['50']};
`

const StyledCloseIcon = styled(CloseIcon)`
  width: 18px;
  height: 18px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

const Message = styled(Typography)`
  padding: 0 12px 12px;
`

const ProgressBar = styled.div`
  height: 6px;
  background: linear-gradient(269.67deg, #3068ef 0.29%, #1322ac 99.71%);
  animation: ${progress} 40s cubic-bezier(0.1, 0.1, 0.01, 1) forwards;
`

const transactionProgressEl = document.getElementById(
  'transaction-progress-portal'
) as Element

function StakingConfirmationProgressWidget({
  open,
  onClose,
  title,
  message,
}: StakingConfirmationProgressWidgetProps) {
  if (!open) {
    return null
  }

  return ReactDOM.createPortal(
    <Wrapper>
      <TitleRow align="center" justify="space-between">
        <Title>
          <Typography variant="body" size="small">
            {title}
          </Typography>
        </Title>
        {onClose && <StyledCloseIcon />}
      </TitleRow>
      <Message variant="body" size="small">
        {message}
      </Message>
      <ProgressBar />
    </Wrapper>,
    transactionProgressEl
  )
}

export { StakingConfirmationProgressWidget }
