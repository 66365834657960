import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components/macro'
import { Box } from '@swell-ui/Box'
import { Grid } from '@swell-ui/Grid'
import { Table, TableConfig } from '@swell-ui/Table'
import { shortenHash, strip0x } from '@/util/hexStrings'
import { SectionBoxLabel } from '@/components/SectionBoxLabel'
import { IDepositCollectionValidationResult } from '@/state/depositValidation/models/DepositCollectionValidationResult'
import { depositProblemToMessage } from '@/state/depositValidation/models/DepositProblem'

type RowType = {
  pubkey?: string
  code: ReactNode
  description: ReactNode
}

type OnRowClick = NonNullable<TableConfig['onRowClick']>

interface DepositCollectionValidationDetailProps {
  validationResult?: IDepositCollectionValidationResult
  textAreaRef: React.RefObject<HTMLTextAreaElement>
}

const ContainerBox = styled(Box)`
  padding: 38px;
`

const columns: TableConfig['columns'] = [
  {
    key: 'pubkey',
    label: 'Pub key',
  },
  {
    key: 'code',
    label: 'Error type',
  },
  {
    key: 'description',
    label: 'Description',
  },
]
const ValidationDetailTable = styled(Table)`
  .pubkey-cell {
    padding-left: 0;
  }
`

const KEY_LENGTH = 96

const buildRows = (
  validationResult: IDepositCollectionValidationResult
): RowType[] => {
  const rows: RowType[] = []

  // build the list of problems to display as rows
  // "input problems" (i.e. those relating to the entirety of the data and not
  //  a single public key) are displayed first
  if (validationResult.inputProblem) {
    const { code } = validationResult.inputProblem

    rows.push({
      pubkey: '',
      code,
      description: depositProblemToMessage(validationResult.inputProblem),
    })
  }

  for (const problem of validationResult.dataProblems ?? []) {
    rows.push({
      pubkey:
        typeof problem.pubkey === 'string'
          ? shortenHash(problem.pubkey)
          : 'Missing',
      code: problem.code,
      description: depositProblemToMessage(problem),
    })
  }

  return rows
}

function DepositCollectionValidationDetail({
  validationResult,
  textAreaRef,
}: DepositCollectionValidationDetailProps) {
  const focusPubKeyForRowInTextArea: OnRowClick = useCallback(
    (row) => {
      if (!textAreaRef?.current) {
        return
      }

      if (!row?.pubkey) return

      const elem = textAreaRef.current
      const selectionStartIndex = elem.value.indexOf(
        strip0x(row.pubkey).slice(0, 4)
      )

      // check whether the associated input included 0x to decide the selection range
      const has0x = textAreaRef.current.value[selectionStartIndex - 1] === 'x'

      elem.selectionStart = elem.selectionEnd = selectionStartIndex
      elem.blur()
      elem.focus()
      elem.setSelectionRange(
        selectionStartIndex - (has0x ? 2 : 0),
        selectionStartIndex + KEY_LENGTH
      )
    },
    [textAreaRef]
  )

  const config = React.useMemo<TableConfig>(() => {
    if (!validationResult || validationResult.valid)
      return {
        columns,
        rows: [],
        selectableRows: true,
      }

    return {
      columns,
      rows: buildRows(validationResult),
      onRowClick: (row) => {
        focusPubKeyForRowInTextArea(row)
      },
      selectableRows: true,
    }
  }, [focusPubKeyForRowInTextArea, validationResult])

  return (
    <ContainerBox>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <SectionBoxLabel>Validation details</SectionBoxLabel>
        </Grid>
        <Grid item>
          <ValidationDetailTable config={config} />
        </Grid>
      </Grid>
    </ContainerBox>
  )
}

export { DepositCollectionValidationDetail }
