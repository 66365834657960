import { OperatorService } from '@v3-backend/operator_connect'
import { RatesService } from '@v3-backend/rates_connect'
import { StakerService } from '@v3-backend/staker_connect'
import { StatsService } from '@v3-backend/stats_connect'
import { TwitterService } from '@v3-backend/twitter_connect'
import { VoyageService } from '@v3-backend/voyage_connect'

export const V3_BACKEND_SERVICES = {
  RatesService,
  OperatorService,
  StakerService,
  StatsService,
  TwitterService,
  VoyageService,
}
