import React from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Box } from '@swell-ui/Box'
import { Grid } from '@swell-ui/Grid'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SectionBoxLabel } from '@/components/SectionBoxLabel'
import {
  IRecentTransactionsTableProps,
  RecentTransactionsTable,
} from './RecentTransactionsTable'
import {
  useUserOperatorId,
  useUserOperatorRecentTransactions,
} from '@/state/userOperator/hooks'

const ContainerBox = styled(Box)`
  padding: 24px 32px;
  max-width: 100%;
  overflow: hidden;

  button {
    width: 100%;
    max-width: 392px;
  }
`

const TableGrid = styled(Grid)`
  max-width: 100% !important;
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
`

function RecentTransactions() {
  const { account } = useSwellWeb3()
  const userOperatorIdQuery = useUserOperatorId()
  const userOperatorRecentTransactionsQuery = useUserOperatorRecentTransactions(
    { account }
  )

  const userOperatorId = userOperatorIdQuery.data?.operatorId.toNumber()

  // Operator ids are indexed from 1. operatorId=0 implies the address is not an operator address
  const notAnOperator = userOperatorId === 0

  const tableProps = React.useMemo<IRecentTransactionsTableProps>(() => {
    if (!account) return { recentTransactions: [] }

    return {
      recentTransactions:
        userOperatorRecentTransactionsQuery.data?.recentTransactions,
    }
  }, [account, userOperatorRecentTransactionsQuery.data?.recentTransactions])

  return (
    <ContainerBox>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <SectionBoxLabel>Recent transactions</SectionBoxLabel>
        </Grid>
        {account && (
          <TableGrid item>
            <RecentTransactionsTable {...tableProps} />
          </TableGrid>
        )}
        {notAnOperator && tableProps.recentTransactions?.length === 0 && (
          <Grid item>
            <CenteredContent>
              <span>Not a Node Operator</span>
            </CenteredContent>
          </Grid>
        )}

        {!account && (
          <Grid item>
            <CenteredContent>
              <ConnectWalletButton>Connect wallet to view</ConnectWalletButton>
            </CenteredContent>
          </Grid>
        )}
      </Grid>
    </ContainerBox>
  )
}

export { RecentTransactions }
