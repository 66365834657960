import React from 'react'
import styled, { keyframes } from 'styled-components/macro'

const rotate = keyframes`
  from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
`

const OuterCircle = styled.div`
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #2f43ec 0deg,
    #536dfe 63.24deg,
    rgba(255, 255, 255, 0) 360deg
  );
  animation: ${rotate} 2s linear infinite;
`

const InnerCircle = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 68px;
  margin-top: 12px;
  margin-left: 12px;
  background: #0b141b;
`

const MiniCircle = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background: #2f43ec;
  margin-left: 34px;
`

function GradientLoader() {
  return (
    <OuterCircle>
      <InnerCircle />
      <MiniCircle />
    </OuterCircle>
  )
}

export { GradientLoader }
