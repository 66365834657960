import React from 'react'
import styled from 'styled-components/macro'
import { AsyncDiv } from '@swell-ui/AsyncDiv'
import { Box } from '@swell-ui/Box'
import { FlexRow } from '@swell-ui/FlexRow'
import { InfoInvertedIcon } from '@swell-ui/icons/InfoInvertedIcon'
import { Tooltip } from '@swell-ui/Tooltip'
import { Typography } from '@swell-ui/Typography'
import { WarningIcon } from '@swell-ui/icons/WarningIcon'
import { shortenAddress } from '@/util/hexStrings'
import { displayPearls } from '@/util/displayNumbers'
import { AppVoyageUser } from '@/types/voyage'
import chestUrl from '@/assets/svg/voyage/chest.svg'
import chestFullUrl from '@/assets/svg/voyage/chest-full.svg'
import pearlUrl from '@/assets/svg/voyage/pearl.svg'

interface YourRewardsProps {
  address?: string
  ENSName?: string
  isLoading: boolean
  voyageUser?: AppVoyageUser
  error: boolean
}

const VoyageBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 28px;
  background-color: rgba(5, 26, 43, 0.5);
  backdrop-filter: blur(10px);
  color: ${({ theme }) => theme.colors.white['50']};
  margin-top: 24px;
  border-radius: 24px;
  border: none;
  letter-spacing: -0.03em;
  text-align: center;
`

const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  letter-spacing: 0.075em;
  text-transform: uppercase;
  margin-bottom: 12px;
  line-height: 38px;
`

const PearlSection = styled.div`
  margin-top: 30px;
`

const PearlImg = styled.img`
  margin-right: 8px;
`

const ChestImg = styled.img`
  height: 128.71px;
  margin: 40px 0 0;
`

const PearlAsyncDiv = styled(AsyncDiv)`
  min-width: unset;
`

const StyledWarningIcon = styled(WarningIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.red['50']};
  }
`

const INFO_TOOLTIP_TITLE = (
  <div>
    Learn more about the voyage&nbsp;
    <a
      href="https://www.swellnetwork.io/post/the-voyage"
      target="_blank"
      rel="noreferrer"
    >
      here
    </a>
    .
  </div>
)

const WHITE_PEARLS_TOOLTIP_TITLE =
  'Pearls earned by holding swETH and completing side quests'

const ERROR_TOOLTIP_TITLE =
  'Please check back later, and check our Discord for announcements'

function YourRewards({
  voyageUser,
  ENSName,
  address,
  isLoading,
  error,
}: YourRewardsProps) {
  let addressText = '-'
  if (ENSName) {
    addressText = ENSName
  } else if (address) {
    addressText = shortenAddress(address, 3, 4)
  }

  const pearlsAmt = voyageUser?.points ?? 0

  return (
    <div>
      <Typography variant="headline" size="h5">
        Your rewards&nbsp;
        <Tooltip title={INFO_TOOLTIP_TITLE} placement="top" offset={[0, -15]}>
          <InfoInvertedIcon />
        </Tooltip>
      </Typography>
      <VoyageBox>
        <div>
          <SectionTitle variant="body" size="medium">
            You
          </SectionTitle>
          <Typography variant="body" size="medium">
            {addressText}
          </Typography>
        </div>
        <PearlSection>
          <SectionTitle variant="body" size="medium">
            <Tooltip
              title={WHITE_PEARLS_TOOLTIP_TITLE}
              placement="top"
              offset={[0, -10]}
            >
              Pearls
            </Tooltip>
          </SectionTitle>
          <Typography variant="body" size="medium">
            <PearlAsyncDiv loading={isLoading}>
              {() => (
                <FlexRow align="center" justify="center">
                  {!error && (
                    <>
                      <PearlImg src={pearlUrl} />
                      <div>{displayPearls(pearlsAmt)}</div>
                    </>
                  )}
                  {error && (
                    <Tooltip
                      title={ERROR_TOOLTIP_TITLE}
                      placement="top"
                      offset={[0, -10]}
                    >
                      <StyledWarningIcon />
                    </Tooltip>
                  )}
                </FlexRow>
              )}
            </PearlAsyncDiv>
          </Typography>
          {!voyageUser && <ChestImg src={chestUrl} />}
          {voyageUser && <ChestImg src={chestFullUrl} />}
        </PearlSection>
      </VoyageBox>
    </div>
  )
}

export { YourRewards }
