const colors = {
  black: {
    50: '#4A4A4A',
    75: '#1D1D1D',
    100: '#181818',
    125: '#101010',
    150: '#000000',
  },
  lightBlue: {
    50: '#A4ABF1',
    75: '#6E79E9',
    100: '#2F43EC',
    125: '#3B46B7',
    150: '#252C73',
  },
  oceanBlue: {
    50: '#166FB8',
    75: '#0E4878',
    100: '#051A2B',
    125: '#0B141B',
    150: '#010508',
  },
  red: {
    50: '#EA8888',
    100: '#C32323',
  },
  white: {
    50: '#FFFFFF',
    75: '#F1F1F1',
    100: '#E7E7E7',
    125: '#B0B0B0',
    150: '#818181',
  },
  auxGrey: '#424557',
}

export { colors }
