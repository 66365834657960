import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Box } from '@swell-ui/Box'
import { Button } from '@swell-ui/Button'
import { CircularProgress } from '@swell-ui/CircularProgress'
import { Divider } from '@swell-ui/Divider'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { SwethIcon } from '@swell-ui/icons/SwethIcon'
import { EthInput, SwEthInput } from '@swell-ui/inputs'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SelectTokenWidget } from '@/components/SelectTokenWidget'
import { SwellStatistics } from '@/components/SwellStatistics'
import { StakingConfirmationProgressWidget } from '@/components/StakingConfirmationProgressWidget'
import { useStakingRate } from '@/state/stakingStats/hooks'
import { useEthBalance } from '@/state/user/hooks'
import { useIERC20Contract } from '@/hooks/useContract'
import {
  useFormatNativeCurrency,
  useParseNativeCurrency,
} from '@/hooks/useNativeCurrencyFormatting'
import {
  useConvertNativeCurrencyToSwEth,
  useConvertSwEthToNativeCurrency,
} from '@/hooks/useCurrencyConversions'
import { useAllowance } from '@/hooks/erc20/useAllowance'
import { useApprove } from '@/hooks/erc20/useApprove'
import { useTokenBalances } from '@/hooks/erc20/useTokenBalances'
import { useFormatSwEth, useParseSwEth } from '@/hooks/useSwEthFormatting'
import { useStakeNativeCurrency } from '@/state/staking/hooks'
import { useStakingDisplayStrings } from '@/hooks/useStakingDisplayStrings'
import { StakingSubmissionStatus, useStakeLens } from '@/hooks/useStakeLens'
import { useZap } from '@/hooks/useZap'
import {
  GLOBAL_NOTIFICATION_TYPES,
  useGlobalNotification,
} from '@/swell-ui/GlobalNotification'
import { useChainInfo, useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useEthUsdMarketRate, useSwEthUsdMarketRate } from '@/state/fiat/hooks'
import { useDisplaySwEthPriceFiat } from '@/hooks/useSwEthDisplay'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import { useGetRoutesQuery } from '@/services/LifiService'
import { displayFiat } from '@/util/displayFiat'
import { trimDecimalPlaces } from '@/util/number'
import { TOKEN_LIST_ETH, TOKEN_LIST_SWETH } from '@/constants/tokens'
import { ActionChooser } from './ActionChooser'
import { AvailableChip } from './AvailableChip'
import { CallToAction } from './CallToAction'
import { CryptoUtilities } from './CryptoUtilities'
import { ExchangeInfo } from './ExchangeInfo'
import { TransactionInProgressModal } from './TransactionInProgressModal'
import { SwapInfo } from './SwapInfo'
import { VaultView } from './VaultView'
import { ACTIONS } from './constants'

const StakingWidgetBox = styled(Box)`
  ${({ theme }) => `
    position: relative;
    width: 340px;
    margin: 0 auto;
    padding: 24px 24px 12px;
    margin-top: 18px;

    > div {
      margin-bottom: 12px;
    }

    ${theme.breakpoints.up('sm')} {
      width: 420px;
      margin-top: 95px;
      padding: 24px 32px 12px;


      > div {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 12px;
        }
      }
    }}
  `}
`

const UtilitiesContainer = styled.div`
  position: absolute;
  top: 99px;
  right: 32px;
`

const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`

const UsdTypography = styled(Typography)`
  height: 19.2px;
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  margin-bottom: 16px;
  margin-top: 2px;
  letter-spacing: -0.03em;
`

const StakingWidgetEthInput = styled(EthInput)`
  ${({ theme }) => `
    max-width: 152px;

    .MuiFormHelperText-root {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: unset;
    }
  `}
`

const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white['50']};
`

const StyledEthIcon = styled(EthIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

const StyledSwethIcon = styled(SwethIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

const StakeWidgetButton = styled(Button)`
  width: 100%;
`

const StakeConnectButton = styled(ConnectWalletButton)`
  width: 100%;
`

const InputCircularProgress = styled(CircularProgress)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

const CTAWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`

type Loadable<T> =
  | {
      isLoading: false
      value: T
    }
  | { isLoading: true }

function inputValueToCryptoAmount(
  inputValue: string,
  decimals: number
): string {
  const decimalPlaces = Math.min(decimals, 12)
  const cryptoAmountString = trimDecimalPlaces(inputValue, decimalPlaces)
  return parseUnits(cryptoAmountString, decimals).toString()
}

type StakingWidgetProps = {
  stakingEnabled: Loadable<boolean>
}

function StakingWidget({ stakingEnabled }: StakingWidgetProps) {
  // Get user, config data, helpers
  const { account, chainId } = useSwellWeb3()
  const { chainId: deploymentChainId } = useDeploymentSetConfig()
  const { explorer } = useChainInfo()
  const { notify, removeNotification } = useGlobalNotification()

  // Get token balances
  const ethBalance = useEthBalance()
  const tokenBalancesQuery = useTokenBalances()
  const tokenBalancesData = tokenBalancesQuery.data

  // Get referrer address if exists
  const [searchParams] = useSearchParams()
  const referrerAddress = searchParams.get('ref') || undefined

  // Action chooser state
  const [action, setAction] = useState<string>(ACTIONS.STAKE)

  // Form State
  const [touched, setTouched] = useState<boolean>(false)
  const [tokenSelectOpen, setTokenSelectOpen] = useState<boolean>(false)
  const [srcTokenInputValue, setSrcTokenInputValue] = useState<string>('')
  const [destTokenInputValue, setDestTokenInputValue] = useState<string>('')

  // Instantiate formatters and parsers
  const formatSwEth = useFormatSwEth()
  const parseSwEth = useParseSwEth()
  const formatNativeCurrency = useFormatNativeCurrency()
  const parseNativeCurrency = useParseNativeCurrency()
  const displaySwEthPriceFiat = useDisplaySwEthPriceFiat()
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  // Swell staking function
  const stakeNativeCurrency = useStakeNativeCurrency()

  // Get ETH/swETH rates for staking
  const ethUsdMarketRate = useEthUsdMarketRate()
  const swEthUsdMarketRate = useSwEthUsdMarketRate()
  const stakingRate = useStakingRate()

  const convertSwEthToNativeCurrency = useConvertSwEthToNativeCurrency()
  const convertNativeCurrencyToSwEth = useConvertNativeCurrencyToSwEth()

  // State / data for staking
  const [confirmationProgressOpen, setConfirmationProgressOpen] =
    useState<boolean>(false)

  const { nativeCurrencyDisplayStr, swEthAmountDisplayStr } =
    useStakingDisplayStrings({
      nativeToSwEthRate: stakingRate.data?.nativeToSwEthRate,
    })

  const { status: stakingSubmissionStatus, txHash, error } = useStakeLens()

  /**
   * Responsible for watching the status of the submission and displaying
   * a progress bar upon a pending stake,
   * a banner to the user upon successful staking,
   * or encountering an error while staking.
   */
  useEffect(() => {
    if (stakingSubmissionStatus === StakingSubmissionStatus.PENDING) {
      setConfirmationProgressOpen(true)
    }
    if (stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED) {
      setConfirmationProgressOpen(false)

      const SuccessMsg = (
        <>
          <span>
            Transaction complete! You have staked {nativeCurrencyDisplayStr} for{' '}
            {swEthAmountDisplayStr}.
          </span>
          &nbsp;&nbsp;
          <a target="_blank" rel="noreferrer" href={`${explorer}/tx/${txHash}`}>
            View on Etherscan.
          </a>
        </>
      )

      const nId = notify(SuccessMsg, GLOBAL_NOTIFICATION_TYPES.SUCCESS)
      setTouched(false)
      setSrcTokenInputValue('')
      setDestTokenInputValue('')

      return () => {
        removeNotification(nId)
      }
    }

    if (stakingSubmissionStatus === StakingSubmissionStatus.ERROR) {
      const nId = notify(`${error}.`, GLOBAL_NOTIFICATION_TYPES.ERROR)

      return () => {
        removeNotification(nId)
      }
    }
  }, [
    error,
    explorer,
    nativeCurrencyDisplayStr,
    notify,
    removeNotification,
    setConfirmationProgressOpen,
    setSrcTokenInputValue,
    setDestTokenInputValue,
    setTouched,
    stakingSubmissionStatus,
    swEthAmountDisplayStr,
    txHash,
  ])

  /* Zap/Swap state and logic */
  const zap = useZap()
  const [srcToken, setSrcToken] = useState<any>(null)
  const destToken = TOKEN_LIST_SWETH
  const swapSrcTokenContract = useIERC20Contract(srcToken?.address)
  const [slippageTolerance, setSlippageTolerance] = useState<number>(0.5)

  const srcTokenIsEth = srcToken && srcToken.symbol === TOKEN_LIST_ETH.symbol

  const srcTokenBalance = useMemo<BigNumber>(() => {
    if (!srcToken || !tokenBalancesData) {
      return BigNumber.from(0)
    }

    const token = tokenBalancesData.tokenBalances.find(
      (t: any) => t.address === srcToken.address
    )
    if (!token) {
      return BigNumber.from(0)
    }
    return token.balance
  }, [srcToken, tokenBalancesData])

  // li.fi get swap routes logic
  const getRoutesQueryParams = {
    fromChainId: chainId,
    fromAmount: inputValueToCryptoAmount(
      srcTokenInputValue || '0',
      srcToken?.decimals || 18
    ),
    fromTokenAddress: srcToken?.address,
    toChainId: chainId,
    toTokenAddress: destToken?.address,
    options: {
      slippage: slippageTolerance / 100,
      maxPriceImpact: 0.4,
    },
    fromAddress: account,
    toAddress: account,
  }

  const getRoutesPaused =
    !srcToken || !destToken || (!srcTokenInputValue && !destTokenInputValue)
  const getRoutesQuery = useGetRoutesQuery(
    getRoutesQueryParams,
    getRoutesPaused
  )
  const getRoutesData = getRoutesQuery.data

  /* Swap side effects */
  // set dest token input value if src token input value is changed
  useEffect(() => {
    if (getRoutesData && action === ACTIONS.ZAP) {
      const destTokenAmountFloat =
        parseFloat(srcTokenInputValue) * getRoutesData.swapInfo.exchangeRate
      const destTokenAmountStr = destTokenAmountFloat.toPrecision(6).toString()
      setDestTokenInputValue(destTokenAmountStr)
    }
  }, [action, getRoutesData, srcTokenInputValue])

  /* Allowance and Approval logic */
  const allowance = useAllowance(swapSrcTokenContract)
  const approve = useApprove(swapSrcTokenContract)

  // UI logic and state
  useEffect(() => {
    if (srcTokenInputValue) setTouched(true)
  }, [srcTokenInputValue])

  const ethValueErrorMsg = (): string | null => {
    if (action === ACTIONS.STAKE || srcTokenIsEth) {
      if (!ethBalance.data || !touched) return null

      if (srcTokenInputValue === '') return 'Must enter a value'

      const ethAmountBN = parseNativeCurrency(srcTokenInputValue)
      if (ethAmountBN.eq(0)) return 'Value cannot be 0'
      if (ethAmountBN.gt(ethBalance.data.balance)) return 'Insufficient balance'
    } else if (action === ACTIONS.ZAP) {
      if (!tokenBalancesData || !touched) return null

      if (srcTokenInputValue === '') return 'Must enter a value'

      const tokenAmount = inputValueToCryptoAmount(
        srcTokenInputValue,
        srcToken.decimals
      )
      const tokenAmountBN = BigNumber.from(tokenAmount)
      if (tokenAmountBN.eq(0)) return 'Value cannot be 0'
      if (tokenAmountBN.gt(srcTokenBalance)) return 'Insufficient balance'
    }

    return null
  }

  const preventInteraction = (): boolean => {
    if (
      stakingRate.isLoading ||
      ethBalance.isLoading ||
      stakingEnabled.isLoading ||
      tokenBalancesQuery.isLoading
    ) {
      return true
    }
    if (!stakingEnabled.value) return true
    if (chainId !== deploymentChainId) {
      return true
    }
    if (action === ACTIONS.ZAP && !srcToken) {
      return true
    }
    return false
  }

  const stakeDisabled = () => {
    if (preventInteraction()) return true
    if (ethValueErrorMsg()) return true
    if (srcTokenInputValue === '') return true

    return false
  }

  const showApprove = () => {
    const compareValue = srcTokenInputValue
      ? BigNumber.from(
          inputValueToCryptoAmount(srcTokenInputValue, srcToken.decimals)
        )
      : -1
    const hasEnoughAllowance = allowance.amount.gte(compareValue)

    return !srcTokenIsEth && !hasEnoughAllowance
  }

  const approveDisabled = () => {
    return (
      allowance.isLoading ||
      approve.isLoading ||
      !srcToken ||
      !srcTokenInputValue ||
      !destTokenInputValue ||
      !!ethValueErrorMsg()
    )
  }

  const zapDisabled = () => {
    if (ethValueErrorMsg()) return true
    if (srcTokenInputValue === '') return true
    if (zap.isLoading) return true
    if (!getRoutesData) return true
    if (getRoutesQuery.isLoading || getRoutesQuery.isValidating) return true

    return false
  }

  const routeLoading = () => {
    return (
      action === ACTIONS.ZAP &&
      (getRoutesQuery.isLoading || getRoutesQuery.isValidating)
    )
  }

  /* Interaction handlers */

  const handleActionClick = (action: string) => {
    if (action === ACTIONS.STAKE) {
      setSrcToken(TOKEN_LIST_ETH)
    } else if (action === ACTIONS.ZAP) {
      setSrcToken(undefined)
    }

    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setAction(action)
  }

  const handleTokenSelect = (token: any) => {
    setSrcToken(token)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
  }

  const handleEthInputChange = (event: any) => {
    if (!stakingRate.data) throw new Error(`No staking data`)

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    let decimalPlaces = 18
    if (action === ACTIONS.ZAP) {
      decimalPlaces = Math.min(srcToken.decimals, 12)
    }

    setSrcTokenInputValue(trimDecimalPlaces(value, decimalPlaces))

    if (action === ACTIONS.STAKE) {
      const { nativeToSwEthRate } = stakingRate.data!

      const swEthAmount = convertNativeCurrencyToSwEth(
        parseNativeCurrency(trimDecimalPlaces(value, decimalPlaces)),
        nativeToSwEthRate
      )

      setDestTokenInputValue(trimDecimalPlaces(formatSwEth(swEthAmount), 18))
    }
  }

  const handleMaxClick = () => {
    if (action === ACTIONS.STAKE || srcTokenIsEth) {
      if (!ethBalance.data) return
      const { balance } = ethBalance.data
      handleEthInputChange({ target: { value: formatNativeCurrency(balance) } })
    } else if (action === ACTIONS.ZAP) {
      if (!srcTokenBalance) return
      handleEthInputChange({
        target: { value: formatUnits(srcTokenBalance, srcToken.decimals) },
      })
    }
  }

  const handleSwEthInputChange = (event: any) => {
    if (!stakingRate.data) throw new Error(`No staking data`)

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    setDestTokenInputValue(value)

    if (action === ACTIONS.STAKE) {
      const { swEthToNativeRate } = stakingRate.data!
      const nativeAmount = convertSwEthToNativeCurrency(
        parseSwEth(value),
        swEthToNativeRate
      )

      setSrcTokenInputValue(formatNativeCurrency(nativeAmount))
    }
  }

  const handleSlippageToleranceSelect = (tolerance: number) => {
    setSlippageTolerance(tolerance)
  }

  const handleStakeClick = () => {
    const ethAmountBN = parseNativeCurrency(srcTokenInputValue)
    stakeNativeCurrency(ethAmountBN, referrerAddress)
  }

  const handleApproveClick = async () => {
    const approved = await approve.sendTransaction(srcTokenInputValue, srcToken)
    if (approved) {
      allowance.set(approved)
      notify('Swap approval successful.', GLOBAL_NOTIFICATION_TYPES.SUCCESS)
    }
  }

  const handleZapClick = async () => {
    if (!getRoutesData) {
      return
    }

    const result = await zap.sendTransaction(getRoutesData.route)

    if (result.error) {
      notify(result.error.message, GLOBAL_NOTIFICATION_TYPES.ERROR)
      return
    }

    const SuccessMsg = (
      <>
        <span>
          Transaction complete! You have zapped {srcTokenInputValue}{' '}
          {srcToken.symbol} for {destTokenInputValue} swETH.
        </span>
        &nbsp;&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href={`${explorer}/tx/${result.tx!.hash}`}
        >
          View on Etherscan.
        </a>
      </>
    )

    notify(SuccessMsg, GLOBAL_NOTIFICATION_TYPES.SUCCESS)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setTouched(false)
  }

  const handleCTAClick = () => {
    if (action === ACTIONS.STAKE) {
      setAction(ACTIONS.VAULT)
    }
  }

  const confirmationWidgetOpen = () => {
    return (
      confirmationProgressOpen ||
      approve.status === approve.STATUS.PENDING ||
      zap.status === zap.STATUS.PENDING
    )
  }

  const confirmationMessage = () => {
    if (confirmationProgressOpen) {
      return `Staking ${srcTokenInputValue} ETH`
    } else if (approve.status === approve.STATUS.PENDING) {
      return `Approving spend of ${srcTokenInputValue} ${srcToken.symbol}`
    } else if (zap.status === zap.STATUS.PENDING) {
      return `Zapping ${srcTokenInputValue} ${srcToken.symbol}`
    }

    return ''
  }

  return (
    <StakingWidgetBox>
      <ActionChooser action={action} onActionClick={handleActionClick} />
      {action !== ACTIONS.VAULT && (
        <>
          <AvailableChip
            token={srcToken}
            tokenBalances={tokenBalancesData?.tokenBalances}
          />
          <UtilitiesContainer>
            <CryptoUtilities />
          </UtilitiesContainer>
          <div>
            <FlexRow justify="space-between" align="center">
              <Typography variant="body" size="xlarge" fstyle="bold">
                {action}
              </Typography>
              {action === ACTIONS.STAKE && (
                <SymbolWrapper>
                  <StyledEthIcon />
                  <Typography variant="body" size="large" fstyle="bold">
                    ETH
                  </Typography>
                </SymbolWrapper>
              )}
              {action === ACTIONS.ZAP && (
                <SelectTokenWidget
                  isOpen={tokenSelectOpen}
                  onClose={() => setTokenSelectOpen(false)}
                  onTokenSelect={handleTokenSelect}
                  tokenBalances={tokenBalancesData?.tokenBalances}
                />
              )}
            </FlexRow>
            <EthInputWrapper>
              <StakingWidgetEthInput
                variant="standard"
                value={srcTokenInputValue}
                onChange={handleEthInputChange}
                error={!!ethValueErrorMsg()}
                helperText={ethValueErrorMsg()}
                disabled={preventInteraction()}
                onMaxClick={handleMaxClick}
              />
            </EthInputWrapper>
            {/* TODO: price should be displayed as 'xx USD' */}
            {!ethValueErrorMsg() && ethUsdMarketRate.data && (
              <UsdTypography variant="body" size="xsmall">
                {action === ACTIONS.STAKE && (
                  <>
                    {displayNativeCurrencyPriceFiat(
                      parseNativeCurrency(srcTokenInputValue || '0'),
                      ethUsdMarketRate.data.rate
                    )}
                  </>
                )}
                {action === ACTIONS.ZAP && (
                  <>
                    {getRoutesData?.route &&
                      `${displayFiat(
                        Number(getRoutesData.route.fromAmountUSD)
                      )}`}
                  </>
                )}
              </UsdTypography>
            )}
            <FlexRow justify="space-between" align="center">
              <Typography variant="body" size="xlarge" fstyle="bold">
                Receive
              </Typography>
              <SymbolWrapper>
                <StyledSwethIcon />
                <Typography
                  variant="headline"
                  size="h5"
                  fstyle="bold"
                  letterSpacing="small"
                >
                  swETH
                </Typography>
              </SymbolWrapper>
            </FlexRow>
            <EthInputWrapper>
              {(action !== ACTIONS.ZAP || !routeLoading()) && (
                <SwEthInput
                  variant="standard"
                  value={destTokenInputValue}
                  onChange={handleSwEthInputChange}
                  disabled={preventInteraction() || action === ACTIONS.ZAP}
                />
              )}
              {routeLoading() && (
                <>
                  <SwEthInput variant="standard" disabled={true} />
                  <InputCircularProgress size={35} />
                </>
              )}
            </EthInputWrapper>
            {swEthUsdMarketRate.data && (
              <UsdTypography variant="body" size="xsmall">
                {action === ACTIONS.STAKE && (
                  <>
                    {displaySwEthPriceFiat(
                      parseSwEth(destTokenInputValue || '0'),
                      swEthUsdMarketRate.data.rate
                    )}
                  </>
                )}
                {action === ACTIONS.ZAP && (
                  <>
                    {getRoutesData?.route &&
                      `${displayFiat(Number(getRoutesData.route.toAmountUSD))}`}
                  </>
                )}
              </UsdTypography>
            )}
          </div>
          <Divider />
          {action === ACTIONS.STAKE && <ExchangeInfo />}
          {action === ACTIONS.ZAP && (
            <SwapInfo
              isLoading={
                getRoutesQuery?.isLoading || getRoutesQuery?.isValidating
              }
              srcTokenSymbol={srcToken?.symbol || ''}
              destTokenSymbol={destToken?.symbol || ''}
              bestRouteInfo={getRoutesData?.swapInfo}
              onSlippageToleranceSelect={handleSlippageToleranceSelect}
            />
          )}
          <div>
            {account && action === ACTIONS.STAKE && (
              <StakeWidgetButton
                disabled={stakeDisabled()}
                onClick={handleStakeClick}
              >
                Stake
              </StakeWidgetButton>
            )}
            {account && action === ACTIONS.ZAP && (
              <>
                {!srcToken && (
                  <StakeWidgetButton onClick={() => setTokenSelectOpen(true)}>
                    Select Token
                  </StakeWidgetButton>
                )}
                {srcToken && showApprove() && (
                  <StakeWidgetButton
                    disabled={approveDisabled()}
                    onClick={handleApproveClick}
                  >
                    Approve
                  </StakeWidgetButton>
                )}
                {srcToken && !showApprove() && (
                  <StakeWidgetButton
                    disabled={zapDisabled()}
                    onClick={handleZapClick}
                  >
                    {routeLoading() && <ButtonCircularProgress size={35} />}
                    {!routeLoading() && 'Zap'}
                  </StakeWidgetButton>
                )}
              </>
            )}
            {!account && (
              <StakeConnectButton>Connect wallet</StakeConnectButton>
            )}
          </div>
          <CTAWrapper onClick={handleCTAClick}>
            <CallToAction action={action} />
          </CTAWrapper>
          <TransactionInProgressModal
            action={action}
            zap={zap}
            approve={approve}
            status={stakingSubmissionStatus}
            nativeCurrencyDisplayStr={nativeCurrencyDisplayStr}
            swEthAmountDisplayStr={swEthAmountDisplayStr}
            txhash={txHash}
          />
          <StakingConfirmationProgressWidget
            open={confirmationWidgetOpen()}
            title="Confirmation pending"
            message={confirmationMessage()}
          />
          <div>
            <SwellStatistics />
          </div>
        </>
      )}
      {action === ACTIONS.VAULT && <VaultView />}
    </StakingWidgetBox>
  )
}

export { StakingWidget }
