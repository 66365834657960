import fromExponential from 'from-exponential'
import { formatEther, parseEther, parseUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'

export function ensureStandardNotationString(input: string) {
  return fromExponential(input.trim())
}

/**
 * Truncates the fractional units of a number string up to the specified decimal units.
 * @param {string} input - The input number string.
 * @param {number} decimalUnits - The number of decimal units to keep.
 * @returns {string} The truncated number string.
 */
function truncateFractionalUnits(input: string, decimalUnits: number): string {
  const decimalIndex: number = input.indexOf('.')
  if (decimalIndex !== -1) {
    const endIndex: number = decimalIndex + decimalUnits + 1 // Calculate the end index to include desired decimal units and the decimal point
    const truncatedString: string = input.substring(0, endIndex)
    return truncatedString
  }
  return input // Return the original string if there are no fractional units
}

export const parseUnitsSafe = (input: string, decimals: number) => {
  const inputSafe = truncateFractionalUnits(
    ensureStandardNotationString(input),
    decimals
  )
  return parseUnits(inputSafe, decimals)
}

export const parseWeiSafe = (v: string) => parseUnitsSafe(v, 0)

export const toFloatBigNumber = (value: BigNumber) =>
  parseFloat(formatEther(value))

export const applyRateBigNumber = (
  value: BigNumber,
  rate: number
): BigNumber => {
  const oneEth = parseEther('1')
  const rateBN = parseUnitsSafe(String(rate), 18)

  const withRateHighPrecision = value.mul(rateBN)
  const afterDecimalConversion = withRateHighPrecision.div(oneEth)

  return afterDecimalConversion
}

export const applyRateBigNumberToFloat = (value: BigNumber, rate: number) => {
  return toFloatBigNumber(applyRateBigNumber(value, rate))
}
