import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { formatDistanceToNowStrict } from 'date-fns'
import { Accordion } from '@swell-ui/Accordion'
import { CaretDownIcon } from '@swell-ui/icons/CaretDownIcon'
import { CaretRightIcon } from '@swell-ui/icons/CaretRightIcon'
import { CaretUpIcon } from '@swell-ui/icons/CaretUpIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Pagination } from '@swell-ui/Pagination'
import { Table, TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { Skeleton } from '@swell-ui/Skeleton'
import { useDisplaySwEth } from '@/hooks/useSwEthDisplay'
import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import { useChainInfo } from '@/state/deployments/hooks'
import { abbreviateTimeWords } from '@/util/datetime'
import { shortenAddress } from '@/util/hexStrings'
import { AppStake } from '@/types/stake'

interface StakingPoolActivityTableProps {
  abbreviated?: boolean
  stakes?: AppStake[]
  pagination?: TableConfig['pagination']
}

type RowType = {
  wallet: string
  age: string
  action: string
  deposit: string
  receive: string
  link: React.ReactNode
  txHash: string
}

const COLUMNS: TableConfig['columns'] = [
  {
    key: 'wallet',
    label: 'Wallet',
  },
  {
    key: 'age',
    label: 'Age',
  },
  {
    key: 'action',
    label: 'Action',
  },
  {
    key: 'deposit',
    label: 'Deposit',
  },
  {
    key: 'receive',
    label: 'Receive',
  },
  {
    key: 'link',
    label: '',
  },
]

const LOADING_LENGTH = 9

const StyledTable = styled(Table)`
  .MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 6px;
  }

  .MuiTableRow-root {
    height: 70px;
    margin-bottom: 6px;
    border-radius: 16px;
  }

  .MuiTableRow-head {
    height: 22px;
    .MuiTableCell-root {
      padding: 12px 16px 24px;
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      background-color: ${({ theme }) => theme.colors.oceanBlue['100']};

      &:first-child {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      &:last-child {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    .receive-cell {
      color: ${({ theme }) => theme.colors.lightBlue['50']};
    }
  }

  .wallet-cell,
  .age-cell,
  .action-cell,
  .deposit-cell,
  .receive-cell {
    min-width: 208px;
  }
`

function StakingPoolActivityTableFull({
  config,
  loading,
}: {
  config: any
  loading: boolean
}) {
  return <StyledTable loading={loading} config={config} />
}

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-content {
    background: ${({ theme }) => theme.colors.oceanBlue['100']};
    margin: 0;
    margin-bottom: 8px;
    border-radius: 12px;

    &.Mui-expanded {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.05)
        ),
        #051a2b;
      margin: 0 0 8px 0;
    }
  }

  .MuiAccordionDetails-root {
    margin-bottom: 8px;
  }

  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: -0.03em;
`

const AccordionSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  padding: 0 16px;
`

const AccordionDetails = styled.div`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #051a2b;
  padding: 16px;
  border-radius: 12px;
`

const LoaderWrapper = styled.div`
  flex: 1 1 auto;
  padding-right: 16px;
`

const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    display: none;
  }
`

const Key = styled.div`
  width: 50%;
`

const Value = styled.div`
  flex: 1 1 auto;
`

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: space-around;
  }
`

function StakingPoolActivityTableMobile({
  config,
  loading,
}: {
  config: any
  loading: boolean
}) {
  const [whichOpen, setWhichOpen] = useState<number>(-1)

  const handleAccordionChange = (index: number) => {
    if (whichOpen === index) {
      return setWhichOpen(-1)
    }

    setWhichOpen(index)
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (!config.pagination) {
      return
    }
    config.pagination.onChange(page)
  }

  const loadingArray = []
  for (let i = 0; i < config.loadingLength; i++) {
    loadingArray.push(i)
  }

  return (
    <div>
      {loading &&
        loadingArray.map((index: number) => {
          const accordionConfig = {
            summary: (
              <AccordionSummary>
                <LoaderWrapper>
                  <Skeleton />
                </LoaderWrapper>
                <LoaderWrapper>
                  <Skeleton />
                </LoaderWrapper>
                <div>
                  {whichOpen !== index && <CaretDownIcon />}
                  {whichOpen === index && <CaretUpIcon />}
                </div>
              </AccordionSummary>
            ),
            details: <div></div>,
          }

          return (
            <StyledAccordion
              expanded={whichOpen === index}
              config={accordionConfig}
              onChange={() => handleAccordionChange(index)}
            />
          )
        })}
      {!loading &&
        config.rows.map((row: RowType, index: number) => {
          const accordionConfig = {
            summary: (
              <AccordionSummary>
                <Key>{row.age}</Key>
                <Value>{row.deposit}</Value>
                <div>
                  {whichOpen !== index && <CaretDownIcon />}
                  {whichOpen === index && <CaretUpIcon />}
                </div>
              </AccordionSummary>
            ),
            details: (
              <AccordionDetails>
                <DetailsItem>
                  <Key>Age</Key>
                  <Value>{row.age}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
                <DetailsItem>
                  <Key>Action</Key>
                  <Value>{row.action}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
                <DetailsItem>
                  <Key>Deposit</Key>
                  <Value>{row.deposit}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
                <DetailsItem>
                  <Key>Receive</Key>
                  <Value>{row.receive}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
                <DetailsItem>
                  <Key>Wallet</Key>
                  <Value>{row.wallet}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
              </AccordionDetails>
            ),
          }

          return (
            <StyledAccordion
              expanded={whichOpen === index}
              config={accordionConfig}
              onChange={() => handleAccordionChange(index)}
            />
          )
        })}
      <StyledPagination count={10} onChange={handlePageChange} />
      {config.pagination.totalResults && (
        <FlexRow justify="center">
          <Typography variant="body" size="xsmall">
            {config.pagination.totalResults}
          </Typography>
        </FlexRow>
      )}
    </div>
  )
}

function StakingPoolActivityTable({
  abbreviated,
  pagination,
  stakes = [],
}: StakingPoolActivityTableProps) {
  const { nativeCurrency, explorer } = useChainInfo()
  const displayNativeCurrency = useDisplayNativeCurrency()
  const displaySwEth = useDisplaySwEth()

  const tableConfig = React.useMemo<TableConfig>(() => {
    if (!stakes) {
      return {
        columns: COLUMNS,
        rows: [],
        loadingLength: LOADING_LENGTH,
        pagination,
      }
    }

    const rows = stakes.map((stake: AppStake): RowType => {
      return {
        wallet: shortenAddress(stake.address, 3, 4),
        age: `${abbreviateTimeWords(
          formatDistanceToNowStrict(stake.timestamp)
        )} ago`,
        action: `${nativeCurrency.symbol} staked`,
        deposit: displayNativeCurrency(stake.stakedWei),
        receive: displaySwEth(stake.mintedSweth),
        link: <CaretRightIcon />,
        txHash: stake.txHash,
      }
    })

    return {
      columns: COLUMNS,
      rows: rows,
      loadingLength: LOADING_LENGTH,
      pagination,
      onRowClick: abbreviated
        ? undefined
        : (row: any) => {
            window.open(`${explorer}/tx/${row.txHash}`, '_newtab')
          },
    }
  }, [
    abbreviated,
    displayNativeCurrency,
    displaySwEth,
    explorer,
    nativeCurrency,
    pagination,
    stakes,
  ])

  if (abbreviated) {
    return (
      <StakingPoolActivityTableMobile
        config={tableConfig}
        loading={stakes.length === 0}
      />
    )
  }

  return (
    <StakingPoolActivityTableFull
      config={tableConfig}
      loading={stakes.length === 0}
    />
  )
}

export { StakingPoolActivityTable }
