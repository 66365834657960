import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { Alert, ALERT_LEVELS } from '@swell-ui/Alert'
import { Box } from '@swell-ui/Box'
import { Grid } from '@swell-ui/Grid'
import { Table, TableConfig } from '@swell-ui/Table'
import { SectionBoxLabel } from '@/components/SectionBoxLabel'
import { IDepositCollectionValidationResult } from '@/state/depositValidation/models/DepositCollectionValidationResult'

interface DepositCollectionValidationSummaryProps {
  validationResult?: IDepositCollectionValidationResult
}

const ContainerBox = styled<any>(Box)`
  padding: 24px 32px 0;
  overflow: hidden;
`

const ValidationSummaryTable = styled(Table)`
  .numErrors-cell {
    padding-left: 0;
  }
`

const ValidationAlert = styled(Alert)`
  margin-left: -32px;
  margin-right: -32px;
`

const Spacer = styled.div`
  width: 100%;
  height: 24px;
`

const columns: TableConfig['columns'] = [
  {
    key: 'numErrors',
    label: 'Number of errors',
  },
  {
    key: 'errorType',
    label: 'Error type',
  },
]

function parseErrorsToRows(dataProblems: any, inputProblem: any) {
  const rows: any[] = []
  const errorCountMap = {} as any

  dataProblems?.forEach((problem: any) => {
    if (!errorCountMap[problem.code]) {
      errorCountMap[problem.code] = 1
    } else {
      errorCountMap[problem.code] += 1
    }
  })

  if (inputProblem) {
    rows.push({
      numErrors: 1,
      errorType: inputProblem.code,
    })
  }

  Object.keys(errorCountMap).forEach((key) => {
    rows.push({
      numErrors: errorCountMap[key],
      errorType: key,
    })
  })

  return rows
}

function DepositCollectionValidationSummary({
  validationResult,
}: DepositCollectionValidationSummaryProps) {
  const config = useMemo<TableConfig>(() => {
    if (!validationResult || validationResult.valid) {
      return {
        columns,
        rows: [],
      }
    }

    const rows = parseErrorsToRows(
      validationResult.dataProblems,
      validationResult.inputProblem
    )

    return {
      columns,
      rows,
    }
  }, [validationResult])

  return (
    <ContainerBox>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <SectionBoxLabel>Validation summary</SectionBoxLabel>
        </Grid>
        <Grid item>
          <ValidationSummaryTable config={config} />
        </Grid>
      </Grid>
      {validationResult?.valid ? (
        <ValidationAlert level={ALERT_LEVELS.SUCCESS}>
          Validation successful, please submit keys.
        </ValidationAlert>
      ) : (
        <Spacer />
      )}
    </ContainerBox>
  )
}

export { DepositCollectionValidationSummary }
