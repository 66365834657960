import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Stack } from '@swell-ui/Stack'
import { Typography } from '@swell-ui/Typography'
import { TopNavLink } from './TopNavLink'

function TopNavRootLinkStack() {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <TopNavLink to="/">
        <Typography variant="body" size="medium" fstyle="bold">
          Stake
        </Typography>
      </TopNavLink>
      <TopNavLink to="/earn">
        <Typography variant="body" size="medium" fstyle="bold">
          Earn
        </Typography>
      </TopNavLink>
      <TopNavLink to="/portfolio">
        <Typography variant="body" size="medium" fstyle="bold">
          Portfolio
        </Typography>
      </TopNavLink>
      <TopNavLink to="/voyage">
        <Typography variant="body" size="medium" fstyle="bold">
          Voyage
        </Typography>
      </TopNavLink>
    </Stack>
  )
}

function TopNavOperatorLinkStack() {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <TopNavLink to="/operators/dashboard">
        <Typography variant="body" size="medium" fstyle="bold">
          Dashboard
        </Typography>
      </TopNavLink>
      <TopNavLink to="/operators/validator-keys">
        <Typography variant="body" size="medium" fstyle="bold">
          Validator keys
        </Typography>
      </TopNavLink>
      <TopNavLink to="/operators/all-operators">
        <Typography variant="body" size="medium" fstyle="bold">
          All operators
        </Typography>
      </TopNavLink>
    </Stack>
  )
}

function TopNavBarLinkStack() {
  return (
    <Routes>
      <Route path="/operators/*" element={<TopNavOperatorLinkStack />} />
      <Route path="/*" element={<TopNavRootLinkStack />} />
    </Routes>
  )
}

export { TopNavBarLinkStack }
