import { useState } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { BigNumber } from 'ethers'
import { usePopulateTransactionData } from '@/services/LifiService'

export function useZap() {
  const { provider } = useSwellWeb3()
  const populateTransactionData = usePopulateTransactionData()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fromToken, setFromToken] = useState<any>(null)
  const [fromAmount, setFromAmount] = useState<BigNumber>(BigNumber.from(0))
  const [toToken, setToToken] = useState<any>(null)
  const [toAmount, setToAmount] = useState<BigNumber>(BigNumber.from(0))

  const STATUS = {
    IDLE: 'idle',
    GET_ROUTE: 'getroute',
    BUILDING: 'building',
    PROMPTING: 'prompting',
    PENDING: 'pending',
  }
  const [status, setStatus] = useState<string>(STATUS.IDLE)
  const [tx, setTx] = useState<any>(undefined)

  function reset() {
    setStatus(STATUS.IDLE)
    setFromToken(null)
    setFromAmount(BigNumber.from(0))
    setToToken(null)
    setFromToken(BigNumber.from(0))
    setIsLoading(false)
  }

  const sendTransaction = async (route: any) => {
    if (!route) {
      return {
        error: { message: 'No zap route specified.' },
      }
    }

    setFromToken(route.fromToken)
    setFromAmount(BigNumber.from(route.fromAmount))
    setToToken(route.toToken)
    setToAmount(BigNumber.from(route.toAmount))

    setIsLoading(true)

    const step = route.steps[0]

    setStatus(STATUS.BUILDING)
    const res = await populateTransactionData(step)
    const transactionRequest = res.transactionRequest

    if (!transactionRequest) {
      setStatus(STATUS.IDLE)
      return {
        error: { message: 'Error building transaction data.' },
      }
    }

    setStatus(STATUS.PROMPTING)
    try {
      const signer = provider.getSigner()
      const tx = await signer.sendTransaction(transactionRequest as any)
      setTx(tx)
      setStatus(STATUS.PENDING)
      await tx.wait()
      reset()
      return {
        tx: tx,
      }
    } catch (error: any) {
      reset()
      return {
        error: { message: error.reason || 'Unknown error' },
      }
    }
  }

  return {
    isLoading,
    sendTransaction,
    fromToken,
    fromAmount,
    toToken,
    toAmount,
    setStatus,
    status,
    STATUS,
    tx,
  }
}
