import { useChainInfo } from '@/state/deployments/hooks'
import { displayFiat } from '@/util/displayFiat'
import { BigNumber } from 'ethers'
import { useCallback } from 'react'
import {
  useConvertNativeCurrencyToFiat,
  useConvertNativeCurrencyToSwEth,
} from '../useCurrencyConversions'
import { useToFloatNativeCurrency } from '../useNativeCurrencyFormatting'
import { useDisplaySwEth } from '../useSwEthDisplay'
import { formatWithConfig } from '@/util/number'

export const useDisplayNativeCurrency = () => {
  const toFloatNativeCurrency = useToFloatNativeCurrency()
  const { nativeCurrency } = useChainInfo()

  return useCallback(
    (
      amountBN: BigNumber,
      {
        precision = 4,
        localize = true,
        numberOnly,
      }: {
        precision?: number
        localize?: boolean
        numberOnly?: boolean
      } = {}
    ) => {
      const floatAmount = toFloatNativeCurrency(amountBN)
      const formattedFloat = formatWithConfig(floatAmount, {
        precision,
        localize,
      })

      if (numberOnly) {
        return `${formattedFloat}`
      }

      return `${formattedFloat} ${nativeCurrency.symbol}`
    },
    [nativeCurrency.symbol, toFloatNativeCurrency]
  )
}

export const useDisplayNativeCurrencyPriceFiat = () => {
  const convertNativeCurrencyToFiat = useConvertNativeCurrencyToFiat()

  return useCallback(
    (nativeCurrencyAmountBN: BigNumber, nativeToFiatRate: number) => {
      return displayFiat(
        convertNativeCurrencyToFiat(nativeCurrencyAmountBN, nativeToFiatRate)
      )
    },
    [convertNativeCurrencyToFiat]
  )
}

export const useDisplayNativeCurrencyInSwEth = () => {
  const displaySwEth = useDisplaySwEth()
  const convertNativeCurrencyToSwEth = useConvertNativeCurrencyToSwEth()

  return useCallback(
    (
      ethAmountBN: BigNumber,
      nativeToSwEthRate: number,
      { precision, localize }: { precision?: number; localize?: boolean } = {}
    ) => {
      return displaySwEth(
        convertNativeCurrencyToSwEth(ethAmountBN, nativeToSwEthRate),
        { precision, localize }
      )
    },
    [convertNativeCurrencyToSwEth, displaySwEth]
  )
}
