import { useDepositCollectionValidatorInstance } from '@/services/DepositCollectionValidator/hooks'
import {
  useDepositCollectionValidationState,
  useValidateDepositCollection,
} from '@/state/depositValidation/hooks'
import { IDepositCollectionValidationResult } from '@/state/depositValidation/models/DepositCollectionValidationResult'
import { ProgressPayload } from '../../services/TaskRunner/ITaskRunner'

export interface IUseDepositCollectionValidationArgs {
  input: string
}

export interface IUseDepositCollectionValidationOutput {
  validationResult: IDepositCollectionValidationResult | undefined
  validating: boolean
  validationProgress: ProgressPayload | undefined

  validate: () => void
}

const useDepositCollectionValidation =
  (): IUseDepositCollectionValidationOutput => {
    const { validating, validationProgress, validationResult } =
      useDepositCollectionValidationState()

    const depositCollectionValidator = useDepositCollectionValidatorInstance()
    const validate = useValidateDepositCollection(depositCollectionValidator)

    return {
      validate,
      validating,
      validationProgress,
      validationResult,
    }
  }

export default useDepositCollectionValidation
