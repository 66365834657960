import useSWRImmutable from 'swr/immutable'
import { useSwellWeb3 } from '@swell-web3/core'
import { useSwETHContractView } from '@/hooks/useContract'

export function useSwEthBalance() {
  const { account, chainId } = useSwellWeb3()

  const swEth = useSwETHContractView()

  return useSWRImmutable(
    account && swEth ? [swEth.address, chainId, 'balanceOf', account] : null,
    async () => ({ balance: await swEth!.balanceOf(account!) })
  )
}

export function useEthBalance() {
  const { account, chainId, provider } = useSwellWeb3()

  return useSWRImmutable(
    account ? [chainId, 'getBalance', account] : null,
    async () => {
      return {
        balance: await provider!.getBalance(account!),
      }
    }
  )
}
