import { BigNumber } from 'ethers'

// The purpose of this value is to be used as an upper bound for gas estimates for
//  creating stake transactions. It is also used to estimate the transaction fee of the
//  stake action in the UI.
//
// It is expected that a value this high should ensure the stake operation succeeds. At
//  run time, the maximum of (STAKE_GAS_ESTIMATE, $provider_gas_estimate) should be used
//  for safety (although unlikely our estimate will be exceeded).
//
// Sampled from one deposit transaction using the mock swETH contract

// Link to investigation which yielded the following value:
// https://www.notion.so/swellnetwork/v3-frontend-Gas-Estimations-d2987fd96e7c412ca4aee8b1f4176cb9?pvs=4#9cacea83fac846879f296523295275fc
const STAKE_GAS_UPPER_ESTIMATE = 120_000

export const getStakingUpperGasEstimate = () => {
  return BigNumber.from(STAKE_GAS_UPPER_ESTIMATE)
}

// ---

// The purpose of these values are to be used as upper bound for gas estimates for
//  creating addNewValidatorDetails transactions.
// To account for different batch sizes, an estimate for the smallest/largest possible batches
//  was procured.

// Link to investigation which yielded the following values:
// https://www.notion.so/swellnetwork/v3-frontend-Gas-Estimations-d2987fd96e7c412ca4aee8b1f4176cb9?pvs=4#57431725b5c44818b5ddbdd9c5781f44

// Chunk size: 50 keys
const VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_50 = 10_641_000
// Chunk size: 1 key
const VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_1 = 273_000

const LARGEST_ESTIMATED_CHUNK_SIZE = 50

export const getValidatorKeyGasEstimate = (nKeys: number) => {
  const rangeMagnitude =
    VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_50 -
    VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_1

  const base = VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_1

  return BigNumber.from(
    Math.ceil(base + rangeMagnitude * (nKeys / LARGEST_ESTIMATED_CHUNK_SIZE))
  )
}
