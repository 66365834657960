import React, { useState } from 'react'
import styled from 'styled-components'
import { CaretDownIcon } from '@swell-ui/icons/CaretDownIcon'
import { Chip } from '@swell-ui/Chip'
import { FlexRow } from '@swell-ui/FlexRow'
import { TextInput } from '@swell-ui/inputs/TextInput'
import { WarningIcon } from '@swell-ui/icons/WarningIcon'

interface SlippageToleranceProps {
  onSelect: (tolerance: number) => any
}

interface SlippageOption {
  value: number
}

const SlippageKey = styled.div`
  font-weight: 600;
`

const SlippagePickWrapper = styled(FlexRow)`
  margin: 12px 0;
`

const StyledCarat = styled(CaretDownIcon)`
  position: relative;
  top: -1px;
  path {
    fill: ${({ theme }) => theme.colors.white['125']};
  }

  &:hover {
    cursor: pointer;
  }
`

const StyledChip = styled<any>(Chip)`
  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.colors.white['50']};
    border-color: ${theme.colors.white['50']};
  `}
`

const StyledTextInput = styled<any>(TextInput)`
  width: 70px;

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.colors.white['50']};
    background: transparent;
    border-radius: 100px;
    opacity: 0.4;
  }

  input {
    height: 24px;
    padding: 4px 12px;
    font-size: 12px;
  }

  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.white['50']};
      opacity: 0.7;
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.white['50']};
      opacity: 1;
    }
  }

  ${({ active }) =>
    active &&
    `
    .MuiOutlinedInput-notchedOutline {
      opacity: 1;
    }
  `}
`

const WarningRow = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.lightBlue['50']};
    }
  }
`

const SLIPPAGE_OPTIONS: SlippageOption[] = [
  {
    value: 0.1,
  },
  {
    value: 0.5,
  },
  {
    value: 1,
  },
]

function SlippageTolerance({ onSelect }: SlippageToleranceProps) {
  const [tolerance, setTolerance] = useState<number>(0.5)
  const [showSelect, setShowSelect] = useState<boolean>(false)

  const handleToleranceSelect = (amount: number) => {
    setTolerance(amount)
    onSelect(amount)
  }

  const handleCustomToleranceInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const amount = parseFloat(e.target.value)
    setTolerance(amount)
    onSelect(amount)
  }

  const handleCustomToleranceInputFocus = (e: any) => {
    const amount = parseFloat(e.target.value)
    if (amount) {
      setTolerance(amount)
    }
  }

  const handleSelectShow = () => {
    setShowSelect(!showSelect)
  }

  return (
    <>
      <FlexRow justify="space-between" align="center">
        <SlippageKey>Slippage tolerance</SlippageKey>
        <div>
          {tolerance}% <StyledCarat onClick={handleSelectShow} />
        </div>
      </FlexRow>
      {showSelect && (
        <>
          <SlippagePickWrapper align="center" gap="4">
            {SLIPPAGE_OPTIONS.map((option: SlippageOption) => {
              return (
                <StyledChip
                  active={tolerance === option.value}
                  label={`${option.value}%`}
                  onClick={() => handleToleranceSelect(option.value)}
                  variant="outlined"
                />
              )
            })}
            <StyledTextInput
              active={tolerance !== 0.1 && tolerance !== 0.5 && tolerance !== 1}
              placeholder="Custom"
              onChange={handleCustomToleranceInputChange}
              onFocus={handleCustomToleranceInputFocus}
            />
          </SlippagePickWrapper>
          {tolerance > 1 && (
            <WarningRow align="center" gap="8">
              <WarningIcon />
              <div>
                Your transaction may be frontrun and result in an unfavorable
                swap.
              </div>
            </WarningRow>
          )}
        </>
      )}
    </>
  )
}

export { SlippageTolerance }
