import React from 'react'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@swell-ui/KeyValueList'
import { displayFiat } from '@/util/displayFiat'
import { formatWithConfig } from '@/util/number'
import { SlippageTolerance } from './SlippageTolerance'

interface SwapInfoProps {
  isLoading?: boolean
  bestRouteInfo: any
  srcTokenSymbol: string
  destTokenSymbol: string
  onSlippageToleranceSelect: (tolerance: number) => any
}

function buildExchageRateValue(
  bestRouteInfo: any,
  srcTokenSymbol: string,
  destTokenSymbol: string
) {
  const formattedExchangeRate = formatWithConfig(bestRouteInfo.exchangeRate, {
    precision: 4,
  })
  return `1 ${srcTokenSymbol} = ${formattedExchangeRate} ${destTokenSymbol}`
}

function SwapInfo({
  isLoading,
  bestRouteInfo,
  srcTokenSymbol,
  destTokenSymbol,
  onSlippageToleranceSelect,
}: SwapInfoProps) {
  const swapInfoData = {
    exchangeRate: bestRouteInfo
      ? buildExchageRateValue(bestRouteInfo, srcTokenSymbol, destTokenSymbol)
      : undefined,
    fees: bestRouteInfo?.gasCostUSD
      ? `~${displayFiat(Number(bestRouteInfo.gasCostUSD))}`
      : undefined,
    orderRoute: bestRouteInfo
      ? `${bestRouteInfo.percent}% ${bestRouteInfo.exchange}`
      : undefined,
    priceImpact: bestRouteInfo
      ? `${bestRouteInfo.priceImpact.toFixed(2)}%`
      : undefined,
  }

  const swapInfoListConfig = {
    list: [
      {
        label: 'Exchange rate',
        value: (
          <AsyncDiv loading={isLoading || false}>
            {() => {
              return <>{swapInfoData.exchangeRate || '-'}</>
            }}
          </AsyncDiv>
        ),
      },
      {
        label: !swapInfoData.exchangeRate ? 'Slippage tolerance' : '',
        value: !swapInfoData.exchangeRate ? '-' : '',
        component: swapInfoData.exchangeRate && (
          <SlippageTolerance onSelect={onSlippageToleranceSelect} />
        ),
      },
      // {
      //   label: 'Price impact',
      //   value: (
      //     <AsyncDiv loading={isLoading || false}>
      //       {() => {
      //         return <>{swapInfoData.priceImpact || '-'}</>
      //       }}
      //     </AsyncDiv>
      //   ),
      // },
      {
        label: 'Fees',
        value: (
          <AsyncDiv loading={isLoading || false}>
            {() => {
              return <>{swapInfoData.fees || '-'}</>
            }}
          </AsyncDiv>
        ),
      },
      {
        label: 'Order route',
        value: (
          <AsyncDiv loading={isLoading || false}>
            {() => {
              return <>{swapInfoData.orderRoute || '-'}</>
            }}
          </AsyncDiv>
        ),
      },
    ],
  }

  return <KeyValueList config={swapInfoListConfig} />
}

export { SwapInfo }
