import React from 'react'

function BridgeIcon(props: any) {
  return (
    <svg
      {...props}
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.85714 11.8095H6.14286V6.66667C5.38095 6.28572 3.61905 5.90476 2.85714 5.52381V11.9048V11.8095ZM17.7619 6.66667V11.8095H21.0476V5.42857C20.2857 5.90476 17.5238 6.28571 17.7619 6.57143V6.66667ZM16.381 11.8095V7.42857C15.3333 7.71429 14.2857 7.90476 13.2381 7.90476V11.8095H16.381ZM10.7619 11.8095V7.90476C9.71429 7.80952 8.66667 7.61905 7.61905 7.42857V11.8095H10.7619ZM21.0476 2.28571V0H24V17.2381H21.0476V14.2857H2.95238V17.2381H0V0H2.95238V2.28571C3.04762 2.38095 3.14286 2.47619 3.2381 2.57143C8.19048 6.57143 15.8095 6.57143 20.7619 2.57143C20.8571 2.47619 20.9524 2.38095 21.0476 2.28571Z"
        fill="white"
      />
    </svg>
  )
}

export { BridgeIcon }
