import {
  DepositsManagerStatsResponse,
  TransactionNative,
  TransactionToken,
} from '@/submodules/v3-shared/ts/connect/swell/v3/operator_pb'
import { TwitterUserResponse } from '@v3-backend/twitter_pb'
import { Reprice } from '@v3-backend/rates_pb'
import { Stake } from '@v3-backend/staker_pb'
import { EarnAPY, EarnAPYsResponse } from '@v3-backend/stats_pb'
import {
  VoyageLeaderboardRecord,
  VoyageStatusResponse,
  VoyageUserResponse,
} from '@v3-backend/voyage_pb'
import { AppEarnAPRsResponse } from '@/types/earn'
import { AppReprice } from '@/types/rates'
import { AppStake } from '@/types/stake'
import { TokenPoolExternal } from '@/types/tokens'
import { AppTransactionNative, AppTransactionToken } from '@/types/transaction'
import { AppTwitterUser } from '@/types/twitter'
import {
  AppVoyageLeaderboardRecord,
  AppVoyageStatus,
  AppVoyageUser,
} from '@/types/voyage'
import { Timestamp } from '@bufbuild/protobuf'
import { BigNumber } from 'ethers'

export const earnAPRsAdapter = ({
  featured,
  topApr,
  available,
}: EarnAPYsResponse): AppEarnAPRsResponse => {
  const parseEarnAPR = (earnAPY: EarnAPY): TokenPoolExternal => {
    return {
      id: earnAPY.id,
      apy: earnAPY.apy,
      category: earnAPY.category,
      chainId: earnAPY.chainId,
      chainName: earnAPY.chainName,
      chainLogo: earnAPY.chainLogo,
      link: earnAPY.link,
      logo: earnAPY.logo,
      positionName: earnAPY.positionName,
      positionTokens: earnAPY.positionTokens,
      protocol: earnAPY.protocol,
      tokenIconList: earnAPY.tokenIconList,
      tvl: earnAPY.tvl,
    }
  }

  return {
    featured: featured.map(parseEarnAPR),
    topApy: topApr.map(parseEarnAPR),
    pools: available.map(parseEarnAPR),
  }
}

/**
 * Converts "TransactionToken" data from the v3-backend APIs into a form that the frontend can use
 *
 * @param param0 TransactionToken data (received from v3-backend API)
 * @returns AppTransactionToken data (standardized format to be used in the app)
 */
export const transactionTokenAdapter = ({
  createdAt,
  value: amountWei,
  ...tx
}: TransactionToken): AppTransactionToken => {
  return {
    ...tx,
    amount: BigNumber.from(amountWei),
    createdAt: timestampAdapter(createdAt),
  }
}

/**
 * Converts "TransactionNative" data from the v3-backend APIs into a form that the frontend can use
 *
 * @param param0 TransactionNative data (received from v3-backend API)
 * @returns AppTransactionNative data (standardized format to be used in the app)
 */
export const transactionNativeAdapter = ({
  valueWei,
  createdAt,
  ...tx
}: TransactionNative): AppTransactionNative => {
  return {
    ...tx,
    keysSubmitted: valueWei,
    createdAt: timestampAdapter(createdAt),
  }
}

export function timestampAdapter(timestamp: Timestamp): number
export function timestampAdapter(
  timestamp: Timestamp | undefined
): number | undefined

/**
 * Converts protobuf timestamp into a form that is usable by the frontend
 * Gracefully handles the timestamp being undefined. Overloads ensure type invariance.
 *
 * @param timestamp Protobuf timestamp
 * @returns time in milliseconds
 */
export function timestampAdapter(timestamp: Timestamp | undefined) {
  return timestamp ? Number(timestamp.seconds) * 1000 : undefined
}

/**
 * Converts DepositsManagerStatsResponse into a form that is usable by the frontend
 *
 * @param response API response for deposit manager stats
 * @returns Transformed API response
 */
export const depositManagerStatsAdapter = (
  response: DepositsManagerStatsResponse
) => {
  const { bufferedWei, currentCapacityWei, unusedKeysCount, lastDepositAt } =
    response

  return {
    bufferedEth: BigNumber.from(bufferedWei),
    currentCapacityEth: BigNumber.from(currentCapacityWei),
    unusedKeysCount: Number(unusedKeysCount),
    lastDepositAt: timestampAdapter(lastDepositAt),
  }
}

/**
 * Converts "VoyageLeaderboardRecord" data from the v3-backend APIs into a form that the frontend can use
 *
 * @param param0 VoyageLeaderboardRecord data (received from v3-backend API)
 * @returns AppVoyageLeaderboardRecord data (standardized format to be used in the app)
 */
export const voyageLeaderboardRecordAdapter = ({
  address,
  points,
}: VoyageLeaderboardRecord): AppVoyageLeaderboardRecord => {
  return {
    address,
    points: Number(points),
  }
}

export const voyageUserAdapter = ({
  address,
  points,
  swethReferred,
  numReferrals,
}: VoyageUserResponse): AppVoyageUser => {
  return {
    address,
    points: Number(points),
    numReferrals,
    swethReferred,
  }
}

export const voyageStatusAdapter = ({
  totalPearls,
  totalUsers,
}: VoyageStatusResponse): AppVoyageStatus => {
  return {
    totalPearls,
    totalUsers,
  }
}

/**
 * Converts StakesResponse into a form known by the frontend
 *
 * @param Stake (from staker_pb)
 * @returns AppStake
 */
export const stakesAdapter = (response: Stake): AppStake => {
  const { address, mintedSweth, stakedWei, timestamp, txHash } = response

  return {
    address,
    mintedSweth: BigNumber.from(mintedSweth),
    stakedWei: BigNumber.from(stakedWei),
    timestamp: Number(timestamp) * 1000,
    txHash,
  }
}

export const repriceAdapter = (response: Reprice): AppReprice => {
  const { rate, time, wethUsdc, blockNumber } = response

  return {
    blockNumber: blockNumber ? Number(blockNumber) : -1,
    rate: BigNumber.from(rate),
    timestamp: Number(time) * 1000,
    wethUsdc: wethUsdc ? BigNumber.from(wethUsdc) : BigNumber.from('0'),
  }
}

const TWITTER_USER_ERROR_MESSAGES = {
  NO_CODE: 'Submitted tweet did not contain your personal code.',
}

export const twitterUserAdapter = (
  response: TwitterUserResponse
): AppTwitterUser => {
  const { message, user, queuePosition } = response

  const userVerificationError = user?.verificationError
  let verificationError = ''

  if (userVerificationError) {
    if (/does not contain code/.test(userVerificationError)) {
      verificationError = TWITTER_USER_ERROR_MESSAGES.NO_CODE
    }
  }

  return {
    message,
    queuePosition: queuePosition || 0,
    twitterCode: user?.twitterCode || '',
    walletAddress: user?.walletAddress || '',
    verificationStatus: user?.verificationStatus || 0,
    verificationError,
  }
}
