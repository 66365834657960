import React from 'react'
import styled from 'styled-components'
import { Chip } from '@swell-ui/Chip'
import { FlexRow } from '@swell-ui/FlexRow'
// import { InfoInvertedIcon } from '@swell-ui/icons/InfoInvertedIcon'
// import { LightningIcon } from '@swell-ui/icons/LightningIcon'
// import { Tooltip } from '@/swell-ui/Tooltip'
import { Typography } from '@swell-ui/Typography'
import { TokenLogo } from '@/components/TokenLogo'
import { useBoostedTokens } from '@/hooks/tokens/useBoostedTokens'
import { Token } from '@/types/tokens'

// const StyledInfoIcon = styled(InfoInvertedIcon)`
//   position: relative;
//   top: 3px;

//   &:hover {
//     cursor: pointer;
//   }
// `

const ChipFlexRow = styled(FlexRow)`
  margin-top: 24px;
  flex-wrap: wrap;
`

function ChipLabel({ token }: { token: Token }) {
  return (
    <FlexRow align="center" gap="4">
      <TokenLogo token={token} />
      <Typography variant="body" size="small">
        {token.symbol}
      </Typography>
      {/*<LightningIcon />*/}
    </FlexRow>
  )
}

function BoostedTokenSection({
  onTokenSelect,
}: {
  onTokenSelect: (token: Token) => void
}) {
  const boostedTokens = useBoostedTokens()

  return (
    <>
      <FlexRow align="center">
        <Typography variant="body" size="medium" fstyle="bold">
          Most popular&nbsp;
        </Typography>
        {/*<Tooltip title={BOOSTED_TOOLTIP_TITLE} arrow placement="top" offset={[0, -10]}>
          <StyledInfoIcon />
        </Tooltip>*/}
      </FlexRow>
      <ChipFlexRow gap="6">
        {boostedTokens.map((token: Token) => {
          return (
            // <Tooltip key={token.symbol} title={CHIP_TOOLTIP_TITLE} arrow placement="top" offset={[0, -5]}>
            <Chip
              key={token.symbol}
              label={<ChipLabel token={token} />}
              onClick={() => onTokenSelect(token)}
            />
            // </Tooltip>
          )
        })}
      </ChipFlexRow>
    </>
  )
}

export { BoostedTokenSection }
