import React from 'react'
import useTransactionFee from '@/hooks/useTransactionFee'
import { useStakingApr, useStakingRate } from '@/state/stakingStats/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import {
  useDisplaySwEth,
  useDisplaySwEthInNativeCurrency,
} from '@/hooks/useSwEthDisplay'
import { useParseSwEth } from '@/hooks/useSwEthFormatting'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import { getStakingUpperGasEstimate } from '@/constants/gasEstimates'

export function ExchangeInfo() {
  const stakingApr = useStakingApr()
  const stakingRate = useStakingRate()
  const stakingTransactionFee = useTransactionFee({
    gasAmount: getStakingUpperGasEstimate().toNumber(),
  })

  const parseSwEth = useParseSwEth()
  const displaySwEth = useDisplaySwEth()
  const displaySwEthInNativeCurrency = useDisplaySwEthInNativeCurrency()
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  const exchangeInfoListConfig = {
    list: [
      {
        label: 'swETH APR',
        value: (
          <AsyncDiv loading={!stakingApr.data}>
            {() => `${stakingApr.data!.value}%`}
          </AsyncDiv>
        ),
        infoText: (
          <span>
            <a
              href="https://dune.com/swell-network/swell-network"
              target="_blank"
              rel="noreferrer"
            >
              Total pool 7 day moving average
            </a>
          </span>
        ),
        hasInfo: true,
      },
      {
        label: 'Exchange rate',
        value: (
          <AsyncDiv loading={!stakingRate.data}>
            {() => {
              const { swEthToNativeRate } = stakingRate.data!

              const oneSwEth = parseSwEth('1')

              const oneSwEthDisplay = displaySwEth(oneSwEth, {
                precision: 4,
                localize: true,
              })
              const equivalentEthDisplay = displaySwEthInNativeCurrency(
                oneSwEth,
                swEthToNativeRate,
                { precision: 4, localize: true }
              )

              return `${oneSwEthDisplay} = ${equivalentEthDisplay}`
            }}
          </AsyncDiv>
        ),
        hasInfo: true,
        infoText: 'Represents the accruing value of swETH vs ETH',
      },
      {
        label: 'Transaction fee',
        value: (
          <AsyncDiv loading={!stakingTransactionFee.data}>
            {() => {
              const { ethUsdRate, feeWei } = stakingTransactionFee.data!
              return `${displayNativeCurrencyPriceFiat(feeWei, ethUsdRate)} USD`
            }}
          </AsyncDiv>
        ),
      },
    ],
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
