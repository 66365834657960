import React from 'react'
import styled from 'styled-components'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { ACTIONS } from './constants'

interface ActionChooserProps {
  action: string
  onActionClick: (action: string) => void
}

const ActionChooserWrapper = styled.div`
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(66, 69, 87, 1);
  margin-bottom: 16px !important;
`

const ActionChoice = styled.div<any>`
  ${({ active, theme }) => active && `color: ${theme.colors.white['50']};`}

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white['50']};
  }
`

function ActionChooser({ action, onActionClick }: ActionChooserProps) {
  return (
    <ActionChooserWrapper>
      <Typography variant="body" size="small" letterSpacing="small">
        <FlexRow align="center" gap="16">
          <ActionChoice
            active={action === ACTIONS.STAKE}
            onClick={() => onActionClick(ACTIONS.STAKE)}
          >
            Stake
          </ActionChoice>
          <ActionChoice
            active={action === ACTIONS.ZAP}
            onClick={() => onActionClick(ACTIONS.ZAP)}
          >
            Zap
          </ActionChoice>
          <ActionChoice
            active={action === ACTIONS.VAULT}
            onClick={() => onActionClick(ACTIONS.VAULT)}
          >
            Vault
          </ActionChoice>
        </FlexRow>
      </Typography>
    </ActionChooserWrapper>
  )
}

export { ActionChooser }
