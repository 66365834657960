import React from 'react'
import { Grid } from '@swell-ui/Grid'
import { ExchangeRateChartWidget } from '@/components/ExchangeRateChartWidget'
import { SwEthHoldings } from '@/components/SwEthHoldings'
import { StakerTransactionHistory } from '@/components/StakerTransactionHistory'

function PortfolioPage() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SwEthHoldings />
      </Grid>
      <Grid item xs={12} md={6}>
        <ExchangeRateChartWidget />
      </Grid>
      <Grid item xs={12} lg={12}>
        <StakerTransactionHistory />
      </Grid>
    </Grid>
  )
}

export { PortfolioPage }
