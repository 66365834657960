import React, { ReactNode } from 'react'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import startCase from 'lodash/startCase'
import { Table, TableConfig } from '@swell-ui/Table'
import { AppTransactionNative } from '@/types/transaction'
import { TimeDelta } from '@/swell-ui/TimeDelta'
import styled from 'styled-components/macro'
import ExplorerLink from '../ExplorerLink'

const StyledTable = styled(Table)`
  max-height: 228px;

  .hash-cell {
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0;
  }

  .method-cell {
    min-width: 116px;
  }
  .age-cell {
    min-width: 200px;
  }

  .value-cell {
    min-width: 125px;
  }
`

type RowType = {
  hash: ReactNode
  method: ReactNode
  age: ReactNode
  keysSubmitted: ReactNode
}

const COLUMNS: TableConfig['columns'] = [
  {
    key: 'hash',
    label: 'Txn hash',
  },
  {
    key: 'method',
    label: 'Method',
  },
  {
    key: 'age',
    label: 'Age',
  },
  {
    key: 'keysSubmitted',
    label: 'Keys Submitted',
  },
]

const LOADING_LENGTH = 4
const MAX_ROW_COUNT = 4

export interface IRecentTransactionsTableProps {
  recentTransactions?: AppTransactionNative[]
}

function RecentTransactionsTable({
  recentTransactions,
}: IRecentTransactionsTableProps) {
  const mapTxToRow = React.useCallback((tx: AppTransactionNative): RowType => {
    return {
      age: tx.createdAt ? <TimeDelta date={tx.createdAt} /> : '--',
      hash: <ExplorerLink kind="tx" hexString={tx.txHash} />,
      method: startCase(tx.method),
      keysSubmitted: parseInt(tx.keysSubmitted, 10).toLocaleString(),
    }
  }, [])

  const tableConfig = React.useMemo<TableConfig>(() => {
    if (recentTransactions === undefined) {
      return {
        columns: COLUMNS,
        rows: [],
        loadingLength: LOADING_LENGTH,
        stickyHeader: true,
      }
    }

    const orderedTransactions = orderBy(
      take(recentTransactions, MAX_ROW_COUNT),
      ({ createdAt }) => createdAt,
      'desc'
    )

    return {
      columns: COLUMNS,
      rows: orderedTransactions.map(mapTxToRow),
      loadingLength: LOADING_LENGTH,
      stickyHeader: true,
    }
  }, [mapTxToRow, recentTransactions])

  return (
    <>
      <StyledTable
        loading={recentTransactions === undefined}
        config={tableConfig}
      />
    </>
  )
}

export { RecentTransactionsTable }
