import React from 'react'
import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { useSwellWeb3 } from '@swell-web3/core'
import { Box } from '@swell-ui/Box'
import { Grid } from '@swell-ui/Grid'
import { Typography } from '@swell-ui/Typography'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { SwethIcon } from '@swell-ui/icons/SwethIcon'
import { UsdIcon } from '@swell-ui/icons/UsdIcon'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { useChainInfo } from '@/state/deployments/hooks'
import { useStakingRate } from '@/state/stakingStats/hooks'
import { useSwEthBalance } from '@/state/user/hooks'
import { useSwEthUsdMarketRate } from '@/state/fiat/hooks'
import {
  useDisplaySwEth,
  useDisplaySwEthInNativeCurrency,
  useDisplaySwEthPriceFiat,
} from '@/hooks/useSwEthDisplay'

const HoldingsBox = styled(Box)`
  height: 100%;
  padding: 24px 32px;
`

const HoldingsInner = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > div:not(:last-child) {
    padding-bottom: 24px;
  }
`

const InnerBox = styled(Box)`
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.05);

  > div:not(:last-child) {
    padding-bottom: 12px;
  }
`

const ConnectZone = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;

  > div:first-child {
    padding-bottom: 24px;
  }

  color: ${({ theme }) => theme.colors.white['150']};
`

const SwEthTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
`

const StyledSwethIcon = styled(SwethIcon)`
  height: 28px;
  width: 28px;
  position: relative;
  top: 6px;
  margin-left: 12px;
  margin-right: 4px;
`

const StyledEthIcon = styled(EthIcon)`
  height: 19.25px;
  width: 19.25px;
  position: relative;
  top: 4px;
  margin-left: 9.38px;
  margin-right: 5.38px;
`

const StyledUsdIcon = styled(UsdIcon)`
  height: 19.25px;
  width: 19.25px;
  position: relative;
  top: 4px;
  margin-left: 9.38px;
  margin-right: 5.38px;
`

function SwEthConversionBox({
  title,
  swEthAmount,
}: {
  title: string
  swEthAmount: BigNumber | undefined
}) {
  const { nativeCurrency } = useChainInfo()
  const stakingRate = useStakingRate()
  const swEthUsdMarketRate = useSwEthUsdMarketRate()

  const displaySwEth = useDisplaySwEth()
  const displaySwEthInNativeCurrency = useDisplaySwEthInNativeCurrency()
  const displaySwEthPriceFiat = useDisplaySwEthPriceFiat()

  return (
    <InnerBox>
      <Typography variant="body" size="small" letterSpacing="small">
        {title}
      </Typography>
      <SwEthTypography variant="body" size="large" letterSpacing="small">
        {swEthAmount &&
          displaySwEth(swEthAmount as BigNumber, {
            precision: 4,
            localize: true,
            numberOnly: true,
          })}
        <StyledSwethIcon />
        swETH
      </SwEthTypography>
      <Typography variant="body" size="medium" letterSpacing="small">
        =&nbsp;
        {swEthAmount &&
          stakingRate &&
          stakingRate.data &&
          displaySwEthInNativeCurrency(
            swEthAmount as BigNumber,
            stakingRate.data.swEthToNativeRate,
            { precision: 4, localize: true, numberOnly: true }
          )}
        <StyledEthIcon />
        {nativeCurrency.symbol}
      </Typography>
      <Typography variant="body" size="medium" letterSpacing="small">
        =&nbsp;
        {swEthAmount &&
          swEthUsdMarketRate &&
          swEthUsdMarketRate.data &&
          displaySwEthPriceFiat(swEthAmount, swEthUsdMarketRate.data.rate)}
        <StyledUsdIcon />
        USD
      </Typography>
    </InnerBox>
  )
}

function SwEthHoldings() {
  const { account } = useSwellWeb3()
  const swEthBalance = useSwEthBalance()

  return (
    <HoldingsBox>
      <HoldingsInner>
        <Typography variant="body" size="large" letterSpacing="small">
          swETH holdings
        </Typography>
        {account && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <SwEthConversionBox
                title="swETH balance"
                swEthAmount={swEthBalance.data?.balance}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SwEthConversionBox
                title="Exchange Rate"
                swEthAmount={BigNumber.from('1000000000000000000')}
              />
            </Grid>
          </Grid>
        )}
        {!account && (
          <ConnectZone>
            <div>Connect wallet to see available data.</div>
            <div>
              <ConnectWalletButton variant="secondary" size="small">
                Connect wallet
              </ConnectWalletButton>
            </div>
          </ConnectZone>
        )}
      </HoldingsInner>
    </HoldingsBox>
  )
}

export { SwEthHoldings }
