import { Table, TableConfig } from '@/swell-ui/Table'
import { shortenHash } from '@/util/hexStrings'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useMemo, useState } from 'react'
import SubmissionStatusIndicator from './SubmissionStatusIndicator'
import { DepositSubmissionDetailModal } from '../DepositSubmissionDetailModal'
import { useDepositSubmissionDetailsContext } from '@/state/depositSubmission/hooks'
import { DepositSubmissionStatus } from '@/state/depositSubmission/models/DepositSubmissionDetails'
import useDepositSubmissionFailNotify from '@/hooks/useDepositSubmissionFailNotify'
import styled from 'styled-components/macro'
import { Box } from '@/swell-ui/Box'

const ContainerBox = styled(Box)`
  padding: 24px 32px;
`

const StyledTable = styled(Table)`
  .number-cell {
    padding-left: 0;
  }
`

const COLUMNS: TableConfig['columns'] = [
  {
    key: 'number',
    label: 'Number',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'confirmedBlock',
    label: 'Confirmed Block',
  },
  {
    key: 'hash',
    label: 'Txn hash',
  },
  {
    key: 'gas',
    label: 'Gas',
  },
]

interface IDepositCollectionSubmissionTableProps {
  isHistoric: boolean
}

/**
 * Table component responsible for showing the state of the batch submission of validator deposits.
 *
 * Rows will be clickable to show information about the chunk when:
 *  a) The table is viewing data for a historic run; or
 *  b) The current submission has finished
 *
 * When viewing a historic run which was cancelled early (e.g. close browser tab), the state of pending
 *  transactions will be "unknown".
 */
function DepositCollectionSubmissionTable({
  isHistoric,
}: IDepositCollectionSubmissionTableProps) {
  const { details } = useDepositSubmissionDetailsContext()
  useDepositSubmissionFailNotify()

  const [viewingRowIdx, setViewingRowIdx] = useState<number | undefined>(
    undefined
  )

  const clearViewingRow = () => setViewingRowIdx(undefined)

  const derived = {
    get canClickRows(): boolean {
      if (isHistoric) return true

      return details.batches.every(
        (batch) =>
          batch.status === DepositSubmissionStatus.EXTERNALLY_MANAGED ||
          batch.status === DepositSubmissionStatus.ERROR ||
          batch.status === DepositSubmissionStatus.SUBMITTED
      )
    },
    get modal() {
      if (typeof viewingRowIdx !== 'number') return null

      const submissionItem = details.batches[viewingRowIdx]

      return (
        <DepositSubmissionDetailModal
          open={true}
          onClose={clearViewingRow}
          submissionItem={submissionItem}
        />
      )
    },
  }

  const { modal, canClickRows } = derived

  const config = useMemo<TableConfig>(() => {
    return {
      onRowClick: (_row, idx) => {
        if (!canClickRows) return
        setViewingRowIdx(idx)
      },
      columns: COLUMNS,
      rows: details.batches.map((item) => {
        const { chunkIdx, receipt, txHash, status } = item

        return {
          number: chunkIdx + 1,
          confirmedBlock: receipt?.blockNumber ?? '',
          get gas() {
            if (!receipt?.gasUsedWei) return ''

            return formatUnits(BigNumber.from(receipt.gasUsedWei), 'gwei')
          },
          hash: txHash ? shortenHash(txHash) : '',
          get status() {
            if (isHistoric && status === 'prompting')
              return <SubmissionStatusIndicator status={'unknown'} />

            return <SubmissionStatusIndicator status={status} />
          },
        }
      }),
    }
  }, [canClickRows, details.batches, isHistoric])

  return (
    <ContainerBox>
      <StyledTable loading={false} config={config} />
      {modal}
    </ContainerBox>
  )
}

export { DepositCollectionSubmissionTable }
