import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ArrowsDownUpIcon } from '@swell-ui/icons/ArrowsDownUpIcon'
import { CaretDownIcon } from '@swell-ui/icons/CaretDownIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { InputAdornment } from '@swell-ui/inputs/InputAdornment'
import { SearchIcon } from '@swell-ui/icons/SearchIcon'
import { SelectInput, SelectOption } from '@swell-ui/inputs/SelectInput'
import { TextInput } from '@swell-ui/inputs'
import { Typography } from '@swell-ui/Typography'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { useSwellUiTheme } from '@swell-ui/theme'
import { displayFiat } from '@/util/displayFiat'
import { TokenPoolExternal } from '@/types/tokens'
import { ALL_CHAINS_OPTION, SORT_OPTIONS } from './constants'

interface PoolTableUtilitiesProps {
  loading: boolean
  pools: TokenPoolExternal[]
  onChainChange: (key: any) => any
  onFilterChange: (value: string) => any
  onSortChange: (key: any) => any
}

const UtilitiesLabel = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  leading-trim: both;
  text-edge: cap;
  font-size: 10px;
  line-height: 160%; /* 16px */
  letter-spacing: 0.75px;
  width: 176px;
`

const ChainSelectInput = styled(SelectInput)`
  max-width: 176px;
  height: 32px;
  border-radius: 200px;
  color: ${({ theme }) => theme.colors.white['100']};
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.white['125']};
    border-radius: 12px;
  }

  .MuiSelect-icon {
    path {
      color: ${({ theme }) => theme.colors.white['125']};
    }
  }
`

const ChooserGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ChooserItem = styled.div<{ active: boolean }>`
  display: flex;
  width: 46px;
  height: 46px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(176, 176, 176, 0.2);
  background: rgba(241, 241, 241, 0.05);

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.colors.white['150']};
    }
  }

  ${({ active, theme }) =>
    active &&
    `
    border: 1px solid ${theme.colors.white['100']};

    &:hover {
      opacity: 1;
    }

    svg {
      path {
        fill: ${theme.colors.white['100']};
      }
    }
  `}
`

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-root {
    padding-left: 10px;
  }
  .MuiInputAdornment-root {
    margin-left: 0;
  }

  input {
    height: 20px;
    padding: 13px 10px 13px 0;
    font-size: 16px;
    line-height: 160%; /* 19.2px */
    letter-spacing: -0.36px;
  }

  svg {
    height: 18px;
    width: 16px;
  }

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      width: 400px;

      input {
        height: 30px;
      }
    }
  `}
`

const SortSelectInput = styled(SelectInput)`
  .MuiSelect-select {
    height: 20px;
    padding: 13px 10px 13px 18px;
    font-size: 12px;
    line-height: 160%; /* 19.2px */
    letter-spacing: -0.36px;

    svg {
      width: 14px;
      height: 14px;
      position: relative;
      top: -0.5px;
    }
  }

  .MuiSelect-icon {
    width: 14px;
    height: auto;
    top: unset;
    right: 18px;
  }
`

const StyledCaretDownIcon = styled(CaretDownIcon)``

const ChainLogo = styled.img`
  width: 16px;
  height: 16px;
`

const EcosystemTVLTypography = styled(Typography)`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      position: initial;
    }
  `}
  position: relative;
  top: 11px;
`

const ACTIONS = {
  SEARCH: 'search',
  SORT: 'sort',
}

const SEARCH_PLACEHOLDER = 'Search...'

function ChainLabel({ pool }: { pool: TokenPoolExternal }) {
  return (
    <FlexRow gap="8">
      <ChainLogo src={pool?.chainLogo} />
      <span>{pool?.chainName}</span>
    </FlexRow>
  )
}

export function PoolTableUtilities({
  loading,
  pools,
  onChainChange,
  onFilterChange,
  onSortChange,
}: PoolTableUtilitiesProps) {
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [action, setAction] = useState<string>(ACTIONS.SEARCH)
  const [filterValue, setFilterValue] = useState<string>('')
  const [chainId, setChainId] = useState<number>(ALL_CHAINS_OPTION.key)
  const [sortKey, setSortKey] = useState<string>(SORT_OPTIONS[0].key)

  useEffect(() => {
    if (isMdUp) {
      setAction(ACTIONS.SEARCH)
    }
  }, [isMdUp])

  const chainOptions = useMemo<SelectOption[]>(() => {
    const options = [ALL_CHAINS_OPTION]
    const seenMap: any = {}

    pools.forEach((pool: TokenPoolExternal) => {
      if (!seenMap[pool.chainId]) {
        options.push({
          key: pool.chainId,
          label: <ChainLabel pool={pool} />,
        })
        seenMap[pool.chainId] = true
      }
    })

    return options
  }, [pools])

  const totalTVL = useMemo<number>(() => {
    return pools.reduce((tvlAcc: number, pool: TokenPoolExternal) => {
      return tvlAcc + pool.tvl
    }, 0)
  }, [pools])

  const handleFilterInputChange = (event: any) => {
    const newFilterValue = event.target.value
    setFilterValue(newFilterValue)
    onFilterChange(newFilterValue)
  }

  const handleChainSelectChange = (event: any) => {
    const newChainId = event.target.value
    setChainId(newChainId)
    onChainChange(newChainId)
  }

  const handleSortSelectChange = (event: any) => {
    const newSortBy = event.target.value
    setSortKey(newSortBy)
    onSortChange(newSortBy)
  }

  // Responsive helpers

  let wrapperFlexDirection = 'column'
  let chainTvlFlexDirection = 'row'
  let chainAlign = 'flex-start'
  let searchWidth = '100%'

  if (isMdUp) {
    wrapperFlexDirection = 'row-reverse'
    chainTvlFlexDirection = 'row-reverse'
    chainAlign = 'flex-end'
    searchWidth = 'auto'
  }

  return (
    <FlexRow direction={wrapperFlexDirection} gap="18">
      <FlexRow direction={chainTvlFlexDirection} justify="space-between">
        <FlexRow direction="column" align={chainAlign} gap="4">
          <UtilitiesLabel>CHAIN</UtilitiesLabel>
          <ChainSelectInput
            options={chainOptions}
            onChange={handleChainSelectChange}
            value={chainId}
            disabled={loading}
          />
        </FlexRow>
        <EcosystemTVLTypography
          variant="body"
          size="small"
          letterSpacing="small"
        >
          Ecosystem&nbsp;TVL:&nbsp;{displayFiat(totalTVL, true)}
        </EcosystemTVLTypography>
      </FlexRow>
      <FlexRow gap="4" width={searchWidth}>
        {!isMdUp && (
          <ChooserGroup>
            <ChooserItem
              active={action === ACTIONS.SEARCH}
              onClick={() => setAction(ACTIONS.SEARCH)}
            >
              <SearchIcon />
            </ChooserItem>
            <ChooserItem
              active={action === ACTIONS.SORT}
              onClick={() => setAction(ACTIONS.SORT)}
            >
              <ArrowsDownUpIcon />
            </ChooserItem>
          </ChooserGroup>
        )}
        {action === ACTIONS.SEARCH && (
          <StyledTextInput
            variant="outlined"
            fullWidth
            placeholder={SEARCH_PLACEHOLDER}
            value={filterValue}
            onChange={handleFilterInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        {action === ACTIONS.SORT && (
          <SortSelectInput
            variant="outlined"
            IconComponent={StyledCaretDownIcon}
            options={SORT_OPTIONS}
            value={sortKey}
            onChange={handleSortSelectChange}
          />
        )}
      </FlexRow>
    </FlexRow>
  )
}
