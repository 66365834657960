import axios from 'axios'

type RedstoneRes = [
  {
    value: number
  }
]

class _RedstoneService {
  swETHPrice = () =>
    axios
      .get<RedstoneRes>(
        'https://api.redstone.finance/prices?symbol=SWETH&provider=redstone&limit=1'
      )
      .then((res) => {
        return { rate: res.data[0].value }
      })
}

export const RedstoneService = new _RedstoneService()
