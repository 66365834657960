import React from 'react'
import styled from 'styled-components/macro'
import MuiPagination from '@mui/material/Pagination'

interface PaginationProps {
  boundaryCount?: number
  count: number
  page?: number
  siblingCount?: number
  onChange?: (event: any, page: number) => void
}

const StyledMuiPagination = styled(MuiPagination)`
  .MuiPaginationItem-root {
    min-width: 20px;
    color: ${({ theme }) => theme.colors.white['50']};
  }

  .MuiButtonBase-root {
    width: 20px;
    height: 20px;
    font-size: 12px;
    margin: 0 2px;

    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors.lightBlue['100']};
    }
  }

  .MuiPaginationItem-ellipsis {
    padding: 0;
  }

  .MuiSvgIcon-root {
    height: 16.5px;
  }
`

function Pagination(props: PaginationProps) {
  return <StyledMuiPagination {...props} />
}

export { Pagination }
