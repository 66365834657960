import React from 'react'
import styled from 'styled-components/macro'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  color?: 'accent'
  disabled?: boolean
  onClick?: (arg1: any) => any
  size?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'secondary' | 'tertiary'
  fullWidth?: boolean
}

const StyledButton = styled.button<ButtonProps>`
  ${({ theme, color, size, variant, fullWidth }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 12px;
    border: none;
    border-radius: 8px;
    font-family: inherit;
    font-size: ${theme.button.medium.fontSize};
    line-height: ${theme.button.medium.lineHeight};
    font-weight: 600;
    height: 54px;

    background-color: ${theme.button.primary.bgColor};
    color: ${theme.button.primary.color};

    &:hover:not([disabled]) {
      cursor: pointer;
      opacity: ${theme.button.primary.hoverOpacity}
    }

    &:active:not([disabled])  {
      opacity: ${theme.button.primary.activeOpacity}
    }

    &:disabled {
      background-color: ${theme.button.primary.disabledBgColor};
      color: ${theme.button.primary.disabledColor};
      cursor: initial;
    }

    ${
      color === `accent`
        ? `
      background-color: ${theme.button.accent.bgColor};
    `
        : ``
    }

    ${
      size === 'small'
        ? `
      height: 42px;
    `
        : ``
    }

    ${
      size === 'large'
        ? `
      height: 70px;
      font-size: ${theme.button.large.fontSize};
      line-height: ${theme.button.large.lineHeight};
    `
        : ``
    }

    ${
      variant === 'secondary'
        ? `
      background-color: ${theme.button.secondary.bgColor};
      color: ${theme.button.secondary.color};
      border: 1px solid ${theme.button.secondary.color};

      &:hover:not([disabled]) {
        cursor: pointer;
        opacity: ${theme.button.secondary.hoverOpacity}
      }

      &:active:not([disabled]) {
        opacity: ${theme.button.secondary.activeOpacity}
      }

      &:disabled {
        background-color: ${theme.button.secondary.bgColor};
        opacity: ${theme.button.secondary.disabledOpacity};
        cursor: initial;
        color: ${theme.colors.white['50']};
      }
    `
        : ``
    }

    ${
      variant === 'tertiary'
        ? `
      background-color: ${theme.button.tertiary.bgColor};
      color: ${theme.button.tertiary.color};
      border: none;

      &:hover:not([disabled]) {
        cursor: pointer;
        opacity: ${theme.button.tertiary.hoverOpacity}
      }

      &:active:not([disabled])  {
        opacity: ${theme.button.tertiary.activeOpacity}
      }

      &:disabled {
        background-color: ${theme.button.tertiary.bgColor};
        opacity: ${theme.button.tertiary.disabledOpacity};
      }

    a {
      color: ${theme.colors.white['50']};

      &:hover:not([disabled]) {
        color: ${theme.colors.white['50']};        
      }
      &:active:not([disabled])  {
        opacity: ${theme.button.tertiary.activeOpacity}
      }

      &:disabled {
        background-color: ${theme.button.tertiary.bgColor};
        opacity: ${theme.button.tertiary.disabledOpacity};
        cursor: initial;
        color: ${theme.colors.white['50']};
      }
    `
        : ``
    }

    ${fullWidth ? `width: 100%;` : ``}
  `}
`

function Button({ children, ...props }: ButtonProps) {
  return <StyledButton {...props}>{children}</StyledButton>
}

export { Button }
export type { ButtonProps }
