import React from 'react'
import styled from 'styled-components/macro'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

interface AccordionConfig {
  summary: string | React.ReactNode
  details: string | React.ReactNode
}

interface AccordionProps {
  className?: string
  config: AccordionConfig
  expanded?: boolean
  onChange?: () => any
}

const StyledMuiAccordion = styled(MuiAccordion)`
  background-color: inherit;
  color: inherit;
  border-radius: inherit !important;
  box-shadow: none;

  &.Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding: 0;

    &.Mui-expanded {
      min-height: unset;
    }
  }

  .MuiAccordionDetails-root {
    margin-bottom: 12px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    color: inherit;
  }
`

function Accordion({ config, ...props }: AccordionProps) {
  if (!config) {
    console.error("Accordion must have 'config' prop.")
    return null
  }

  return (
    <StyledMuiAccordion {...props}>
      <MuiAccordionSummary>{config.summary}</MuiAccordionSummary>
      <MuiAccordionDetails>{config.details}</MuiAccordionDetails>
    </StyledMuiAccordion>
  )
}

export { Accordion }
