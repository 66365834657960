import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { Box } from '@swell-ui/Box'
import { Grid } from '@swell-ui/Grid'
import { InfoInvertedIcon } from '@swell-ui/icons/InfoInvertedIcon'
import { Tooltip } from '@swell-ui/Tooltip'
import { Typography } from '@swell-ui/Typography'
import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import submarineUrl from '@/assets/svg/voyage/submarine.svg'
import waveUrl from '@/assets/svg/voyage/wave.svg'

interface PearlDivingTargetsProps {
  totalEthStaked?: BigNumber
}

const VoyageBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 32.4px 24px;
  gap: 24px;
  background-color: rgba(5, 26, 43, 0.5);
  backdrop-filter: blur(10px);
  justify-content: center;
  border-radius: 24px;
  margin-top: 24px;
  border: none;
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  text-transform: uppercase;
`

const TVLWrapper = styled.div`
  display: flex;
  position: relative;
  @media (max-width: 899px) {
    justify-content: center;
  }
`

const TVLPositioner = styled.div`
  position: relative;
`

const TVLContainer = styled.div`
  position: relative;
  width: 138px;
  height: 342px;
  background: #051a2b;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
`

const TVLInner = styled.div<any>`
  @keyframes rise {
    0% {
      height: 0;
    }
    100% {
      height: ${({ tvl }) => `calc(${(tvl * 100) / 50000}% - 27px)`};
    }
  }
  width: 100%;
  background: #1f3fc8;
  opacity: 0.7;
  animation: 2s linear rise forwards;
`

const subLoop = keyframes`
  0% {
    left: -45px;
  }
  100% {
    left: 213px;
  }
`

const TVLSubmarine = styled.img<any>`
  @keyframes subRise {
    0% {
      height: 0;
    }
    100% {
      bottom: ${({ tvl }) => `calc(${(tvl * 100) / 50000}% - 7px)`};
    }
  }

  position: absolute;
  bottom: ${({ tvl }) => `calc(${(tvl * 100) / 50000}% - 7px)`};
  animation: 10s linear ${subLoop} infinite, 2s linear subRise forwards;
`

const waveLoop = keyframes`
  0% {
      left: 0;
    }
    100% {
      left: -467px;
    }
`

const TVLWave = styled.img<any>`
  position: relative;
  width: 934px;
  left: 0px;
  animation: 10s linear ${waveLoop} infinite;
  opacity: 0.7;
`

const TVLMarkerLine = styled.div`
  position: absolute;
  height: 0px;
  width: 152px;
  border: 1px dashed #b0b0b0;
`

const TVLTopMarkerLine = styled(TVLMarkerLine)`
  top: 0;
`

const TVLBottomMarkerLine = styled(TVLMarkerLine)`
  bottom: 0;
`

const TVLCurrentMarkerLine = styled<any>(TVLMarkerLine)`
  bottom: ${({ tvl }) => `calc(${(tvl * 100) / 50000}%)`};
  border-color: ${({ theme }) => theme.colors.white['50']};
  opacity: 0.5;
`

const TVLMarkerNumber = styled.div`
  position: absolute;
  left: 157px;
  white-space: nowrap;
`

const TVLMarkerNumberTop = styled(TVLMarkerNumber)`
  top: -10px;
`

const TVLMarkerBottom = styled(TVLMarkerNumber)`
  bottom: -10px;
`

const TVLMarkerNumberCurrent = styled<any>(TVLMarkerNumber)`
  bottom: ${({ tvl }) => `calc(${(tvl * 100) / 50000}% - 10px)`};
  color: ${({ theme }) => theme.colors.white['50']};
`

interface TVLChartProps {
  tvl?: BigNumber
}

function TVLChart({ tvl }: TVLChartProps) {
  const displayNativeCurrency = useDisplayNativeCurrency()

  if (!tvl) {
    return (
      <TVLWrapper>
        <TVLContainer />
      </TVLWrapper>
    )
  }

  const tvlFloat = Number(
    displayNativeCurrency(tvl, {
      precision: 0,
      numberOnly: true,
      localize: false,
    })
  )

  return (
    <TVLWrapper>
      <TVLPositioner>
        <TVLContainer>
          <TVLSubmarine src={submarineUrl} tvl={tvlFloat} />
          <TVLWave src={waveUrl} />
          <TVLInner tvl={tvlFloat} />
        </TVLContainer>
        <TVLTopMarkerLine />
        <TVLMarkerNumberTop>50K ETH</TVLMarkerNumberTop>
        <TVLBottomMarkerLine />
        <TVLMarkerBottom>0K ETH</TVLMarkerBottom>
        <TVLCurrentMarkerLine tvl={tvlFloat} />
        <TVLMarkerNumberCurrent tvl={tvlFloat}>
          {Math.floor(tvlFloat / 1000) + 'K ETH'}
        </TVLMarkerNumberCurrent>
      </TVLPositioner>
    </TVLWrapper>
  )
}

const tooltipTitle =
  '40% bonus $SWELL will be earned by all voyagers when this milestone is achieved.'

function PearlDivingTargets({ totalEthStaked }: PearlDivingTargetsProps) {
  return (
    <div>
      <Typography variant="headline" size="h5">
        Community Bonus&nbsp;
        <Tooltip title={tooltipTitle} placement="top" offset={[0, -15]}>
          <InfoInvertedIcon />
        </Tooltip>
      </Typography>
      <VoyageBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TVLChart tvl={totalEthStaked} />
          </Grid>
        </Grid>
      </VoyageBox>
    </div>
  )
}

export { PearlDivingTargets }
