import { useState } from 'react'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { useSwellWeb3 } from '@swell-web3/core'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'

export function useApprove(erc20TokenContract: any) {
  const { account } = useSwellWeb3()
  const { addresses } = useDeploymentSetConfig()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0))
  const [token, setToken] = useState<any>(null)

  const STATUS = {
    IDLE: 'idle',
    PROMPTING: 'prompting',
    PENDING: 'pending',
  }

  const [status, setStatus] = useState<string>(STATUS.IDLE)

  const sendTransaction = async (amount: string, token: any) => {
    if (!erc20TokenContract || !account || !addresses) {
      return false
    }

    setStatus(STATUS.PROMPTING)
    setIsLoading(true)

    try {
      const spenderAddress = addresses.lifi
      const allowanceAmount = parseUnits(amount, token.decimals)
      const allowanceAmountBN = BigNumber.from(allowanceAmount)
      setAmount(allowanceAmountBN)
      setToken(token)

      const approveTx = await erc20TokenContract.approve(
        spenderAddress,
        allowanceAmount
      )
      setStatus(STATUS.PENDING)

      await approveTx.wait()

      setIsLoading(false)
      setStatus(STATUS.IDLE)
      return allowanceAmount
    } catch (err) {
      console.error(err)

      setIsLoading(false)
      setStatus(STATUS.IDLE)

      return null
    }
  }

  return {
    amount,
    isLoading,
    sendTransaction,
    status,
    STATUS,
    token,
  }
}
