import { DefaultTheme } from 'styled-components/macro'
import { createTheme } from '@mui/material/styles'
import { colors } from './colors'
import { typography } from './typography'

const lightTheme: DefaultTheme = createTheme({
  mainBgColor: colors.oceanBlue['100'],
  mainColor: 'black',
  secondaryBgColor: 'white',
  accentBgColor: 'rgb(200, 200, 200)',
  accentColor: 'rgb(100, 100, 100)',

  box: {
    borderColor: `rgb(216, 220, 244)`,
  },

  button: {
    fontSize: typography.body.medium.fontSize,
    lineHeight: typography.body.medium.lineHeight,
    primary: {
      bgColor: colors.lightBlue['100'],
      color: colors.white['50'],
      hoverBgColor: 'rgba(150,150,150, 0.7)',
    },
    contained: {
      primary: {
        bgColor: 'rgb(51, 71, 218)',
        color: 'white',
        hoverBgColor: 'rgb(33, 51, 182)',
        activeBgColor: 'rgb(25, 38, 137)',
      },
      secondary: {
        bgColor: 'white',
        color: 'black',
        hoverColor: 'rgb(150, 150, 150)',
      },
    },
    outlined: {
      primary: {
        bgColor: 'transparent',
        color: 'black',
        hoverColor: 'rgb(100, 100, 100)',
      },
    },
  },

  chip: {
    color: 'rgb(166, 167, 175)',
  },

  colors,

  dialog: {
    bgColor: 'white',
    borderColor: 'white',
  },
})

const darkTheme: DefaultTheme = createTheme({
  mainBgColor: colors.oceanBlue['100'],
  mainColor: 'white',
  secondaryBgColor: 'rgb(14, 20, 26)',
  accentBgColor: 'rgb(41, 50, 73)',
  accentColor: 'rgb(200, 200, 200)',

  alert: {
    success: {
      bgColor: colors.lightBlue['125'],
      linkHoverColor: colors.white['100'],
    },
    error: {
      bgColor: colors.red['100'],
    },
  },

  box: {
    bgColor: colors.oceanBlue['125'],
    borderColor: colors.auxGrey,
    color: colors.white['125'],
  },

  bottomNav: {
    bgColor: colors.oceanBlue['125'],
    color: colors.white['50'],
  },

  button: {
    accent: {
      bgColor: colors.oceanBlue['125'],
    },
    small: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    medium: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    large: {
      fontSize: typography.body.large.fontSize,
      lineHeight: typography.body.large.lineHeight,
    },
    primary: {
      bgColor: colors.lightBlue['100'],
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledBgColor: '#131B5E',
      disabledColor: colors.white['150'],
      disabledOpacity: 0.5,
    },
    secondary: {
      bgColor: 'transparent',
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
    tertiary: {
      bgColor: 'transparent',
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
  },

  chip: {
    color: colors.white['125'],
  },

  colors,

  dataBoxGrid: {
    dataBox: {
      color: colors.white['50'],
      bgColor: 'rgba(255, 255, 255, 0.05)',
      dataColor: colors.white['125'],
    },
  },

  dialog: {
    bgColor: colors.oceanBlue['125'],
    borderColor: colors.lightBlue['50'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
  },

  drawer: {
    bgColor: colors.oceanBlue['125'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
  },

  input: {
    underlineColor: colors.white['50'],
    underlineFocusColor: colors.lightBlue['100'],
  },

  menu: {
    bgColor: colors.oceanBlue['125'],
    color: colors.white['50'],
    borderColor: '#424557',
  },

  table: {
    head: {
      color: colors.white['125'],
    },
  },

  typography,
})

export { lightTheme, darkTheme }
