import React, { useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createGlobalStyle } from 'styled-components/macro'
import { ThemeProvider } from '@mui/material/styles'
import { lightTheme, darkTheme } from './themes'
import { SwellUiThemeContext } from './SwellUiThemeContext'
import { GlobalNotificationProvider } from '../GlobalNotification'
import './fonts.css'

interface SwellUiThemeProviderProps {
  children: React.ReactNode
}

const GlobalStyle = createGlobalStyle`
    /* Variables for styling web3-onboard modal */
    :root {
      --onboard-connect-sidebar-background: #051A2B;
      --onboard-modal-backdrop: rgba(11, 20, 27, 0.7);
      --onboard-modal-background: ${({ theme }) =>
        theme.colors.oceanBlue['100']};
      --onboard-link-color: ${({ theme }) => theme.colors.lightBlue['50']};
      --onboard-gray-600: ${({ theme }) => theme.colors.white['50']};
      --onboard-primary-100: ${({ theme }) => theme.colors.lightBlue['125']};
      --onboard-primary-300: ${({ theme }) => theme.colors.lightBlue['150']};
      --onboard-primary-500: ${({ theme }) => theme.colors.lightBlue['50']};
      --onboard-warning-100: ${({ theme }) => theme.colors.lightBlue['125']};
      --onboard-warning-400: ${({ theme }) => theme.colors.lightBlue['150']};
      --onboard-warning-700: ${({ theme }) => theme.colors.white['50']};
      --onboard-modal-top: unset;
    }

    html, body {
      background-color: ${({ theme }) => theme.mainBgColor};
      color: ${({ theme }) => theme.mainColor};
      font-family: "Inter", sans-serif !important;

      a {
        color: ${({ theme }) => theme.colors.lightBlue['50']};

        &:hover {
          color: ${({ theme }) => theme.colors.lightBlue['100']};
        }
      }

      a:link {
        text-decoration: none;
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    /* Mui Datepicker stylings global because exists outside normal DOM structure */
    .MuiPickersLayout-root {
      background-color: rgb(25, 32, 38);
      color: white !important;

      .MuiPickersDay-root,
      .MuiDayCalendar-weekDayLabel,
      .MuiPickersArrowSwitcher-button,
      .MuiPickersCalendarHeader-switchViewButton {
        color: white;
      }

      .MuiPickersArrowSwitcher-button {
        &.Mui-disabled {
          opacity: 0.4;
        }
      }

      .MuiPickersDay-root, .MuiPickersYear-yearButton {
        &:hover {
          background-color: ${({ theme }) => theme.colors.lightBlue['150']};
        }

        &.Mui-disabled:not(.Mui-selected) {
          color: white;
          opacity: 0.4;
        }
      }

      .MuiPickersYear-yearButton {
        &.Mui-disabled {
          color: white;
          opacity: 0.4;
        }
      }

      .Mui-selected {
        background-color: ${({ theme }) => theme.colors.lightBlue['100']};
      }
    }
`

function SwellUiThemeProvider({ children }: SwellUiThemeProviderProps) {
  const [theme, setTheme] = useState<any>(darkTheme)
  const [themeName, setThemeName] = useState<string>('Dark Theme')

  const toggleTheme = () => {
    if (theme === lightTheme) {
      setTheme(darkTheme)
      setThemeName('Dark Theme')
    } else {
      setTheme(lightTheme)
      setThemeName('Light Theme')
    }
  }

  return (
    <SwellUiThemeContext.Provider value={{ toggleTheme, themeName, theme }}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <GlobalNotificationProvider>{children}</GlobalNotificationProvider>
      </ThemeProvider>
    </SwellUiThemeContext.Provider>
  )
}

export { SwellUiThemeProvider }
