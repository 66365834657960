import useChainDepositConfig from '@/state/deployments/hooks/useChainDepositConfig'
import { useMemo } from 'react'
import { DepositCollectionValidator } from '.'
import { TaskRunnerSerial } from '../TaskRunner/TaskRunnerSerial'
import { useV3BackendClient } from '../V3BackendService/hooks'

export const useDepositCollectionValidatorInstance = () => {
  const chainDepositConfig = useChainDepositConfig()

  const {
    v3BackendClient: {
      operator: { validateKeys },
    },
  } = useV3BackendClient()

  return useMemo(() => {
    return DepositCollectionValidator.create({
      taskRunner: TaskRunnerSerial.create(),
      chainDepositConfig,
      validateKeys,
    })
  }, [chainDepositConfig, validateKeys])
}
