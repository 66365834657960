import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { formatUnits } from 'ethers/lib/utils'
import { DatePicker } from '@swell-ui/inputs/DatePicker'
import { Box } from '@swell-ui/Box'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SwEthEthLineChart } from '@/components/SwEthEthLineChart'
import { AppReprice } from '@/types/rates'

const FIRST_REPRICE_DATE = new Date('2023-04-18')

// TODO: type prices properly
interface ExchangeRateChartProps {
  account: any
  prices?: any[]
}

const ExchangeRateChartBox = styled(Box)`
  padding: 24px 32px;
  height: 100%;
  min-height: 396px;
`

const ExchangeRateChartInner = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > div:first-child {
    padding-bottom: 24px;
  }
`

const ConnectZone = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;

  > div:first-child {
    padding-bottom: 24px;
  }

  color: ${({ theme }) => theme.colors.white['150']};
`

const ChartContainer = styled.div`
  flex-grow: 1;
`

const StyledDatePicker = styled(DatePicker)`
  width: 84px;
  height: 35px;

  .MuiInputBase-root {
    font-size: 12px;
    line-height: 160%;
    padding-right: 8px;
  }

  input {
    height: 19px;
    padding: 8px;
    padding-right: 0;
  }
`

const DatePickerSeparator = styled.div`
  width: 7px;
  height: 1px;
  background: ${({ theme }) => theme.colors.white['150']};
  margin: 20px 6px 0;
`

const Legend = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 4px;
`

const SwEthLegend = styled(Legend)`
  background: linear-gradient(180.33deg, #3068ef 0.29%, #1322ac 167.95%);
`

const EthLegend = styled(Legend)`
  background: #a4abf1;
`

function buildPriceData(prices: AppReprice[]) {
  const priceData = []

  for (let i = 0; i < prices.length; i++) {
    const price = prices[i]
    const date = new Date(price.timestamp)
    const formattedDate = date.toLocaleDateString(navigator.language, {
      day: '2-digit',
      month: '2-digit',
    })

    const ethToSwethRate = Number(formatUnits(price.rate, 18))
    const ethPrice = Number(formatUnits(price.wethUsdc, 18))
    const swethPrice = ethPrice * ethToSwethRate

    priceData.push({
      date: formattedDate,
      ethPrice: ethPrice,
      swEthPrice: swethPrice,
    })
  }

  return priceData
}

function ExchangeRateChart({ account, prices }: ExchangeRateChartProps) {
  const [startDate, setStartDate] = useState<Date>(FIRST_REPRICE_DATE)
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [allPrices, setAllPrices] = useState<any>([])
  const [priceData, setPriceData] = useState<any>([])

  useEffect(() => {
    if (prices) {
      setAllPrices(prices)
    }
  }, [prices])

  useEffect(() => {
    if (endDate.getTime() > startDate.getTime()) {
      const prices = allPrices.filter((price: any) => {
        const timestamp = price.timestamp
        return (
          timestamp >= startDate.getTime() && timestamp <= endDate.getTime()
        )
      })

      buildPriceData(prices)
      setPriceData(buildPriceData(prices))
    }
  }, [allPrices, startDate, endDate])

  return (
    <ExchangeRateChartBox>
      <ExchangeRateChartInner>
        <Typography variant="body" size="large" letterSpacing="small">
          Exchange rate
        </Typography>
        {account && (
          <>
            <FlexRow justify="space-between" align="center">
              <div>
                <FlexRow align="center">
                  <SwEthLegend />
                  <Typography variant="body" size="small" letterSpacing="small">
                    swETH
                  </Typography>
                </FlexRow>
                <FlexRow>
                  <EthLegend />
                  <Typography variant="body" size="small" letterSpacing="small">
                    ETH
                  </Typography>
                </FlexRow>
              </div>
              <div>
                <FlexRow align="center">
                  <div>
                    <Typography
                      variant="body"
                      size="xsmall"
                      letterSpacing="small"
                    >
                      Start date
                    </Typography>
                    <StyledDatePicker
                      value={startDate}
                      onChange={(value: any) => {
                        setStartDate(value)
                      }}
                      shouldDisableDate={(date: any) => {
                        return (
                          date.getTime() < FIRST_REPRICE_DATE.getTime() ||
                          date.getTime() > endDate.getTime()
                        )
                      }}
                    />
                  </div>
                  <DatePickerSeparator />
                  <div>
                    <Typography
                      variant="body"
                      size="xsmall"
                      letterSpacing="small"
                    >
                      End date
                    </Typography>
                    <StyledDatePicker
                      value={endDate}
                      onChange={(value: any) => {
                        setEndDate(value)
                      }}
                      shouldDisableDate={(date: any) => {
                        return date.getTime() < startDate.getTime()
                      }}
                    />
                  </div>
                </FlexRow>
              </div>
            </FlexRow>
            <ChartContainer>
              <SwEthEthLineChart priceData={priceData} />
            </ChartContainer>
          </>
        )}
        {!account && (
          <ConnectZone>
            <div>Connect wallet to see available data.</div>
            <div>
              <ConnectWalletButton variant="secondary" size="small">
                Connect wallet
              </ConnectWalletButton>
            </div>
          </ConnectZone>
        )}
      </ExchangeRateChartInner>
    </ExchangeRateChartBox>
  )
}

export { ExchangeRateChart }
