import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Divider } from '@swell-ui/Divider'
import { CopyIcon } from '@swell-ui/icons/CopyIcon'
import { EtherscanIcon } from '@swell-ui/icons/EtherscanIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { PowerIcon } from '@swell-ui/icons/PowerIcon'
import { PillSwitch } from '@swell-ui/PillSwitch'
import { Popover, PopoverProps } from '@swell-ui/Popover'
import { Tooltip } from '@swell-ui/Tooltip'
import { Typography } from '@swell-ui/Typography'
import { StatusIcon } from '@/components/Identicon/StatusIcon'
import useCopyClipboard from '@/hooks/useCopyClipboard'
import { shortenAddress } from '@/util/hexStrings'
import { SupportedChainId } from '@/constants/chains'
import { getChainInfo } from '@/constants/chainInfo'
import {
  useEthUsdMarketRate,
  useSwEthEthMarketRate,
  useSwEthUsdMarketRate,
} from '@/state/fiat/hooks'
import { useEthBalance, useSwEthBalance } from '@/state/user/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useSwEthToken } from '@/state/deployments/hooks'
import {
  useDisplayNativeCurrencyPriceFiat,
  useDisplayNativeCurrency,
  useDisplayNativeCurrencyInSwEth,
} from '@/hooks/useNativeCurrencyDisplay'
import {
  useDisplaySwEth,
  useDisplaySwEthInNativeCurrency,
  useDisplaySwEthPriceFiat,
} from '@/hooks/useSwEthDisplay'
import { useParseNativeCurrency } from '@/hooks/useNativeCurrencyFormatting'

const StyledWeb3AccountInfoPopover = styled(Popover)`
  ${({ theme }) => `
    .MuiBackdrop-root {
      background-color: ${theme.dialog.backdrop.bgColor};
      backdrop-filter: ${theme.dialog.backdrop.backdropFilter};

      ${theme.breakpoints.up('sm')} {
        background-color: transparent;
        backdrop-filter: unset;
      }
    }

    .MuiPaper-root {
      position: initial;
      margin: 0 auto;
      width: 340px;
      margin-top: 192px;

      ${theme.breakpoints.up('sm')} {
        position: absolute;
        width: 288px;
        margin: unset;
      }
    }
  `}
`

const PopoverInner = styled.div`
  ${({ theme }) => `
    position: relative;
    padding: 24px 32px;
    text-align: center;
    margin: 0;

    IconButton:hover {
      cursor: poin;
    }

    ${theme.breakpoints.up('sm')} {
      width: 288px;
      max-width: 332px;
    }
  `}
`

const AddressTypography = styled(Typography)`
  margin-right: 6px;
`

const NetworkTypography = styled(Typography)`
  margin-right: 12px;
`

const IconTooltip = styled(Tooltip)`
  height: 22px;
`

const PillSwitchRow = styled(FlexRow)`
  margin-bottom: 8px;
`

const AccountSectionWrapper = styled.div`
  letter-spacing: -0.03em;
`

const StyledCopyIcon = styled(CopyIcon)`
  height: 22px;
  width: 22px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const StyledEtherscanIcon = styled(EtherscanIcon)`
  height: 22px;
  width: 22px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const StyledPowerIcon = styled(PowerIcon)`
  height: 22px;
  width: 22px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const FiatCurrency = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  letter-spacing: -0.03em;
  font-weight: 500;
`

const InlineRow = styled.div`
  display: flex;
  flex-flow: row nowrap;

  text-align: left;

  > * + * {
    margin-left: 0.2em;
  }
`

const RateContainer = styled.div`
  min-width: 60px;
`

function Web3AccountSection() {
  const { account, chainId, disconnect, icon, type, ENSName } = useSwellWeb3()

  const chainInfo = getChainInfo(chainId as SupportedChainId)
  const etherscanHref = `${chainInfo?.explorer}address/${account}`

  const disconnectWallet = async () => {
    await disconnect()
    window.localStorage.removeItem('connectedWallets')
  }

  const [isCopied, setCopied] = useCopyClipboard()
  const copy = useCallback(() => {
    setCopied(account || '')
  }, [account, setCopied])

  const shortenedAccount = shortenAddress(account || '', 4, 4)

  return (
    <AccountSectionWrapper>
      <FlexRow justify="space-between">
        <StatusIcon walletType={type} fallback={icon} size={56} />
        <div>
          <FlexRow>
            <AddressTypography variant="body" size="medium">
              {ENSName || shortenedAccount}
            </AddressTypography>
            <IconTooltip title={isCopied ? 'Copied!' : 'Copy'} arrow>
              <StyledCopyIcon onClick={copy} />
            </IconTooltip>
          </FlexRow>
          <FlexRow justify="left">
            <NetworkTypography variant="body" size="small">
              {chainInfo?.label}
            </NetworkTypography>
            <IconTooltip title="Etherscan" arrow>
              <a href={etherscanHref} target="_blank" rel="noreferrer">
                <StyledEtherscanIcon />
              </a>
            </IconTooltip>
            <IconTooltip title="Disconnect" arrow>
              <StyledPowerIcon onClick={disconnectWallet} />
            </IconTooltip>
          </FlexRow>
        </div>
      </FlexRow>
    </AccountSectionWrapper>
  )
}

function EthBalanceSection() {
  const ethBalance = useEthBalance()
  const ethUsdMarketRate = useEthUsdMarketRate()

  const displayNativeCurrency = useDisplayNativeCurrency()
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  return (
    <div>
      <FlexRow justify="center">
        <Typography variant="body" size="small">
          ETH Balance
        </Typography>
      </FlexRow>
      <FlexRow justify="center">
        <Typography variant="body" size="large" fstyle="bold">
          <AsyncDiv loading={!ethBalance.data}>
            {() => {
              return displayNativeCurrency(ethBalance.data!.balance, {
                precision: 4,
                localize: true,
              })
            }}
          </AsyncDiv>
        </Typography>
      </FlexRow>
      <FlexRow justify="center">
        <FiatCurrency>
          <AsyncDiv loading={!ethBalance.data || !ethUsdMarketRate.data}>
            {() => {
              const { balance } = ethBalance.data!
              const { rate } = ethUsdMarketRate.data!
              return `${displayNativeCurrencyPriceFiat(balance, rate)} USD`
            }}
          </AsyncDiv>
        </FiatCurrency>
      </FlexRow>
    </div>
  )
}

function SwEthBalanceSection() {
  const [showUSD, setShowUSD] = useState(true)

  const { symbol: swEthSymbol = 'swETH' } = useSwEthToken()

  const swEthBalance = useSwEthBalance()
  const swEthEthMarketRate = useSwEthEthMarketRate()
  const swEthUsdMarketRate = useSwEthUsdMarketRate()

  const parseNativeCurrency = useParseNativeCurrency()

  const displaySwEth = useDisplaySwEth()
  const displaySwEthInNativeCurrency = useDisplaySwEthInNativeCurrency()
  const displayNativeCurrencyInSwEth = useDisplayNativeCurrencyInSwEth()
  const displaySwEthPriceFiat = useDisplaySwEthPriceFiat()

  const handlePillSwitchChange = (key: string) => {
    if (key === 'usd') {
      setShowUSD(true)
    } else {
      setShowUSD(false)
    }
  }

  const config: any = {
    choices: [
      {
        key: 'usd',
        label: 'USD',
      },
      {
        key: 'eth',
        label: 'ETH',
      },
    ],
    defaultValue: 'usd',
  }

  return (
    <div>
      <FlexRow justify="center">
        <Typography variant="body" size="small">
          swETH Balance
        </Typography>
      </FlexRow>
      <FlexRow justify="center">
        <Typography variant="body" size="large" fstyle="bold">
          <AsyncDiv loading={!swEthBalance.data}>
            {() => {
              const { balance } = swEthBalance.data!
              return displaySwEth(balance, { precision: 4, localize: true })
            }}
          </AsyncDiv>
        </Typography>
      </FlexRow>
      <PillSwitchRow justify="center" gap="8">
        <FiatCurrency>
          {showUSD && (
            <AsyncDiv loading={!swEthBalance.data || !swEthUsdMarketRate.data}>
              {() => {
                const { balance } = swEthBalance.data!
                const { rate } = swEthUsdMarketRate.data!
                return displaySwEthPriceFiat(balance, rate)
              }}
            </AsyncDiv>
          )}
          {!showUSD && (
            <AsyncDiv loading={!swEthBalance.data || !swEthEthMarketRate.data}>
              {() => {
                const { balance } = swEthBalance.data!
                const { rate } = swEthEthMarketRate.data!
                return displaySwEthInNativeCurrency(balance, rate, {
                  precision: 4,
                  localize: true,
                })
              }}
            </AsyncDiv>
          )}
        </FiatCurrency>
        <PillSwitch config={config} onChange={handlePillSwitchChange} />
      </PillSwitchRow>
      <FlexRow justify="center">
        <Typography variant="body" size="small">
          <IconTooltip title={'Primary market rate'} arrow>
            {!showUSD && (
              <InlineRow>
                <span>1 ETH = </span>
                <AsyncDiv
                  loading={!swEthEthMarketRate.data}
                  // RateContainer enforces min-width of the loading skeleton
                  // React.Fragment ensures text is centered when value is loaded
                  Container={
                    !swEthEthMarketRate.data ? RateContainer : React.Fragment
                  }
                >
                  {() => {
                    const { rate } = swEthEthMarketRate.data!
                    return (
                      <span>
                        {displayNativeCurrencyInSwEth(
                          parseNativeCurrency('1'),
                          1 / rate,
                          { precision: 4, localize: true }
                        )}
                      </span>
                    )
                  }}
                </AsyncDiv>
              </InlineRow>
            )}
            {showUSD && (
              <InlineRow>
                <span>1 {swEthSymbol} = </span>
                <AsyncDiv
                  loading={!swEthUsdMarketRate.data}
                  // RateContainer enforces min-width of the loading skeleton
                  // React.Fragment ensures text is centered when value is loaded
                  Container={
                    !swEthUsdMarketRate.data ? RateContainer : React.Fragment
                  }
                >
                  {() => {
                    return (
                      <span>
                        {Number(
                          swEthUsdMarketRate.data!.rate.toFixed(2)
                        ).toLocaleString(navigator.language)}{' '}
                        USD
                      </span>
                    )
                  }}
                </AsyncDiv>
              </InlineRow>
            )}
          </IconTooltip>
        </Typography>
      </FlexRow>
    </div>
  )
}

function Web3AccountInfoPopover(props: PopoverProps) {
  return (
    <StyledWeb3AccountInfoPopover {...props}>
      <PopoverInner>
        <Web3AccountSection />
        <Divider />
        <EthBalanceSection />
        <Divider />
        <SwEthBalanceSection />
      </PopoverInner>
    </StyledWeb3AccountInfoPopover>
  )
}

export { Web3AccountInfoPopover }
