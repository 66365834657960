import React from 'react'
import styled from 'styled-components/macro'
import { Typography } from '@swell-ui/Typography'

const StyledPageTitle = styled(Typography)`
  padding: 40px 0;
  letter-spacing: -0.03em;
`

interface PageTitleProps {
  text: string
}

function PageTitle({ text, ...props }: PageTitleProps) {
  return (
    <StyledPageTitle {...props} variant="headline" size="h2" fstyle="bold">
      {text}
    </StyledPageTitle>
  )
}

export { PageTitle }
