import { useState } from 'react'
import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { Skeleton } from '@/swell-ui/Skeleton'
import { useStakingRate } from '@/state/stakingStats/hooks'
import { AccountInfoButton } from './AccountInfoButton'
import {
  useDisplaySwEth,
  useDisplaySwEthInNativeCurrency,
} from '@/hooks/useSwEthDisplay'

interface StakerAccountButtonProps {
  swEthBalance: BigNumber | undefined
  loading: boolean
}

const SuperText = styled.div`
  display: flex;
  font-size: 10px;
  padding-top: 4px;
  line-height: 132%;
`

const FieldContainer = styled.div`
  display: flex;
  min-width: 100px;
`

function StakerAccountButton({
  swEthBalance,
  loading,
}: StakerAccountButtonProps) {
  const stakingRate = useStakingRate()

  const displaySwEth = useDisplaySwEth()
  const displaySwEthInNativeCurrency = useDisplaySwEthInNativeCurrency()

  const [showEth, setShowEth] = useState(false)

  const handleBalanceMouseEnter = () => {
    setShowEth(true)
  }

  const handleBalanceMouseLeave = () => {
    setShowEth(false)
  }

  return (
    <AccountInfoButton
      color="accent"
      onMouseEnter={handleBalanceMouseEnter}
      onMouseLeave={handleBalanceMouseLeave}
    >
      <div>
        <SuperText>Staked swETH</SuperText>
        <AsyncDiv
          loading={loading || !stakingRate.data || !swEthBalance}
          Container={FieldContainer}
          loader={() => <Skeleton width={100} />}
        >
          {() => {
            const swEthBalanceDisplay = displaySwEth(
              swEthBalance as BigNumber,
              {
                precision: 4,
                localize: true,
              }
            )

            const { swEthToNativeRate } = stakingRate.data!

            const ethBalanceDisplay = displaySwEthInNativeCurrency(
              swEthBalance as BigNumber,
              swEthToNativeRate,
              { precision: 4, localize: true }
            )
            return (
              <span>
                {!showEth && swEthBalanceDisplay}
                {showEth && ethBalanceDisplay}
              </span>
            )
          }}
        </AsyncDiv>
      </div>
    </AccountInfoButton>
  )
}

export { StakerAccountButton }
