import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { useCallback } from 'react'
import { useDataLivenessConfig } from '../deployments/hooks/useDataLivenessConfig'
import { useSwEthBalance, useEthBalance } from './hooks'

export default function UserUpdater(): null {
  const { mutate: swEthBalanceMutate, data: swEthBalanceData } =
    useSwEthBalance()
  const { mutate: ethBalanceMutate, data: ethBalanceData } = useEthBalance()

  const { userTokenBalancesLiveness } = useDataLivenessConfig()

  const dataToRefresh = !!swEthBalanceData || !!ethBalanceData

  const mutate = useCallback(() => {
    swEthBalanceMutate()
    ethBalanceMutate()
  }, [ethBalanceMutate, swEthBalanceMutate])

  useEveryNthBlock({
    blocksPerFetch: userTokenBalancesLiveness.blocksPerFetch,
    fetch: mutate,
    skip: !dataToRefresh, // if there is no data to refresh, then deactivate block listener
  })

  return null
}
