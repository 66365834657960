import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { Dialog, DialogContent, DialogTitle } from '@swell-ui/Dialog'
import { GradientLoader } from '@swell-ui/GradientLoader'
import { Typography } from '@swell-ui/Typography'
import { StakingSubmissionStatus } from '@/hooks/useStakeLens'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useChainInfo } from '@/state/deployments/hooks'
import { displayCrypto } from '@/util/displayCrypto'
import { ACTIONS } from '../constants'

interface TransactionInProgressModalProps {
  action: string
  zap: any
  approve: any
  status: StakingSubmissionStatus
  nativeCurrencyDisplayStr?: string
  swEthAmountDisplayStr?: string
  txhash?: string
}

const StyledCloseIcon = styled(CloseIcon)`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  path {
    stroke-width: 0.666667px;
  }
`

const StakeEthDialogContent = styled(DialogContent)`
  padding: 0 48px 80px;
  letter-spacing: -0.03em;
`

const WaitingToConnectSection = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
`

const EtherscanLinkWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: -35px;
`

function StakeView({
  status,
  nativeCurrencyDisplayStr,
  swEthAmountDisplayStr,
  txhash,
}: {
  status: StakingSubmissionStatus
  nativeCurrencyDisplayStr?: string
  swEthAmountDisplayStr?: string
  txhash?: string
}) {
  const { explorer } = useChainInfo()

  return (
    <WaitingToConnectSection>
      <LoaderContainer style={{ padding: '16px 0px' }}>
        <GradientLoader />
      </LoaderContainer>
      <Typography variant="body" size="large">
        {status === StakingSubmissionStatus.PROMPTING
          ? 'Confirm transaction in wallet'
          : 'Transaction in progress...'}
      </Typography>
      <Typography variant="body" size="medium">
        You are staking {nativeCurrencyDisplayStr} for {swEthAmountDisplayStr}.
      </Typography>
      <EtherscanLinkWrapper>
        {txhash && (
          <Typography variant="body" size="small">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${explorer}/tx/${txhash}`}
            >
              View on etherscan
            </a>
          </Typography>
        )}
      </EtherscanLinkWrapper>
    </WaitingToConnectSection>
  )
}

function ZapView({ zap, approve }: { zap: any; approve: any }) {
  const { explorer } = useChainInfo()

  const STATUS_TEXT = {
    [zap.STATUS.GET_ROUTE]: 'Building transaction...',
    [zap.STATUS.BUILDING]: 'Building transaction...',
    [zap.STATUS.PROMPTING]: 'Confirm transaction in wallet',
    [zap.STATUS.PENDING]: 'Transaction in progress...',
  }

  let formattedApproveAmount = ''
  if (approve.token) {
    formattedApproveAmount = displayCrypto(
      approve.amount,
      approve.token.decimals,
      {
        precision: 4,
        localize: true,
      }
    )
  }

  let formattedFromAmount = '0'
  let formattedToAmount = '0'
  if (zap.fromToken && zap.toToken) {
    formattedFromAmount = displayCrypto(zap.fromAmount, zap.toToken.decimals, {
      precision: 4,
      localize: true,
    })

    formattedToAmount = displayCrypto(zap.toAmount, zap.fromToken.decimals, {
      precision: 4,
      localize: true,
    })
  }

  return (
    <WaitingToConnectSection>
      <LoaderContainer style={{ padding: '16px 0px' }}>
        <GradientLoader />
      </LoaderContainer>
      <Typography variant="body" size="large">
        {approve.isLoading && STATUS_TEXT[approve.status]}
        {(zap.isLoading || zap.status === zap.STATUS.GET_ROUTE) &&
          STATUS_TEXT[zap.status]}
      </Typography>
      <Typography variant="body" size="medium">
        {approve.isLoading && (
          <>
            You are approving a spending cap of {formattedApproveAmount}{' '}
            {approve.token.symbol}
          </>
        )}
        {zap.isLoading && (
          <>
            You are zapping {formattedFromAmount} {zap.fromToken.symbol} for{' '}
            {formattedToAmount} swETH.
          </>
        )}
        {zap.status === zap.STATUS.GET_ROUTE && (
          <>Making sure route is up to date...</>
        )}
      </Typography>
      <EtherscanLinkWrapper>
        {zap.tx && (
          <Typography variant="body" size="small">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${explorer}/tx/${zap.tx.hash}`}
            >
              View on etherscan
            </a>
          </Typography>
        )}
      </EtherscanLinkWrapper>
    </WaitingToConnectSection>
  )
}

function TransactionInProgressModal({
  action,
  zap,
  approve,
  status,
  nativeCurrencyDisplayStr,
  swEthAmountDisplayStr,
  txhash,
}: TransactionInProgressModalProps) {
  // grants the ability to close the modal during the submission process.
  // Does not cancel the submission flow.
  const [closedOverride, setClosedOverride] = React.useState<boolean>()

  const modalActive =
    status === StakingSubmissionStatus.PENDING ||
    status === StakingSubmissionStatus.PROMPTING ||
    (action === ACTIONS.ZAP &&
      (zap.isLoading || zap.status === zap.STATUS.GET_ROUTE)) ||
    (action === ACTIONS.ZAP && approve.isLoading)
  const open = closedOverride ? false : modalActive

  useEffect(() => {
    // reset the overridden closed state when the modal is no longer active
    if (!modalActive) {
      setClosedOverride(false)
    }
  }, [modalActive])

  const forceClose = () => setClosedOverride(true)

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        <FlexRow justify="right">
          <StyledCloseIcon aria-label="Close" onClick={forceClose} />
        </FlexRow>
      </DialogTitle>
      <StakeEthDialogContent>
        <>
          {action === ACTIONS.STAKE && (
            <StakeView
              status={status}
              nativeCurrencyDisplayStr={nativeCurrencyDisplayStr}
              swEthAmountDisplayStr={swEthAmountDisplayStr}
              txhash={txhash}
            />
          )}
          {action === ACTIONS.ZAP && <ZapView zap={zap} approve={approve} />}
        </>
      </StakeEthDialogContent>
    </Dialog>
  )
}

export { TransactionInProgressModal }
