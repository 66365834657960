import { useSwellWeb3 } from '@swell-web3/core'
import { useCallback, useState } from 'react'
import { FungibleToken } from '@/util/FungibleToken'

export interface IUseAddTokenArgs {
  token: FungibleToken
}

export interface IUseAddTokenOutput {
  success?: boolean
  addToken: () => void
  canWatchAssets: boolean
}

// sw3 TOOD: implement new version of useAddToken
const useAddToken = ({ token }: IUseAddTokenArgs): IUseAddTokenOutput => {
  const [success, setSuccess] = useState<boolean | undefined>()

  const { connector } = useSwellWeb3()

  const addToken = useCallback(() => {
    if (!token?.symbol || !connector.watchAsset) return
    connector
      .watchAsset({
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        image: token.logoURL ?? '',
      })
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false))
  }, [connector, token])

  return {
    addToken,
    canWatchAssets: !!connector.watchAsset,
    success,
  }
}

export default useAddToken
