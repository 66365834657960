import React from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Box } from '@swell-ui/Box'
import { Grid } from '@swell-ui/Grid'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SectionBoxLabel } from '@/components/SectionBoxLabel'
import {
  DepositManagerDataBoxGrid,
  DepositManagerDataBoxGridProps,
} from './DepositManagerDataBoxGrid'
import { useUserOperator } from '@/state/userOperator/hooks'
import {
  YourInfoDataBoxGrid,
  YourInfoDataBoxGridProps,
} from './YourInfoDataBoxGrid'
import {
  useUserOperatorActiveValidatorKeys,
  useUserOperatorPendingValidatorKeys,
} from '@/state/userOperator/hooks'
import { isNoOperatorFoundError } from '@/state/userOperator/util'
import {
  useProtocolBufferedEth,
  useProtocolLastDepositAt,
  useProtocolUnusedKeysCount,
} from '@/state/protocolOperators/hooks'
import { queryHasInfo } from '@/util/queries'

const ContainerBox = styled(Box)`
  padding: 24px 32px;

  button {
    width: 100%;
    max-width: 392px;
  }
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
`

function SwellDepositManager() {
  const { account } = useSwellWeb3()

  // protocol wide queries
  const protocolBufferedEthQuery = useProtocolBufferedEth()
  const protocolLastDepositAtQuery = useProtocolLastDepositAt()
  const protocolUnusedKeysCountQuery = useProtocolUnusedKeysCount()

  // user operator queries
  const userOperator = useUserOperator()
  const activeValidatorKeysQuery = useUserOperatorActiveValidatorKeys()
  const pendingValidatorKeysQuery = useUserOperatorPendingValidatorKeys()

  // presentational component props
  const depositManagerProps =
    React.useMemo<DepositManagerDataBoxGridProps>(() => {
      return {
        get bufferedEth() {
          if (!protocolBufferedEthQuery.data) return { loading: true as const }
          return {
            loading: false as const,
            value: protocolBufferedEthQuery.data.bufferedEth,
          }
        },
        get lastDepositAt() {
          if (!protocolLastDepositAtQuery.data)
            return { loading: true as const }
          return {
            loading: false as const,
            value: protocolLastDepositAtQuery.data.lastDepositAt,
          }
        },
        get unusedKeysCount() {
          if (!protocolUnusedKeysCountQuery.data)
            return { loading: true as const }
          return {
            loading: false as const,
            value: protocolUnusedKeysCountQuery.data.unusedKeysCount.toNumber(),
          }
        },
      }
    }, [
      protocolBufferedEthQuery,
      protocolLastDepositAtQuery,
      protocolUnusedKeysCountQuery,
    ])

  const yourInfoProps = React.useMemo<YourInfoDataBoxGridProps>(() => {
    if (!queryHasInfo([activeValidatorKeysQuery, pendingValidatorKeysQuery]))
      return { kind: 'loading' }

    const error =
      activeValidatorKeysQuery.error ?? pendingValidatorKeysQuery.error

    if (error) {
      // special case: non-operator accounts have a different view
      if (isNoOperatorFoundError(error)) return { kind: 'non-operator-account' }
      return { kind: 'hidden' }
    }

    const { activeValidatorKeys } = activeValidatorKeysQuery.data!
    const { pendingValidatorKeys } = pendingValidatorKeysQuery.data!

    const usedKeys = activeValidatorKeys.length
    const unusedKeys = pendingValidatorKeys.length
    const totalKeys = usedKeys + unusedKeys

    return { kind: 'operator', totalKeys, unusedKeys, usedKeys }
  }, [activeValidatorKeysQuery, pendingValidatorKeysQuery])

  let infoLabelPrefix = 'Your'

  if (userOperator.data) {
    const {
      operator: { name },
    } = userOperator.data
    infoLabelPrefix = `${name} `
  }

  return (
    <ContainerBox>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <SectionBoxLabel>Swell deposit manager</SectionBoxLabel>
        </Grid>
        <Grid item>
          <div>
            <DepositManagerDataBoxGrid {...depositManagerProps} />
          </div>
        </Grid>
        <Grid item>
          <SectionBoxLabel>{infoLabelPrefix} info</SectionBoxLabel>
        </Grid>
        <Grid item>
          {!account && (
            <>
              <CenteredContent>
                <ConnectWalletButton>
                  Connect wallet to view
                </ConnectWalletButton>
              </CenteredContent>
            </>
          )}
          {account && <YourInfoDataBoxGrid {...yourInfoProps} />}
        </Grid>
      </Grid>
    </ContainerBox>
  )
}

export { SwellDepositManager }
