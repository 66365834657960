import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

function calculateExchangeRate(route: any) {
  const srcAmountBN = BigNumber.from(route.fromAmount)
  const destAmountBN = BigNumber.from(route.toAmount)

  const srcAmount = formatUnits(srcAmountBN, route.fromToken.decimals)
  const destAmount = formatUnits(destAmountBN, route.toToken.decimals)

  const exchangeRate = parseFloat(destAmount) / parseFloat(srcAmount)
  return exchangeRate
}

function calculatePriceImpact(route: any) {
  const srcAmountUSD = route.fromAmountUSD
  const destAmountUSD = route.toAmountUSD

  return ((destAmountUSD - srcAmountUSD) / srcAmountUSD) * 100
}

export function swapInfoAdapter(route: any) {
  return {
    exchange: route.steps[0].tool,
    exchangeRate: calculateExchangeRate(route),
    gasCostUSD: route.gasCostUSD,
    percent: 100,
    priceImpact: calculatePriceImpact(route),
  }
}
