export const TOKEN_LIST_ETH = {
  chainId: 1,
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  name: 'Ethereum',
  symbol: 'ETH',
  decimals: 18,
  logoURI: '',
}

export const TOKEN_LIST_SWETH = {
  chainId: 1,
  address: '0xf951e335afb289353dc249e82926178eac7ded78',
  name: 'Swell Ethereum',
  symbol: 'SWETH',
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/30326/thumb/_lB7zEtS_400x400.jpg?1684128260',
}
