import coinbaseWalletModule from '@web3-onboard/coinbase'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnect from '@web3-onboard/walletconnect'

interface BuildWalletOptions {
  walletConnectV1Bridge: string
  walletConnectV2ProjectId: string
}

function buildWallets({
  walletConnectV1Bridge,
  walletConnectV2ProjectId,
}: BuildWalletOptions) {
  // https://onboard.blocknative.com/docs/wallets/injected
  const injected = injectedModule()

  // https://onboard.blocknative.com/docs/wallets/coinbase
  const coinbase = coinbaseWalletModule({ darkMode: true })

  // https://onboard.blocknative.com/docs/wallets/walletconnect
  const WALLET_CONNECT_V1_MODULE_NAME = 'WalletConnect v1'
  const walletConnectV1Module = () => {
    return (helpers: any) => {
      const walletConnectModule = walletConnect({
        version: 1,
        bridge: walletConnectV1Bridge,
      })(helpers) as any

      // web3-onboard de-dupes identical labels, must change so we can support both
      walletConnectModule.label = WALLET_CONNECT_V1_MODULE_NAME

      return walletConnectModule
    }
  }
  const walletConnectV1 = walletConnectV1Module()

  const walletConnectV2 = walletConnect({
    // should be based on deployment set
    version: 2,
    projectId: walletConnectV2ProjectId,
    requiredChains: [1],
    optionalChains: [5],
    // dappUrl equired by some wallets (i.e. MetaMask)
    dappUrl: 'https://app.swellnetwork.io',
  })

  return [injected, coinbase, walletConnectV1, walletConnectV2]
}

export { buildWallets }
