import { Interface } from 'ethers/lib/utils'
import { getParsedEthersError } from '@enzoferey/ethers-error-parser'

/**
 * Transforms an error resulting from a wallet interaction into a string
 *  suitable for display purposes.
 *
 * @param err The error thrown by the web3 interaction
 * @param iface The interface of the contract whose calling resulted in an error
 * @returns Display string
 */
export const web3ErrorAdapter = (
  err: any | undefined,
  iface: Interface
): string => {
  const parsedError = getParsedEthersError(err)
  console.warn('Web3 error', { parsedError, err })

  switch (parsedError.errorCode) {
    case 'CALL_REVERTED': {
      // TODO: Have not seen this case yet
      console.warn('Call reverted', { parsedError, err })
      return 'Call reverted'
    }

    case 'INSUFFICIENT_FUNDS_FOR_GAS':
      return 'Insufficient funds for gas'

    case 'MAX_FEE_PER_GAS_LESS_THAN_BLOCK_BASE_FEE':
      return 'Max gas fee setting is too low'

    case 'MAX_PRIORITY_FEE_PER_GAS_HIGHER_THAN_MAX_FEE_PER_GAS':
      return 'Max gas fee setting is too high'

    case 'NONCE_TOO_LOW':
      return 'Nonce too low'

    case 'REJECTED_TRANSACTION':
      return 'Transaction rejected'

    case 'TRANSACTION_RAN_OUT_OF_GAS':
      return 'Transaction ran out of gas'

    case 'TRANSACTION_UNDERPRICED':
      return 'Transaction underpriced'

    case 'UNKNOWN_ERROR':
    default: {
      console.warn('Reverted (unknown)', { parsedError, err })

      const getErrorData = () => {
        let error = err
        let errorData: string | undefined
        let reason: string | undefined

        while (error) {
          errorData = error.data ?? errorData
          reason = error.reason ?? error.message ?? reason
          error = error.error ?? error.data?.originalError
        }

        return { error, reason, errorData }
      }

      const { errorData, reason } = getErrorData()

      if (errorData) {
        try {
          const decodedErrorMsg = iface.parseError(errorData)
          return `Execution reverted: ${decodedErrorMsg.name}`
        } catch (parsingErr) {
          console.error('Failed to parse error', { parsingErr, err })
        }
      }

      // Execution reverted errors (dynamic error reason)
      return reason ? `Execution reverted: ${reason}` : 'Execution reverted'
    }
  }
}
