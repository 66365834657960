import { ReactNode, useCallback, useMemo } from 'react'
import take from 'lodash/take'
import orderBy from 'lodash/orderBy'
import { Table, TableConfig } from '@swell-ui/Table'
import { shortenAddress, shortenHash } from '@/util/hexStrings'
import { AppTransactionToken } from '@/types/transaction'
import { TimeDelta } from '@/swell-ui/TimeDelta'
import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import styled from 'styled-components/macro'

const StyledTable = styled(Table)`
  max-height: 228px;

  .hash-cell {
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0;
  }

  .age-cell {
    min-width: 116px;
  }

  .to-cell {
    min-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .value-cell {
    min-width: 125px;
  }
`

type RowType = {
  hash: ReactNode
  age: ReactNode
  to: ReactNode
  value: ReactNode
}

const COLUMNS: TableConfig['columns'] = [
  {
    key: 'hash',
    label: 'Txn hash',
  },
  {
    key: 'age',
    label: 'Age',
  },
  {
    key: 'to',
    label: 'To',
  },
  {
    key: 'value',
    label: 'Value',
  },
]

const LOADING_LENGTH = 4
const MAX_ROW_COUNT = 4

export interface IFeesEarnedTableProps {
  feesEarnedTransactions?: AppTransactionToken[]
}

function FeesEarnedTable({ feesEarnedTransactions }: IFeesEarnedTableProps) {
  const displayNativeCurrency = useDisplayNativeCurrency()

  const mapTxToRow = useCallback(
    (tx: AppTransactionToken): RowType => {
      return {
        age: tx.createdAt ? <TimeDelta date={tx.createdAt} /> : '--',
        hash: <span title={tx.txHash}>{shortenHash(tx.txHash)}</span>,
        to: <span title={tx.to}>{shortenAddress(tx.to)}</span>,
        value: displayNativeCurrency(tx.amount, { precision: 6 }),
      }
    },
    [displayNativeCurrency]
  )

  const tableConfig = useMemo<TableConfig>(() => {
    if (feesEarnedTransactions === undefined) {
      return {
        columns: COLUMNS,
        rows: [],
        loadingLength: LOADING_LENGTH,
        stickyHeader: true,
      }
    }
    const orderedTransactions = orderBy(
      take(feesEarnedTransactions, MAX_ROW_COUNT),
      ({ createdAt }) => createdAt,
      'desc'
    )

    return {
      columns: COLUMNS,
      rows: orderedTransactions.map(mapTxToRow),
      loadingLength: LOADING_LENGTH,
      stickyHeader: true,
    }
  }, [feesEarnedTransactions, mapTxToRow])

  return (
    <>
      <StyledTable
        loading={feesEarnedTransactions === undefined}
        config={tableConfig}
      />
    </>
  )
}

export { FeesEarnedTable }
