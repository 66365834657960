import React from 'react'
import styled from 'styled-components/macro'
import { Route, Routes, Navigate } from 'react-router-dom'
import { Container } from '@swell-ui/Container'
import { FlexRow } from '@swell-ui/FlexRow'
import { GlobalNotificationContainer } from '@swell-ui/GlobalNotification'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { TopNavBar } from '@/components/TopNavBar'
import { StakingPage } from '@/pages/StakingPage'
import { Earn } from '@/pages/Earn'
import { AllOperators } from '@/pages/AllOperators'
import { OperatorDashboard } from '@/pages/OperatorDashboard'
import { PortfolioPage } from '@/pages/PortfolioPage'
import { ValidatorKeys } from '@/pages/ValidatorKeys'
import { Voyage } from '@/pages/Voyage'
import { BottomNavBar } from '@/components/BottomNavBar'
import { Footer } from '@/components/Footer'
import { Web3NavWidget } from '@/components/Web3NavWidget'
import { OceanBackground } from '@/components/OceanBackground'
import { VoyageBgImg } from '@/components/VoyageBgImg'
import { Helmet } from 'react-helmet'
import { DepositSubmissionDetailsProvider } from './state/depositSubmission/hooks'

const StyledGlobalNotificationContainer = styled(GlobalNotificationContainer)`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 100;
`

const AppContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
`

const AppInner = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  flex: 1 1 auto;
  max-width: 1536px;
  padding-left: 36px;
  padding-right: 36px;
  margin: 0 auto;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      padding-left: 16px;
      padding-right: 16px;
    }
  `}
`

const InnerWallet = styled(FlexRow)`
  margin-bottom: 24px;
`

function App() {
  const is1040Up = useMediaQuery('(min-width:1040px)')

  return (
    <>
      <Helmet>
        <title>Swell | Liquid staking made simple</title>
      </Helmet>
      <AppContainer maxWidth="xl">
        <TopNavBar />
        <StyledGlobalNotificationContainer />
        <AppInner>
          {!is1040Up && (
            <InnerWallet justify="right">
              <Web3NavWidget />
            </InnerWallet>
          )}
          <Routes>
            <Route index element={<StakingPage />} />
            <Route path="voyage" element={<Voyage />} />
            <Route path="portfolio" element={<PortfolioPage />} />
            <Route path="earn" element={<Earn />} />
            <Route path="operators">
              <Route index element={<Navigate to="/operators/dashboard" />} />
              <Route path="dashboard" element={<OperatorDashboard />} />
              <Route path="all-operators" element={<AllOperators />} />
              <Route
                path="validator-keys"
                element={
                  <DepositSubmissionDetailsProvider>
                    <ValidatorKeys />
                  </DepositSubmissionDetailsProvider>
                }
              />
              <Route
                path="*"
                element={<Navigate to="/operators/dashboard" />}
              />
            </Route>
            <Route path="vault" element={<Navigate to="/" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AppInner>
        <Routes>
          <Route path="voyage" element={<></>} />
          <Route path="*" element={<Footer />} />
        </Routes>
        <BottomNavBar />
      </AppContainer>
      <Routes>
        <Route path="voyage" element={<VoyageBgImg />} />
        <Route path="*" element={<OceanBackground />} />
      </Routes>
    </>
  )
}

export default App
