import { useMemo } from 'react'
import { useCoingeckoTokenListQuery } from '@/services/TokenList'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { Token } from '@/types/tokens'

const BOOSTED_TOKEN_ADDRESSES = {
  STETH: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
  WSTETH: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
  USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
}

export function useBoostedTokens(): Token[] {
  const coingeckoListQuery = useCoingeckoTokenListQuery()

  const tokens = useMemo(() => {
    const ADDRESSES = [
      BOOSTED_TOKEN_ADDRESSES.STETH,
      BOOSTED_TOKEN_ADDRESSES.WSTETH,
      TOKEN_LIST_ETH.address,
      BOOSTED_TOKEN_ADDRESSES.USDC,
    ]

    const boostedTokens: Token[] = []

    if (coingeckoListQuery.data) {
      ADDRESSES.forEach((address: string) => {
        if (address === TOKEN_LIST_ETH.address) {
          boostedTokens.push(TOKEN_LIST_ETH)
        } else {
          const token = coingeckoListQuery.data.tokenMap[address]
          boostedTokens.push(token)
        }
      })
    }

    return boostedTokens
  }, [coingeckoListQuery.data])

  return tokens
}
