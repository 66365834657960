import React from 'react'
import styled from 'styled-components/macro'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { StakeIcon } from '@swell-ui/icons/StakeIcon'
import vaultUrl from '@/assets/images/vault.png'

interface ISecondaryMarketC2AProps {
  action: string
  discountPercent?: number
}

const C2ASpan = styled.span`
  margin-top: 2px;

  display: inline-block;
  > span {
    font-weight: 600;
  }

  color: #fff;
  font-size: 12px;
  letter-spacing: -0.15px;
  font-weight: 500;
  line-height: 14.52px;
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  height: 66px;
  padding: 0 12px;
  align-items: center;
  background: linear-gradient(90deg, #172834 3.79%, #0c162c 99.86%);
  border-radius: 8px;

  img {
    margin-right: 8px;
  }
`

const ZapIcon = styled(StakeIcon)`
  position: relative;
  top: 2px;

  path {
    fill: #ffe500;
  }
`

const formatPercentApprox = (percentValue: number) => {
  return `~${Math.round(percentValue! * 10) / 10}%`
}

export function SecondaryMarketC2A({
  action,
  discountPercent,
}: ISecondaryMarketC2AProps) {
  const icon = () => {
    if (action === 'Zap') {
      return <ZapIcon />
    }

    return <img src={vaultUrl} />
  }

  const c2a = () => {
    if (action === 'Zap') {
      return (
        <>
          Zap your token into swETH to get a{' '}
          {formatPercentApprox(discountPercent!)} <span>discount.</span>
        </>
      )
    }

    return <>Deposit stETH for turbo yields and pearls</>
  }

  return (
    <Layout>
      {icon()}
      <AsyncDiv loading={discountPercent == null}>
        {() => <C2ASpan>{c2a()}</C2ASpan>}
      </AsyncDiv>
    </Layout>
  )
}
