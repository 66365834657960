import React from 'react'
import styled from 'styled-components/macro'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { Dialog, DialogContent, DialogTitle } from '@swell-ui/Dialog'
import { FlexRow } from '@/swell-ui/FlexRow'
import { TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { AppStake } from '@/types/stake'
import { StakingPoolActivityTable } from './StakingPoolActivityTable'

interface StakingPoolActivityModalProps {
  open: boolean
  onClose: (value: boolean) => any
  stakes?: AppStake[]
  pagination?: TableConfig['pagination']
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 1152px;
  }

  .MuiDialogTitle-root {
    padding: 24px 32px 12px;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  path {
    stroke-width: 0.666667px;
  }
`

function StakingPoolActivityModal({
  open,
  onClose,
  stakes,
  pagination,
}: StakingPoolActivityModalProps) {
  return (
    <StyledDialog open={open} fullWidth>
      <DialogTitle>
        <FlexRow justify="space-between">
          <Typography variant="body" size="large">
            Staking pool activity
          </Typography>
          <StyledCloseIcon aria-label="Close" onClick={onClose} />
        </FlexRow>
      </DialogTitle>
      <DialogContent>
        <StakingPoolActivityTable stakes={stakes} pagination={pagination} />
      </DialogContent>
    </StyledDialog>
  )
}

export { StakingPoolActivityModal }
