import { useStakingPegMetrics } from '@/state/fiat/hooks'
import { SecondaryMarketC2A } from '../SecondaryMarketC2A'

export function CallToAction({ action }: { action: string }) {
  const stakingPegMetrics = useStakingPegMetrics()

  return (
    <SecondaryMarketC2A
      discountPercent={stakingPegMetrics.data?.secondaryDiscountPercent}
      action={action}
    />
  )
}
