import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import MuiBottomNavigation from '@mui/material/BottomNavigation'
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction'

interface BottomNavAction {
  component?: React.ComponentType<any>
  disabled?: boolean
  icon: React.ReactNode
  label: string
  to: string
}

interface BottomNavProps {
  actions: BottomNavAction[]
}

const BottomNavWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 0;
  z-index: 20;

  .MuiBottomNavigation-root {
    height: 60px;
    background-color: ${({ theme }) => theme.bottomNav.bgColor};
    color: ${({ theme }) => theme.bottomNav.color};
  }

  .MuiBottomNavigationAction-root {
    color: ${({ theme }) => theme.colors.white['150']};

    &.Mui-selected {
      color: white;
    }

    &:disabled {
      color: #888b97;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }
`

const StyledMuiBottomNavigationAction = styled<any>(MuiBottomNavigationAction)`
  ${({ className }) =>
    className === 'disabled' &&
    `
    color: #888b97 !important;
    pointer-events: none;
  `}
`

function BottomNav(props: BottomNavProps) {
  const { actions } = props
  const { pathname } = useLocation()

  const [value, setValue] = React.useState(pathname)

  return (
    <BottomNavWrapper>
      <MuiBottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
      >
        {actions.map((action: any) => {
          return (
            <StyledMuiBottomNavigationAction
              disableRipple
              disableTouchRipple
              key={action.label}
              label={action.label}
              icon={action.icon}
              component={action.component}
              className={action.disabled ? 'disabled' : ''}
              to={action.to}
              value={action.to}
            />
          )
        })}
      </MuiBottomNavigation>
    </BottomNavWrapper>
  )
}

export { BottomNav }
export type { BottomNavAction }
