import styled from 'styled-components/macro'

const Divider = styled.hr<any>`
  ${({ theme, margin }) => `
    border-top: 0px;
    border-color: ${theme.colors.auxGrey};
    margin: ${margin + 'px' ?? '12px'} 0;

    ${theme.breakpoints.up('sm')} {
      margin: 24px 0;
    }
  `}
`

export { Divider }
