import { useProtobufMutation } from '@/hooks/useProtobufMutation'
import {
  useProtobufQuery,
  useProtobufQueryImmutable,
} from '@/hooks/useProtobufQuery'
import {
  depositManagerStatsAdapter,
  earnAPRsAdapter,
  repriceAdapter,
  stakesAdapter,
  transactionNativeAdapter,
  transactionTokenAdapter,
  twitterUserAdapter,
  voyageLeaderboardRecordAdapter,
  voyageStatusAdapter,
  voyageUserAdapter,
} from './adapters'
import { createPromiseClient } from '@bufbuild/connect'
import { createConnectTransport } from '@bufbuild/connect-web'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { V3_BACKEND_SERVICES } from '.'
import { useMemo } from 'react'
import { OrderBy, OrderDirection } from '@v3-backend/staker_pb'
import useSWR from 'swr'
import axios from 'axios'
import { AVERAGE_L1_BLOCK_TIME } from '@/constants/chainInfo'

export function useV3BackendClient() {
  const { v3BackendUrl } = useDeploymentSetConfig()

  return useMemo(() => {
    const transport = createConnectTransport({
      baseUrl: v3BackendUrl,
      useHttpGet: true,
    })

    const rates = createPromiseClient(
      V3_BACKEND_SERVICES.RatesService,
      transport
    )
    const operator = createPromiseClient(
      V3_BACKEND_SERVICES.OperatorService,
      transport
    )
    const staker = createPromiseClient(
      V3_BACKEND_SERVICES.StakerService,
      transport
    )
    const stats = createPromiseClient(
      V3_BACKEND_SERVICES.StatsService,
      transport
    )
    const twitter = createPromiseClient(
      V3_BACKEND_SERVICES.TwitterService,
      transport
    )
    const voyage = createPromiseClient(
      V3_BACKEND_SERVICES.VoyageService,
      transport
    )

    const v3BackendClient = {
      rates,
      operator,
      staker,
      stats,
      twitter,
      voyage,
    }

    return { v3BackendClient }
  }, [v3BackendUrl])
}

export function useGetAllStatsV3Backend() {
  const { v3BackendClient } = useV3BackendClient()
  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.StatsService,
      method: 'all',
      params: {},
      client: v3BackendClient.stats,
    },
    { refreshInterval: AVERAGE_L1_BLOCK_TIME }
  )
}

export function useEarnAPRsV3Backend() {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.StatsService,
    method: 'earnAPYs',
    client: v3BackendClient.stats,
    params: {},
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return earnAPRsAdapter(data!)
    },
  }
}

export function useGetRatesEthUsdV3Backend({
  refreshInterval,
  paused = false,
}: {
  refreshInterval?: number
  paused?: boolean
} = {}) {
  const { v3BackendClient } = useV3BackendClient()

  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.RatesService,
      method: 'ethUsd',
      params: {},
      client: v3BackendClient.rates,
    },
    {
      refreshInterval,
      paused,
    }
  )
}

export function useGetRatesSwEthEthV3Backend({
  refreshInterval,
  paused = false,
}: {
  refreshInterval?: number
  paused?: boolean
} = {}) {
  const { v3BackendClient } = useV3BackendClient()

  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.RatesService,
      method: 'swethEth',
      params: {},
      client: v3BackendClient.rates,
    },
    {
      refreshInterval,
      paused,
    }
  )
}

export function useRepricingHistory() {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQuery({
    service: V3_BACKEND_SERVICES.RatesService,
    method: 'repricingHistory',
    params: {},
    client: v3BackendClient.rates,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { prices } = data
      return {
        prices: prices.map(repriceAdapter),
      }
    },
  }
}

export function useTransactionsTokensV3Backend(address?: string) {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.OperatorService,
      method: 'transactionsTokens',
      client: v3BackendClient.operator,
      params: { address },
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { tokenTransactions } = data
      return {
        tokenTransactions: tokenTransactions.map(transactionTokenAdapter),
      }
    },
  }
}

export function useTransactionsNativeV3Backend(address?: string) {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.OperatorService,
      method: 'transactionsNative',
      client: v3BackendClient.operator,
      params: { address },
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { nativeTransactions } = data
      return {
        nativeTransactions: nativeTransactions.map(transactionNativeAdapter),
      }
    },
  }
}

export function useDepositManagerStatsV3Backend() {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.OperatorService,
    method: 'depositsManagerStats',
    client: v3BackendClient.operator,
    params: {},
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return depositManagerStatsAdapter(data)
    },
  }
}

export function useDepositUserStatsV3Backend() {
  const { v3BackendClient } = useV3BackendClient()

  return useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.OperatorService,
    method: 'depositsUserStats',
    client: v3BackendClient.operator,
    params: {},
  })
}

function useVoyageLeaderboardV3BackendProtobuf() {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQuery({
    service: V3_BACKEND_SERVICES.VoyageService,
    method: 'voyageLeaderboard',
    client: v3BackendClient.voyage,
    params: {},
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { scores } = data
      return {
        scores: scores.map(voyageLeaderboardRecordAdapter),
      }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useVoyageLeaderboardV3BackendGet(): ReturnType<
  typeof useVoyageLeaderboardV3BackendProtobuf
> {
  const { v3BackendUrl } = useDeploymentSetConfig()

  const safeV3Url = v3BackendUrl.replace(/\/$/, '')

  return useSWR(
    'voyage-leaderboard',
    async () => {
      const data = await axios
        .get(
          `${safeV3Url}/swell.v3.VoyageService/VoyageLeaderboard?connect=v1&encoding=json&message={}`
        )
        .then((res) => {
          return res.data
        })

      const { scores } = data
      return { scores: scores.map(voyageLeaderboardRecordAdapter) } as any
    },
    {
      refreshInterval: 30_000,
    }
  )
}

// export { useVoyageLeaderboardV3BackendProtobuf as useVoyageLeaderboardV3Backend }
export { useVoyageLeaderboardV3BackendGet as useVoyageLeaderboardV3Backend }

export function useVoyageUserV3Backend(address?: string) {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.VoyageService,
      method: 'voyageUser',
      client: v3BackendClient.voyage,
      params: { address },
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return voyageUserAdapter(data)
    },
  }
}

export function useVoyageStatusV3Backend() {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQuery({
    service: V3_BACKEND_SERVICES.VoyageService,
    method: 'voyageStatus',
    client: v3BackendClient.voyage,
    params: {},
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return voyageStatusAdapter(data)
    },
  }
}

export interface StakesV3BackendParams {
  first?: number
  skip?: number
  orderBy?: OrderBy
  orderDirection?: OrderDirection
  address?: string
  paused?: boolean
}

export function useStakesV3Backend({
  first = 10,
  skip = 0,
  orderBy = OrderBy.TIMESTAMP,
  orderDirection = OrderDirection.DESC,
  address,
  paused = false,
}: StakesV3BackendParams = {}) {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.StakerService,
      method: 'stakes',
      client: v3BackendClient.staker,
      params: {
        first,
        skip,
        orderBy,
        orderDirection,
        address,
      },
    },
    {
      paused,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) {
        return undefined
      }
      const { stakes, count } = data
      return {
        stakes: stakes.map(stakesAdapter),
        count,
      }
    },
  }
}

export function useTwitterUserV3Backend(address?: string) {
  const { v3BackendClient } = useV3BackendClient()

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.TwitterService,
      method: 'twitterUser',
      client: v3BackendClient.twitter,
      params: { walletAddress: address },
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        user: twitterUserAdapter(data),
      }
    },
  }
}

export function useTwitterVerifyV3Backend() {
  const { v3BackendClient } = useV3BackendClient()

  const mutation = useProtobufMutation({
    service: V3_BACKEND_SERVICES.TwitterService,
    method: 'twitterVerify',
    client: v3BackendClient.twitter,
  })

  return mutation
}

export function useGetSwethAprV3Backend() {
  const { v3BackendUrl } = useDeploymentSetConfig()

  const safeV3Url = v3BackendUrl.replace(/\/$/, '')

  return useSWR(
    'sweth-apr',
    async () => {
      return axios
        .get<number>(`${safeV3Url}/api/tokens/sweth/apr`)
        .then((res) => res.data)
    },
    {
      refreshInterval: 30000,
    }
  )
}
