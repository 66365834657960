import React from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { useRepricingHistory } from '@/services/V3BackendService/hooks'
import { ExchangeRateChart } from './ExchangeRateChart'

function ExchangeRateChartWidget() {
  const { account } = useSwellWeb3()
  const repricingHistoryQuery = useRepricingHistory()

  const prices = repricingHistoryQuery.data
    ? repricingHistoryQuery.data.prices
    : []

  return <ExchangeRateChart account={account} prices={prices} />
}

export { ExchangeRateChartWidget }
