import React from 'react'
import styled from 'styled-components/macro'
import MuiDialog, { DialogProps } from '@mui/material/Dialog'
import { DialogTitle, DialogContent } from './DialogHelpers'

const StyledMuiDialog = styled(MuiDialog)`
  ${({ theme }) => `
    .MuiBackdrop-root {
      background-color: ${theme.dialog.backdrop.bgColor};
      backdrop-filter: ${theme.dialog.backdrop.backdropFilter};
    }

    .MuiDialog-paper {
      width: auto;
      background-color: ${theme.dialog.bgColor};
      color: ${theme.mainColor};
      border: 1px solid ${theme.dialog.borderColor};
      border-radius: 16px;
    }

    .MuiDialogTitle-root {
      color: ${theme.colors.white['125']};
    }
  `}
`

function Dialog(props: DialogProps) {
  return <StyledMuiDialog {...props} />
}

export { Dialog, DialogTitle, DialogContent }
export type { DialogProps }
