import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { isAddress } from '@/util/hexStrings'

const LogoImgContainer = styled.div<any>`
  width: 24px;
  height: 24px;

  ${({ size }) => size && `height: ${size}px;`}
  ${({ size }) => size && `width: ${size}px;`}
`

const LogoImg = styled.img<any>`
  height: 24px;
  border-radius: 50%;

  ${({ size }) => size && `height: ${size}px;`}
`

const MissingLogoImg = styled.div<any>`
  border-radius: 100px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledEthIcon = styled<any>(EthIcon)`
  height: 24px;
  width: 24px;

  ${({ size }) =>
    size &&
    `
    height: ${size}px;
    width: ${size}px;
  `}
`

function getInitialUrl(token: any) {
  const checksummedAddress = isAddress(token.address)

  if (checksummedAddress) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/${checksummedAddress}/logo.png`
  } else {
    return token.logoURI
  }
}

function TokenLogo({ token, size, ...props }: { token: any; size?: number }) {
  const [src, setSrc] = useState<string>(getInitialUrl(token))
  const [isMissing, setIsMissing] = useState<boolean>(false)

  useEffect(() => {
    setSrc(getInitialUrl(token))
  }, [token])

  const findFallbackSrc = () => {
    setSrc(token.logoURI)
    if (!token.logoURI) {
      setIsMissing(true)
    }
  }

  if (token.symbol === 'ETH') {
    return <StyledEthIcon {...props} />
  }

  return (
    <LogoImgContainer size={size}>
      {!isMissing && (
        <LogoImg src={src} onError={findFallbackSrc} size={size} {...props} />
      )}
      {isMissing && <MissingLogoImg />}
    </LogoImgContainer>
  )
}

export { TokenLogo }
