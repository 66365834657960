import { ServiceType } from '@bufbuild/protobuf'
import { PromiseClient } from '@bufbuild/connect'
import useSWRMutation from 'swr/mutation'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { protobufToUrl } from '@/util/protobuf'

const makeProtobufMutation = (useSWRMutationHook: typeof useSWRMutation) => {
  const useProtobufMutationHook = <
    ST extends ServiceType,
    PC extends PromiseClient<ST>,
    Method extends keyof PromiseClient<ST>
  >({
    service,
    method,
    client,
  }: {
    service: ST
    method: Method
    client: PC
  }): any => {
    const { v3BackendUrl } = useDeploymentSetConfig()

    return useSWRMutationHook(
      protobufToUrl({
        service,
        method,
        prefix: v3BackendUrl,
      }),
      (url: string, { arg }: { arg: any }) => {
        return Promise.resolve(client[method](arg))
      }
    ) as any
  }

  return useProtobufMutationHook
}

export const useProtobufMutation = makeProtobufMutation(useSWRMutation)
