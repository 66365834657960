import React from 'react'
import { LineChart } from '@swell-ui/charts/LineChart'
import { mouseLinePlugin } from '@swell-ui/charts/plugins/mouseLine'

interface SwEthEthLineChartProps {
  priceData: any
}

function parsePriceData(priceData: any) {
  const dateLabels = []
  const ethPrices = []
  const swEthPrices = []

  for (let i = 0; i < priceData.length; i++) {
    dateLabels.push(priceData[i]['date'])
    ethPrices.push(priceData[i]['ethPrice'])
    swEthPrices.push(priceData[i]['swEthPrice'])
  }

  return {
    dateLabels,
    ethPrices,
    swEthPrices,
  }
}

function SwEthEthLineChart({ priceData }: SwEthEthLineChartProps) {
  const config = React.useMemo(() => {
    if (!priceData) {
      return {}
    }

    const { dateLabels, ethPrices, swEthPrices } = parsePriceData(priceData)

    return {
      data: {
        labels: dateLabels,
        datasets: [
          {
            label: 'swETH',
            data: swEthPrices,
            // fill: true,
            pointBackgroundColor: '#0019FF',
            borderColor: '#0019FF',
            backgroundColor: '#0019FF',
          },
          {
            label: 'ETH',
            data: ethPrices,
            // fill: true,
            pointBackgroundColor: '#A4ABF1',
            borderColor: '#A4ABF1',
            backgroundColor: '#A4ABF1',
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          mouseLine: {
            color: '#ffffff',
          },
        },
        elements: {
          point: {
            radius: 0,
            hoverRadius: 5,
          },
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      plugins: [
        mouseLinePlugin,
        {
          id: 'gradient',
          beforeLayout(chart: any) {
            const ctx = chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 100)
            gradient.addColorStop(0, '#0019FF')
            gradient.addColorStop(1, 'rgba(0, 0, 0, 0)')

            const gradient2 = ctx.createLinearGradient(0, 0, 0, 200)
            gradient2.addColorStop(0, '#A4ABF1')
            gradient2.addColorStop(1, 'rgba(0, 0, 0, 0)')
          },
        },
      ],
    }
  }, [priceData])

  return <>{priceData && <LineChart config={config} />}</>
}

export { SwEthEthLineChart }
