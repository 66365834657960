import { useDeploymentSetConfig } from '@/state/deployments/hooks'

// w30 todo: put chain id constants in var, move this to helpers/network.ts
function makeRpcUrls(infuraApiKey: string) {
  return {
    1: `https://mainnet.infura.io/v3/${infuraApiKey}`,
    5: `https://goerli.infura.io/v3/${infuraApiKey}`,
    1337: 'http://localhost:8545',
  } as any
}

function useRpcUrl() {
  const { chainId, infura } = useDeploymentSetConfig()
  return makeRpcUrls(infura.apiKey)[chainId as any]
}

function useWalletConnectV1Bridge() {
  const { walletConnectV1 } = useDeploymentSetConfig()
  return walletConnectV1.bridge
}

function useWalletConnectV2ProjectId() {
  const { walletConnectV2 } = useDeploymentSetConfig()
  return walletConnectV2.projectId
}

export { useRpcUrl, useWalletConnectV1Bridge, useWalletConnectV2ProjectId }
