import { useEthUsdFeedChainlinkContract } from '@/hooks/useContract'
import useSWRImmutable from 'swr/immutable'

export const CHAINLINK_PRICE_PRECISION = 1e6

export function useEthUsdMarketRateChainlink(
  { active = true }: { active?: boolean } = { active: true }
) {
  const ethUsdFeedChainlink = useEthUsdFeedChainlinkContract()

  return useSWRImmutable(
    ethUsdFeedChainlink && active
      ? [ethUsdFeedChainlink.address, 'latestRoundData']
      : null,
    async () => {
      const rateCents =
        (await ethUsdFeedChainlink!.latestRoundData()).answer.toNumber() /
        CHAINLINK_PRICE_PRECISION

      return {
        rate: rateCents / 100,
      }
    },
    { revalidateOnMount: false }
  )
}

export function useSwEthEthMarketRateOnChain(
  { active = true }: { active?: boolean } = { active: true }
) {
  // TODO: find a suitable on chain data source. Mocked for now.
  return useSWRImmutable(
    active ? ['mock-sweth-eth-on-chain'] : null,
    async () => {
      return {
        rate: await new Promise<number>((resolve) => {
          setTimeout(() => {
            resolve(0.48)
          }, 1000)
        }),
      }
    },
    { revalidateOnMount: false }
  )
}
