import React from 'react'
import { Link, Routes, Route } from 'react-router-dom'
import { BottomNav } from '@swell-ui/BottomNav'
import { MonetizationIcon } from '@swell-ui/icons/MonetizationIcon'
import { PortfolioIcon } from '@swell-ui/icons/PortfolioIcon'
import { StakeIcon } from '@swell-ui/icons/StakeIcon'
import { VoyageIcon } from '@swell-ui/icons/VoyageIcon'

function BottomNavBar() {
  const actions = [
    {
      label: 'Stake',
      icon: <StakeIcon />,
      component: Link,
      to: '/',
    },
    {
      label: 'Earn',
      icon: <MonetizationIcon />,
      component: Link,
      to: '/earn',
    },
    {
      label: 'Portfolio',
      icon: <PortfolioIcon />,
      component: Link,
      to: '/portfolio',
    },
    {
      label: 'Voyage',
      icon: <VoyageIcon />,
      component: Link,
      to: '/voyage',
    },
  ]

  return (
    <Routes>
      <Route path="/operators/*" element={null} />
      <Route path="/*" element={<BottomNav actions={actions} />} />
    </Routes>
  )
}

export { BottomNavBar }
