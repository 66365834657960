import React from 'react'
import styled from 'styled-components/macro'
import { AsyncDiv } from '@swell-ui/AsyncDiv'
import { FlexRow } from '@swell-ui/FlexRow'
import { Tooltip } from '@swell-ui/Tooltip'
import { Typography } from '@swell-ui/Typography'
import { WarningIcon } from '@swell-ui/icons/WarningIcon'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { displayPearls } from '@/util/displayNumbers'
import { useVoyageStatusV3Backend } from '@/services/V3BackendService/hooks'
import { MapPath } from './MapPath'
import pearlUrl from '@/assets/svg/voyage/pearl.svg'
import { AppVoyageUser } from '@/types/voyage'

interface VoyageMapProps {
  voyageUser?: AppVoyageUser
  voyageUserIsLoading: boolean
  pearlError: boolean
}

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 2024px;
  margin: 0 auto;
  margin-top: 119px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      margin-top: 48px;
    }
  `}
`

const MapTitle = styled(Typography)`
  text-align: center;
`

const MapInfoBox = styled.div`
  background: rgba(4, 6, 42, 0.6);
  backdrop-filter: blur(3px);
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.auxGrey};
  border-radius: 28px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      padding: 8px;
      border-radius: 12px;
    }
  `}
`

const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.lightBlue[50]};
  letter-spacing: 0.075em;
  text-transform: uppercase;
  margin-bottom: 18px;
`

const PearlImg = styled.img`
  margin-right: 8px;
  height: 24px;
`

const Chapter1Positioner = styled.div`
  position: absolute;
  left: 3.4%;
  top: 192px;
  width: 27%;
  z-index: 10;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      position: static;
      width: 100%;
      margin-top: 12px;
    }
  `}
`

const Chapter2Positioner = styled.div`
  position: absolute;
  right: 8.7%;
  top: 847px;
  width: 23%;
  text-align: right;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      top: 571px;
      left: 0;
      width: 100%;
      margin-top: 32px;
      text-align: left;
    }

    ${theme.breakpoints.down('sm')} {
      top: 590px;
    }
  `}
`

const PearlsPositioner = styled.div`
  z-index: 10;
  position: absolute;
  right: 3.4%;
  top: 309px;
  width: 17.8%;
  text-align: center;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      position: static;
      width: 100%;
      margin-top: 32px;
    }
  `}
`

const StyledWarningIcon = styled(WarningIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.red['50']};
  }
`

const ERROR_TOOLTIP_TITLE =
  'Please check back later, and check our Discord for announcements'

function ChapterInfoBox({
  description,
  label,
  title,
}: {
  description: string
  label: string
  title: string
}) {
  return (
    <MapInfoBox>
      <Typography variant="body" size="medium" xs="xsmall" md="medium">
        {label}
      </Typography>
      <Typography variant="body" size="large" xs="small" md="large">
        {title}
      </Typography>
      <Typography variant="body" size="medium" xs="xsmall" md="medium">
        {description}
      </Typography>
    </MapInfoBox>
  )
}

function PearlInfoBox({
  total,
  isLoading,
  error,
}: {
  total: string
  isLoading: boolean
  error: boolean
}) {
  const isMobile = useMediaQuery('(max-width: 899px)')
  const justify = isMobile || error ? 'center' : 'flex-start'

  return (
    <MapInfoBox>
      <SectionTitle variant="body" size="small">
        Total Pearls Collected
      </SectionTitle>
      <Typography variant="body" xs="xlarge" size="xlarge">
        <AsyncDiv loading={isLoading}>
          {() => (
            <FlexRow align="center" justify={justify}>
              {!error && (
                <>
                  <PearlImg src={pearlUrl} />
                  <div>{total}</div>
                </>
              )}
              {error && (
                <Tooltip
                  title={ERROR_TOOLTIP_TITLE}
                  placement="top"
                  offset={[0, -10]}
                >
                  <StyledWarningIcon />
                </Tooltip>
              )}
            </FlexRow>
          )}
        </AsyncDiv>
      </Typography>
    </MapInfoBox>
  )
}

const CHAPTER_1_LABEL = 'Chapter 1'
const CHAPTER_1_TITLE = 'Sunlight Zone'
const CHAPTER_1_DESCRIPTION =
  'The journey begins with the initial descent of voyagers towards becoming citizens of the Swell DAO.'

const CHAPTER_2_LABEL = 'Chapter 2'
const CHAPTER_2_TITLE = 'Twilight Zone'
const CHAPTER_2_DESCRIPTION =
  'Voyagers continue with a rallying cry for others to join the voyage.'

function VoyageMap({
  voyageUser,
  voyageUserIsLoading,
  pearlError,
}: VoyageMapProps) {
  const voyageStatusQuery = useVoyageStatusV3Backend()

  const totalPearls = voyageUser ? voyageUser.points : 0
  const totalVoyagers = voyageStatusQuery.data
    ? Number(voyageStatusQuery.data.totalUsers).toLocaleString()
    : '-'

  return (
    <MapContainer>
      <MapTitle
        variant="headline"
        size="h1"
        xs="h3"
        md="h1"
        letterSpacing="small"
        fstyle="bold"
      >
        Voyage Map
      </MapTitle>
      <PearlsPositioner>
        <PearlInfoBox
          isLoading={voyageUserIsLoading}
          error={pearlError}
          total={displayPearls(totalPearls)}
        />
      </PearlsPositioner>
      <Chapter1Positioner>
        <ChapterInfoBox
          label={CHAPTER_1_LABEL}
          title={CHAPTER_1_TITLE}
          description={CHAPTER_1_DESCRIPTION}
        />
      </Chapter1Positioner>
      <Chapter2Positioner>
        <ChapterInfoBox
          label={CHAPTER_2_LABEL}
          title={CHAPTER_2_TITLE}
          description={CHAPTER_2_DESCRIPTION}
        />
      </Chapter2Positioner>
      <MapPath totalVoyagers={totalVoyagers} />
    </MapContainer>
  )
}

export { VoyageMap }
