import styled from 'styled-components'
import { useSwellWeb3 } from '@swell-web3/core'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Typography } from '@/swell-ui/Typography'
import { useDisplaySwEth } from '@/hooks/useSwEthDisplay'
import { useSwEthBalance } from '@/state/user/hooks'

const Wrapper = styled.div`
  margin: 40px 0 64px;
`

const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
`

const TITLE = 'Earn with swETH'

export function PageTitle() {
  const { account } = useSwellWeb3()
  const swEthBalance = useSwEthBalance()
  const displaySwEth = useDisplaySwEth()

  const subtitle = () => {
    let balanceDisplay: any = 'swETH'
    if (account && swEthBalance.data && swEthBalance.data.balance.gt(0)) {
      balanceDisplay = (
        <Highlight>
          {displaySwEth(swEthBalance.data.balance, {
            precision: 4,
            localize: true,
          })}
        </Highlight>
      )
    }

    return (
      <>
        Utilise your {balanceDisplay} across multiple chains in DeFi ecosystem
        to maximise the returns on your staked ETH.
      </>
    )
  }
  return (
    <Wrapper>
      <FlexRow direction="column" gap="16" align="flex-start">
        <Typography
          variant="headline"
          xs="h6"
          md="h2"
          size="h2"
          fstyle="bold"
          letterSpacing="small"
        >
          {TITLE}
        </Typography>
        <Typography
          variant="body"
          xs="xsmall"
          md="medium"
          size="medium"
          letterSpacing="small"
        >
          {subtitle()}
        </Typography>
      </FlexRow>
    </Wrapper>
  )
}
