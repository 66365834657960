import React from 'react'
import styled from 'styled-components'
import { useSwellWeb3 } from '@swell-web3/core'
import { BridgeIcon } from '@swell-ui/icons/BridgeIcon'
import { CreditCardIcon } from '@swell-ui/icons/CreditCardIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Tooltip } from '@swell-ui/Tooltip'
import { TransakOnRampWidget } from '@/components/TransakOnRampWidget'

const StyledCreditCardIcon = styled(CreditCardIcon)`
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
`

const StyledBridgeIcon = styled(BridgeIcon)`
  height: 14px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
`

const DisabledCreditCardIcon = styled(CreditCardIcon)`
  position: relative;
  top: 3px;
  opacity: 0.3;
`

const DisabledBridgeIcon = styled(BridgeIcon)`
  position: relative;
  top: 3px;
  height: 14px;
  opacity: 0.3;
`

function CryptoUtilities() {
  const { account } = useSwellWeb3()

  return (
    <FlexRow gap="8" align="center">
      {!account && (
        <>
          <DisabledBridgeIcon />
          <DisabledCreditCardIcon />
        </>
      )}
      {account && (
        <>
          <Tooltip title="Bridge swETH via Stargate" placement="top" arrow>
            <a
              href="https://stargate.finance/transfer"
              target="_blank"
              rel="noreferrer"
            >
              <StyledBridgeIcon />
            </a>
          </Tooltip>
          <TransakOnRampWidget
            label={
              <Tooltip title={'Buy ETH with fiat'} placement="top" arrow>
                <StyledCreditCardIcon />
              </Tooltip>
            }
            account={account}
          />
        </>
      )}
    </FlexRow>
  )
}

export { CryptoUtilities }
