import { useGetAllStatsV3Backend } from '@/services/V3BackendService/hooks'
import { useSwEthUsdMarketRate } from '@/state/fiat/hooks'
import { useSwEthTotalSupply } from './useSwEthTotalSupply'
import { useConvertSwEthToFiat } from './useCurrencyConversions'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useSwEthMarketCapOnChain = () => {
  const { data: swEthUsdMarketRateData, ...swEthUsdMarketRateQuery } =
    useSwEthUsdMarketRate()
  const { data: swEthTotalSupplyData, ...swEthTotalSupplyQuery } =
    useSwEthTotalSupply()

  const convertSwEthToFiat = useConvertSwEthToFiat()

  return {
    error: swEthUsdMarketRateQuery.error ?? swEthTotalSupplyQuery.error,
    isLoading:
      swEthUsdMarketRateQuery.isLoading || swEthTotalSupplyQuery.isLoading,
    isValidating:
      swEthUsdMarketRateQuery.isValidating ||
      swEthTotalSupplyQuery.isValidating,
    get data() {
      if (!swEthUsdMarketRateData || !swEthTotalSupplyData) return undefined
      const { rate } = swEthUsdMarketRateData
      const { totalSupply } = swEthTotalSupplyData

      return { marketCapUsd: convertSwEthToFiat(totalSupply, rate) }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useSwEthMarketCapV3Backend = () => {
  const { data, ...query } = useGetAllStatsV3Backend()

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { swEthMarketCapCents } = data
      return {
        marketCapUsd: parseFloat(swEthMarketCapCents) / 100,
      }
    },
  }
}

export { useSwEthMarketCapV3Backend as useSwEthMarketCap }
// export { useSwEthMarketCapWeb3 as useSwEthMarketCap }
