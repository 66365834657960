import { useState } from 'react'
import styled from 'styled-components/macro'
import { Accordion } from '@swell-ui/Accordion'
import { DownCaratIcon } from '@swell-ui/icons/DownCaratIcon'
import { UpCaratIcon } from '@swell-ui/icons/UpCaratIcon'
import { KeyValueList } from '@swell-ui/KeyValueList'
import { Typography } from '@swell-ui/Typography'
import { StakingPoolActivity } from '@/components/StakingPoolActivity'
import {
  useCommissionRate,
  useStakerCountUsers,
  useTotalEthStaked,
} from '@/state/stakingStats/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import { useSwEthMarketCap } from '@/hooks/useSwEthMarketCap'
import { displayFiat } from '@/util/displayFiat'

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.03em;

  svg {
    margin-left: 5px;
  }
`

const ActivityContainer = styled.div`
  margin-top: 24px;
`

function SwellStatisticAccordionSummary({ open }: { open: boolean }) {
  return (
    <SummaryContainer>
      <Typography variant="body" size="small">
        Swell statistics
      </Typography>
      {!open && <DownCaratIcon />}
      {open && <UpCaratIcon />}
    </SummaryContainer>
  )
}

function SwellStatisticAccordionDetails() {
  const totalEthStaked = useTotalEthStaked()
  const stakerCountUsers = useStakerCountUsers()

  const commissionRate = useCommissionRate()

  const swEthMarketCapQuery = useSwEthMarketCap()
  const displayNativeCurrency = useDisplayNativeCurrency()

  const keyValueListConfig = {
    list: [
      {
        label: 'Commission rate',
        value: (
          <AsyncDiv loading={!commissionRate.data}>
            {() =>
              `${
                commissionRate.data!.nodeOperatorRewardPercentage +
                commissionRate.data!.swellTreasuryRewardPercentage
              }%`
            }
          </AsyncDiv>
        ),
      },
      {
        label: 'Total ETH staked',
        value: (
          <AsyncDiv loading={!totalEthStaked.data}>
            {() => {
              return displayNativeCurrency(totalEthStaked.data!.value, {
                precision: 0,
                localize: true,
              })
            }}
          </AsyncDiv>
        ),
      },
      {
        label: 'Stakers',
        value: (
          <AsyncDiv loading={!stakerCountUsers.data}>
            {() => stakerCountUsers.data!.value}
          </AsyncDiv>
        ),
      },
      {
        label: 'swETH market cap',
        value: (
          <AsyncDiv loading={!swEthMarketCapQuery.data}>
            {() => {
              const { marketCapUsd } = swEthMarketCapQuery.data!
              return `${displayFiat(marketCapUsd)} USD`
            }}
          </AsyncDiv>
        ),
      },
    ],
  }

  return (
    <>
      <KeyValueList config={keyValueListConfig} />
      <ActivityContainer>
        <StakingPoolActivity />
      </ActivityContainer>
    </>
  )
}

function SwellStatistics() {
  const [open, setOpen] = useState<boolean>(false)

  const accordionConfig = {
    summary: <SwellStatisticAccordionSummary open={open} />,
    details: <SwellStatisticAccordionDetails />,
  }

  function handleChange() {
    if (!open) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return (
    <Accordion config={accordionConfig} onChange={handleChange}></Accordion>
  )
}

export { SwellStatistics }
