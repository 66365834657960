import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ModalOpenIcon } from '@swell-ui/icons/ModalOpenIcon'
import { TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { useSwellUiTheme } from '@swell-ui/theme'
import {
  useStakesV3Backend,
  StakesV3BackendParams,
} from '@/services/V3BackendService/hooks'
import { StakingPoolActivityAccordion } from './StakingPoolActivityAccordion'
import { StakingPoolActivityModal } from './StakingPoolActivityModal'

const StakingPoolActivitySection = styled.div`
  display: block;
  text-align: center;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

const StyledModalOpenIcon = styled(ModalOpenIcon)`
  position: relative;
  top: 1.5px;
  margin-left: 4px;
`
const STAKES_PER_PAGE = 9

function StakingPoolActivity() {
  const { theme } = useSwellUiTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const [stakingPoolActivityModalOpen, setStakingPoolActivityModalOpen] =
    useState<boolean>(false)

  // stakes query
  const [stakesQueryParams, setStakesQueryParams] =
    useState<StakesV3BackendParams>({
      first: STAKES_PER_PAGE,
    })
  const stakesQuery = useStakesV3Backend(stakesQueryParams)

  // pagination state
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalResults, setTotalResults] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)

  useEffect(() => {
    if (!stakesQuery.data) {
      return
    }

    setTotalResults(stakesQuery.data.count)
    setPageCount(Math.ceil(stakesQuery.data.count / STAKES_PER_PAGE))
  }, [stakesQuery.data])

  const pagination = React.useMemo<TableConfig['pagination']>(() => {
    return {
      boundaryCount: 1,
      siblingCount: 1,
      count: pageCount,
      page: currentPage,
      totalResults: totalResults,
      onChange: (page: number) => {
        setCurrentPage(page)
        setStakesQueryParams((prevQueryParams: StakesV3BackendParams) => {
          return {
            ...prevQueryParams,
            skip: STAKES_PER_PAGE * (page - 1),
          }
        })
      },
    }
  }, [currentPage, pageCount, totalResults])

  const sharedProps = React.useMemo<any>(() => {
    if (!stakesQuery.data?.stakes)
      return {
        stakes: [],
        pagination,
      }

    return {
      stakes: stakesQuery.data?.stakes,
      pagination,
    }
  }, [stakesQuery.data?.stakes, pagination])

  if (isSmUp) {
    return (
      <StakingPoolActivitySection>
        <Typography variant="body" size="small">
          <span onClick={() => setStakingPoolActivityModalOpen(true)}>
            Staking pool activity
            <StyledModalOpenIcon />
          </span>
        </Typography>
        <StakingPoolActivityModal
          {...sharedProps}
          open={stakingPoolActivityModalOpen}
          onClose={() => setStakingPoolActivityModalOpen(false)}
        />
      </StakingPoolActivitySection>
    )
  }

  return <StakingPoolActivityAccordion {...sharedProps} />
}

export { StakingPoolActivity }
