import useSWR from 'swr'
import { LiFi } from '@lifi/sdk'
import axios from 'axios'
import { swapInfoAdapter } from './adapters'

interface RouteOptions {
  slippage?: number
  maxPriceImpact?: number
  integrator?: string
  exchanges?: {
    allow: string[]
  }
}

interface RouteRequestParams {
  fromChainId: number
  fromAmount: string
  fromTokenAddress: string
  toChainId: number
  toTokenAddress: string
  options: RouteOptions
  fromAddress?: string
  toAddress?: string
}

const lifi = new LiFi({
  integrator: 'Swell',
})

// mainnet exchanges only
const allowedExchanges = ['1inch', '0x', 'uniswap', 'sushiswap', 'apeswap']

let controller: AbortController

export function useGetRoutesQuery(
  params: RouteRequestParams,
  paused?: boolean
) {
  const key = paused ? null : `LiFiGetRoutes${params.fromAmount}`

  const getRoutesFetcher = async () => {
    const url = 'https://li.quest/v1/advanced/routes'

    params.options.integrator = 'Swell'
    params.options.exchanges = { allow: allowedExchanges }

    if (controller) {
      controller.abort()
    }

    controller = new AbortController()
    const signal = controller.signal

    const result = await axios.post(url, params, { signal } as any)
    return result.data.routes
  }

  const { data, ...query } = useSWR(key, getRoutesFetcher, {
    refreshInterval: 15000,
  })

  return {
    ...query,
    get data() {
      if (!data || !data[0]) return undefined

      const route = data[0]

      return {
        route,
        swapInfo: swapInfoAdapter(route),
      }
    },
  }
}

export function usePopulateTransactionData() {
  return async (params: any) => {
    return await lifi.getStepTransaction(params)
  }
}
