import React from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Grid } from '@swell-ui/Grid'
import { Typography } from '@swell-ui/Typography'
import { VoyageHero } from '@/components/VoyageHero'
import { YourRewards } from '@/components/YourRewards'
import { PearlDivingTargets } from '@/components/PearlDivingTargets'
import { VoyageMap } from '@/components/VoyageMap'
import { VoyageReferral } from '@/components/VoyageReferral'
import { useTotalEthStaked } from '@/state/stakingStats/hooks'
import { useVoyageUserV3Backend } from '@/services/V3BackendService/hooks'
import aquanautUrl from '@/assets/svg/aquanaut.svg'

const PageTitleContainer = styled.div`
  letter-spacing: -0.03em;
  text-align: center;

  > div:not(:last-child) {
    margin-bottom: 14px;
  }
`

const ChapterText = styled(Typography)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  margin-top: 60px;
  text-transform: uppercase;
`

const CountdownContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 81px;
  text-align: center;
`

const InfoContainer = styled.div`
  max-width: 891px;
  margin: 0 auto;
`

function Voyage() {
  const { account, ENSName } = useSwellWeb3()

  const totalEthStakedQuery = useTotalEthStaked()
  const totalEthStaked = totalEthStakedQuery.data?.value

  const voyageUserQuery = useVoyageUserV3Backend(account)
  const voyageUser = voyageUserQuery.data

  const pearlError = !!voyageUserQuery.error

  return (
    <div>
      <PageTitleContainer>
        <ChapterText variant="body" size="large">
          Chapter 2
        </ChapterText>
        <Typography variant="headline" xs="h2" sm="h1" size="h1" fstyle="bold">
          Swell Voyage
        </Typography>
        <Typography variant="body" xs="medium" sm="large" size="large">
          The voyage has set sail, time to start hunting pearls...
        </Typography>
      </PageTitleContainer>
      <CountdownContainer>
        <img src={aquanautUrl} />
        <VoyageHero />
      </CountdownContainer>
      <InfoContainer>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <YourRewards
              isLoading={voyageUserQuery.isLoading}
              voyageUser={voyageUser}
              ENSName={ENSName ? ENSName : undefined}
              address={account}
              error={pearlError}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <VoyageReferral account={account} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PearlDivingTargets totalEthStaked={totalEthStaked} />
          </Grid>
        </Grid>
      </InfoContainer>
      <VoyageMap
        voyageUser={voyageUser}
        voyageUserIsLoading={voyageUserQuery.isLoading}
        pearlError={pearlError}
      />
    </div>
  )
}

export { Voyage }
